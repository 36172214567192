@font-face {
    font-family: "regular";
    src: url(../fonts/Bahij_Plain.ttf);
}

@font-face {
    font-family: "bold";
    src: url(../fonts/Bahij_SemiBold.ttf);
}

/******* BASE *******/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: middle;
}

body {
    overflow-x: hidden;
    font-family: "regular";
}

/* HTML5 display-role reset for older browsers */
* {
    box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after {
    content: "";
    content: none;
}

q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1 {
    font-weight: normal;
    font-size: 40px;
}

@media (max-width: 767px) {
    h1 {
        font-size: 27px;
    }
}

h2 {
    font-weight: normal;
    font-size: 30px;
}

@media (max-width: 767px) {
    h2 {
        font-size: 23px;
    }
}

h3 {
    font-weight: normal;
    font-size: 24px;
}

@media (max-width: 1023px) {
    h3 {
        font-size: 20px;
    }
}

@media (max-width: 767px) {
    h3 {
        font-size: 16px;
    }
}

h4 {
    font-weight: normal;
    font-size: 21px;
}

@media (max-width: 1023px) {
    h4 {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    h4 {
        font-size: 18px;
    }
}

h5 {
    font-weight: normal;
    font-size: 19px;
}

@media (max-width: 1023px) {
    h5 {
        font-size: 18px;
    }
}

h6 {
    font-weight: normal;
    font-size: 18px;
}

@media (max-width: 1023px) {
    h6 {
        font-size: 16px;
    }
}

body {
    font-size: 14px;
    color: #181C32;
    line-height: 1.5;
    background: #fff;
    direction: rtl;
}

.container {
    width: 100%;
    max-width: 1300px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

img {
    max-width: 100%;
    height: auto;
}

* {
    outline: none;
}

a {
    color: #05bbc9;
    cursor: pointer;
    text-decoration: none;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

a:hover {
    color: #fe820e;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

a:focus {
    outline: 0;
}

p {
    margin-bottom: 20px;
}

p:last-child {
    margin-bottom: 0;
}

strong {
    font-weight: 700;
}

/*** default values ***/
/***** custom values *****/
/***** Grid Values *****/
/** Extends **/
.css-equal-heights,
.css-equal-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}

.activity .activity-list,
.notifications .notification-list,
.sidebar .sidebar-right ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.css-equal-content {
    color: red;
    float: left;
}

.pass-group .toggle-password,
.pass-group .toggle-passworda,
.profile-set .profile-top .profileupload a,
.productset .productsetimg,
.productset.active .check-product,
.order-list .actionproducts > ul > li .deletebg,
.product-details,
.header-menuset ul li a,
.owl-product .owl-nav button,
.productimg .productcontet h4,
.increment-decrement .input-groups input[type="button"],
.setvaluecash ul li a,
.select-split .btn-filters,
.product-view .product-viewimg,
.productviewset .productviewscontent a,
.image-upload-new .productviewscontent a,
.add-icon span,
.add-icon a,
.slider-product-details .owl-carousel .owl-nav button,
.product-list ul li .productviews .productviewscontent a,
.login-content .form-sociallink ul li a,
.login-content .toggle-passwords,
.login-content .toggle-password,
.dash-widget .dash-widgetimg span,
.image-upload.image-upload-new .image-uploads,
.search-set .btn-filter,
.page-header .btn-filter,
.customizer-links,
.modal .modal-footer .btn-print,
.modal .close,
.modal .btn-close,
.sidebar .nav-link,
div#global-loader,
.searchinputs .search-addon span,
.user-menu.nav > li > a .badge,
.error-page .main-wrapper,
.error-page,
.icons-items .icons-list li,
.icons-list li,
.form-group .btn-filters,
.input-group .scanner-set,
.avatar .avatar-title {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

.pass-group .toggle-password,
.pass-group .toggle-passworda,
.profile-set .profile-top .profileupload a,
.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top .profile-content,
.profile-set .profile-top,
.productset .productsetimg,
.productset.active .check-product,
.order-list .actionproducts > ul > li .deletebg,
.order-list .actionproducts > ul,
.order-list,
.totalitem,
.btn-scanner-set,
.product-details,
.invoice-load-btn .btn,
.header-menuset ul li a,
.header-menuset ul li,
.header-menuset ul,
.calculator-set ul,
.btn-pos ul,
.actionproduct ul,
.owl-product .owl-nav button,
.tab-set ul,
.productimg .productlinkset,
.productimg .productcontet h4,
.productimg,
.increment-decrement .input-groups input[type="button"],
.increment-decrement .input-groups,
.product-lists,
.setvalue ul li,
.setvaluecash ul li a,
.setvaluecash ul,
.btn-totallabel,
.select-split .btn-filters,
.select-split,
.product-view .product-viewimg,
.productviewset .productviewscontent a,
.image-upload-new .productviewscontent a,
.image-upload-new .productviewscontent,
.card-sales-split,
.total-order ul li,
.add-icon span,
.add-icon a,
.slider-product-details .owl-carousel .owl-nav button,
.input-checkset ul,
.bar-code-view,
.product-list ul li .productviews .productviewscontent a,
.product-list ul li .productviews .productviewscontent,
.product-list ul li .productviews,
.product-list ul,
.login-content .form-sociallink ul li a,
.login-content .form-sociallink ul,
.login-content .toggle-passwords,
.login-content .toggle-password,
.graph-sets > ul,
.graph-sets,
.dash-count,
.dash-widget .dash-widgetimg span,
.dash-widget,
.image-upload.image-upload-new .image-uploads,
.wordset ul,
.search-set .btn-filter,
.search-set,
.table-top,
.page-header .btn-added,
.page-header .btn-filter,
.page-header,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info,
.chat-search .input-group .input-group-prepend,
.switch-wrapper #dark-mode-toggle .light-mode,
.switch-wrapper #dark-mode-toggle .dark-mode,
.switch-wrapper #dark-mode-toggle,
.sidebar-content .sidebar-header,
.modal .modal-footer .btn-print,
.modal .close,
.modal .btn-close,
.modal-barcode ul,
.productimgname,
.sidebar .sub-menu a,
.sidebar .nav-link,
.sidebar .sidebar-menu > ul > li > a,
div#global-loader,
.sidebar.sidebar-four .sidebar-menu ul li a,
.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a,
.searchinputs .search-addon span,
.user-menu.nav > li.flag-nav a,
.user-menu.nav > li > a .badge,
.header-split .header-menu ul li a,
.header-split .header-menu ul,
.header-split,
.header-pos.header .user-menu.nav li,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .menu-drop-user .dropdown-item,
.header .userset,
.header .header-left,
.error-page,
.icons-items .icons-list li,
.icons-list li,
.form-group .btn-filters,
.input-group .scanner-set,
.btn-white,
.avatar .avatar-title {
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top,
.order-list,
.totalitem,
.invoice-load-btn .btn,
.header-menuset ul li,
.btn-pos ul,
.product-lists,
.setvalue ul li,
.btn-totallabel,
.image-upload-new .productviewscontent,
.card-sales-split,
.bar-code-view,
.product-list ul li .productviews .productviewscontent,
.dash-count,
.table-top,
.page-header,
.chat-cont-left .chat-scroll .media .media-body,
.sidebar-content .sidebar-header,
.sidebar .sub-menu a,
.header-split,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .header-left,
.btn-white {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.customizer-links ul li a,
.align-center {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.activity .activity-list,
.notifications .notification-list,
.sidebar .sidebar-right ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.switch-wrapper #dark-mode-toggle .light-mode,
.switch-wrapper #dark-mode-toggle .dark-mode,
.switch-wrapper #dark-mode-toggle,
.avatar-group {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.pass-group .toggle-password,
.pass-group .toggle-passworda,
.profile-set .profile-top .profileupload a,
.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top .profile-content,
.profile-set .profile-top,
.productset .productsetimg,
.productset.active .check-product,
.order-list .actionproducts > ul > li .deletebg,
.order-list .actionproducts > ul,
.order-list,
.totalitem,
.btn-scanner-set,
.product-details,
.header-menuset ul li a,
.header-menuset ul li,
.header-menuset ul,
.calculator-set ul,
.btn-pos ul li a.btn,
.btn-pos ul,
.actionproduct ul,
.owl-product .owl-nav button,
.tab-set ul,
.productimg .productlinkset,
.productimg .productcontet h4,
.productimg,
.increment-decrement .input-groups input[type="button"],
.increment-decrement .input-groups,
.product-lists,
.setvalue ul li,
.setvaluecash ul li a,
.setvaluecash ul,
.btn-totallabel,
.select-split .btn-filters,
.select-split,
.product-view .product-viewimg,
.productviewset .productviewscontent a,
.image-upload-new .productviewscontent a,
.image-upload-new .productviewscontent,
.card-sales-split ul,
.card-sales-split,
.total-order ul li,
.add-icon span,
.add-icon a,
.slider-product-details .owl-carousel .owl-nav button,
.productdetails > ul > li,
.input-checkset ul,
.bar-code-view,
.product-list ul li .productviews .productviewscontent a,
.product-list ul li .productviews .productviewscontent,
.product-list ul li .productviews,
.product-list ul,
.login-content .form-sociallink ul li a,
.login-content .form-sociallink ul,
.login-content .toggle-passwords,
.login-content .toggle-password,
.login-wrapper,
.graph-sets > ul,
.graph-sets,
.dash-count,
.dash-widget .dash-widgetimg span,
.dash-widget,
.image-upload.image-upload-new .image-uploads,
.wordset ul,
.search-set .btn-filter,
.search-set,
.table-top,
.page-header .btn-added,
.page-header .btn-filter,
.page-header,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments,
.chat-cont-right ul.list-unstyled .media.sent .media-body,
.chat-cont-left .chat-scroll .media .media-body,
.chat-search .input-group .input-group-prepend,
.sidebar-content .sidebar-header,
.customizer-links,
.modal .modal-footer .btn-print,
.modal .close,
.modal .btn-close,
.modal-barcode ul,
.productimgname,
.sidebar .nav-items .nav-link,
.sidebar .sub-menu a,
.sidebar .nav-link,
.sidebar .sidebar-menu > ul > li > a,
.sidebar,
div#global-loader,
.sidebar.sidebar-four .sidebar-menu ul li a,
.sidebar.sidebar-four .sidebar-menu,
.searchinputs .search-addon span,
.user-menu.nav > li.flag-nav a,
.user-menu.nav > li > a .badge,
.profilename .profileset,
.header-split .header-menu ul li a,
.header-split .header-menu ul,
.header-split,
.header-pos.header .user-menu.nav li,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .menu-drop-user .dropdown-item,
.header .userset,
.header .header-left,
.error-page .main-wrapper,
.error-page,
.icons-items .icons-list li,
.icons-items .icons-list,
.icons-list li,
.icons-list,
.form-group .btn-filters,
.input-group,
.input-group .scanner-set,
.dropdown-menu .dropdown-item,
.btn-white,
.avatar .avatar-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top,
.order-list,
.totalitem,
.invoice-load-btn .btn,
.header-menuset ul li,
.btn-pos ul,
.product-lists,
.setvalue ul li,
.btn-totallabel,
.image-upload-new .productviewscontent,
.card-sales-split,
.bar-code-view,
.product-list ul li .productviews .productviewscontent,
.dash-count,
.table-top,
.page-header,
.chat-cont-left .chat-scroll .media .media-body,
.sidebar-content .sidebar-header,
.sidebar .sub-menu a,
.header-split,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .header-left,
.btn-white {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.header-menuset ul,
.calculator-set ul,
.btn-pos ul,
.tab-set ul,
.productdetails > ul > li,
.input-checkset ul,
.product-list ul,
.login-content .form-sociallink ul,
.login-wrapper,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info,
.customizer-links,
.modal-barcode ul,
.error-page .main-wrapper,
.icons-items .icons-list,
.icons-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body {
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
}

.setvaluecash ul li a,
.chat-cont-right ul.list-unstyled .media.sent .media-body,
.sidebar .nav-items .nav-link {
    -ms-flex-direction: column;
    flex-direction: column;
}

.col {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    float: left;
    position: relative;
    min-height: 1px;
}

@media (min-width: 320px) {
    .col.col-xs-12 {
        width: 100%;
    }

    .col.col-xs-11 {
        width: 91.66666667%;
    }

    .col.col-xs-10 {
        width: 83.33333333%;
    }

    .col.col-xs-9 {
        width: 75%;
    }

    .col.col-xs-8 {
        width: 66.66666667%;
    }

    .col.col-xs-7 {
        width: 58.33333333%;
    }

    .col.col-xs-6 {
        width: 50%;
    }

    .col.col-xs-5 {
        width: 41.66666667%;
    }

    .col.col-xs-4 {
        width: 33.33333333%;
    }

    .col.col-xs-3 {
        width: 25%;
    }

    .col.col-xs-2 {
        width: 16.66666667%;
    }

    .col.col-xs-1 {
        width: 8.33333333%;
    }
}

@media (min-width: 568px) {
    .col.col-sm-12 {
        width: 100%;
    }

    .col.col-sm-11 {
        width: 91.66666667%;
    }

    .col.col-sm-10 {
        width: 83.33333333%;
    }

    .col.col-sm-9 {
        width: 75%;
    }

    .col.col-sm-8 {
        width: 66.66666667%;
    }

    .col.col-sm-7 {
        width: 58.33333333%;
    }

    .col.col-sm-6 {
        width: 50%;
    }

    .col.col-sm-5 {
        width: 41.66666667%;
    }

    .col.col-sm-4 {
        width: 33.33333333%;
    }

    .col.col-sm-3 {
        width: 25%;
    }

    .col.col-sm-2 {
        width: 16.66666667%;
    }

    .col.col-sm-1 {
        width: 8.33333333%;
    }
}

@media (min-width: 768px) {
    .col.col-md-12 {
        width: 100%;
    }

    .col.col-md-11 {
        width: 91.66666667%;
    }

    .col.col-md-10 {
        width: 83.33333333%;
    }

    .col.col-md-9 {
        width: 75%;
    }

    .col.col-md-8 {
        width: 66.66666667%;
    }

    .col.col-md-7 {
        width: 58.33333333%;
    }

    .col.col-md-6 {
        width: 50%;
    }

    .col.col-md-5 {
        width: 41.66666667%;
    }

    .col.col-md-4 {
        width: 33.33333333%;
    }

    .col.col-md-3 {
        width: 25%;
    }

    .col.col-md-2 {
        width: 16.66666667%;
    }

    .col.col-md-1 {
        width: 8.33333333%;
    }
}

@media (min-width: 1024px) {
    .col.col-lg-12 {
        width: 100%;
    }

    .col.col-lg-11 {
        width: 91.66666667%;
    }

    .col.col-lg-10 {
        width: 83.33333333%;
    }

    .col.col-lg-9 {
        width: 75%;
    }

    .col.col-lg-8 {
        width: 66.66666667%;
    }

    .col.col-lg-7 {
        width: 58.33333333%;
    }

    .col.col-lg-6 {
        width: 50%;
    }

    .col.col-lg-5 {
        width: 41.66666667%;
    }

    .col.col-lg-4 {
        width: 33.33333333%;
    }

    .col.col-lg-3 {
        width: 25%;
    }

    .col.col-lg-2 {
        width: 16.66666667%;
    }

    .col.col-lg-1 {
        width: 8.33333333%;
    }
}

/******* COMPONENTS ******/
.btn-submit {
    min-width: 120px;
    background: #05bbc9;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 14px 10px;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.btn-submit:hover {
    background: #fe820e;
    color: #fff;
}

@media (max-width: 991px) {
    .btn-submit {
        min-width: auto;
        padding: 5px 7px;
    }
}

.btn-cancel {
    min-width: 120px;
    background: #637381;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 14px 10px;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.btn-cancel:hover {
    background: #424b52;
    color: #fff;
}

@media (max-width: 991px) {
    .btn-cancel {
        min-width: auto;
        padding: 5px 7px;
    }
}

.btn-primary {
    color: #fff;
    background-color: #05bbc9;
    border-color: #05bbc9;
    transition: 0.3s;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    /* background-color: #0498a4;
    border-color: #048d97; */
    background-color: #05bbc9;
    border-color: #05bbc9;
    box-shadow: 0 50px #fff inset !important;
    color: #05bbc9;
}

.btn-primary:hover .loader-btn,
.btn-primary:active .loader-btn,
.btn-primary:focus .loader-btn {
    border: 3px dotted #05bbc9;
}

/****** LAYOUT ******/
.avatar {
    background-color: transparent;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    height: 34px;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    width: 34px;
    position: relative;
    white-space: nowrap;
    margin: 0 0 0 10px;
    border-radius: 50%;
}

.avatar.avatar-xs {
    width: 24px;
    height: 24px;
}

.avatar > img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
}

.avatar .avatar-title {
    width: 100%;
    height: 100%;
    background-color: #0d6efd;
    color: #fff;
}

.avatar.avatar-online::before {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #28c76f;
}

.avatar.avatar-offline::before {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #f62d51;
}

.avatar.avatar-away::before {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #ff9900;
}

.avatar .border {
    border-width: 3px !important;
}

.avatar .rounded {
    border-radius: 6px !important;
}

.avatar .avatar-title {
    font-size: 18px;
}

.avatar .avatar-xs {
    width: 1.65rem;
    height: 1.65rem;
}

.avatar .avatar-xs .border {
    border-width: 2px !important;
}

.avatar .avatar-xs .rounded {
    border-radius: 4px !important;
}

.avatar .avatar-xs .avatar-title {
    font-size: 12px;
}

.avatar .avatar-xs.avatar-away::before,
.avatar .avatar-xs.avatar-offline::before,
.avatar .avatar-xs.avatar-online::before {
    border-width: 1px;
}

.avatar-sm {
    width: 2.5rem;
    height: 2.5rem;
}

.avatar-sm .border {
    border-width: 3px !important;
}

.avatar-sm .rounded {
    border-radius: 4px !important;
}

.avatar-sm .avatar-title {
    font-size: 15px;
}

.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
    border-width: 2px;
}

.avatar-lg {
    width: 3.75rem;
    height: 3.75rem;
}

.avatar-lg .border {
    border-width: 3px !important;
}

.avatar-lg .rounded {
    border-radius: 8px !important;
}

.avatar-lg .avatar-title {
    font-size: 24px;
}

.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
    border-width: 3px;
}

.avatar-xl {
    width: 5rem;
    height: 5rem;
}

.avatar-xl .border {
    border-width: 4px !important;
}

.avatar-xl .rounded {
    border-radius: 8px !important;
}

.avatar-xl .avatar-title {
    font-size: 28px;
}

.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
    border-width: 4px;
}

.avatar-xxl {
    width: 5.125rem;
    height: 5.125rem;
}

@media (min-width: 768px) {
    .avatar-xxl {
        width: 8rem;
        height: 8rem;
    }
}

.avatar-xxl .border {
    border-width: 6px !important;
}

@media (min-width: 768px) {
    .avatar-xxl .border {
        border-width: 4px !important;
    }
}

.avatar-xxl .rounded {
    border-radius: 8px !important;
}

@media (min-width: 768px) {
    .avatar-xxl .rounded {
        border-radius: 12px !important;
    }
}

.avatar-xxl .avatar-title {
    font-size: 30px;
}

@media (min-width: 768px) {
    .avatar-xxl .avatar-title {
        font-size: 42px;
    }
}

.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
    border-width: 4px;
}

@media (min-width: 768px) {

    .avatar-xxl.avatar-away::before,
    .avatar-xxl.avatar-offline::before,
    .avatar-xxl.avatar-online::before {
        border-width: 4px;
    }
}

.avatar-group .avatar + .avatar {
    margin-left: -0.75rem;
}

.avatar-group .avatar:hover {
    z-index: 1;
}

.avatar-group .avatar-xs + .avatar-xs {
    margin-left: -0.40625rem;
}

.avatar-group .avatar-sm + .avatar-sm {
    margin-left: -0.625rem;
}

.avatar-group .avatar-lg + .avatar-lg {
    margin-left: -1rem;
}

.avatar-group .avatar-xl + .avatar-xl {
    margin-left: -1.28125rem;
}

.avatar-xs {
    width: 1.65rem;
    height: 1.65rem;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #fc8543;
    text-shadow: unset;
}

.bootstrap-datetimepicker-widget table td.today:before {
    border-bottom-color: #fc8543;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
    background: transparent;
}

.bor-b1 {
    border-bottom: 1px solid #e9ecef;
}

.calendar-events {
    border: 1px solid transparent;
    cursor: move;
    padding: 10px 15px;
}

.calendar-events:hover {
    border-color: #eeeeee;
    background-color: #fff;
}

.calendar-events i {
    margin-right: 8px;
}

.calendar {
    float: left;
    margin-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5rem;
}

.none-border .modal-footer {
    border-top: none;
}

.fc-toolbar h2 {
    font-size: 18px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    line-height: 30px;
    text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
    font-family: "Roboto", sans-serif;
}

.fc-day {
    background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
    z-index: 0;
}

.fc th.fc-widget-header {
    background: #eeeeee;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
    border-color: #f3f3f3;
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
    padding: 2px 5px;
}

.fc-button {
    background: #f1f1f1;
    border: none;
    color: #797979;
    text-transform: capitalize;
    box-shadow: none !important;
    border-radius: 3px !important;
    margin: 0 3px !important;
    padding: 6px 12px !important;
    height: auto !important;
}

.fc-text-arrow {
    font-family: inherit;
    font-size: 16px;
}

.fc-state-hover {
    background: #f3f3f3;
}

.fc-state-highlight {
    background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: #05bbc9 !important;
    color: #fff !important;
    text-shadow: none !important;
}

.fc-cell-overlay {
    background: #f0f0f0;
}

.fc-unthemed .fc-today {
    background: #fff;
}

.fc-event {
    border-radius: 2px;
    border: none;
    color: #fff !important;
    cursor: move;
    font-size: 13px;
    margin: 1px 7px;
    padding: 5px 5px;
    text-align: center;
}

.fc-basic-view td.fc-week-number span {
    padding-right: 8px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
}

.fc-basic-view td.fc-day-number {
    padding-right: 8px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
}

.event-form .input-group .form-control {
    height: 40px;
}

.submit-section {
    text-align: center;
    margin-top: 40px;
}

.submit-btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

.apexcharts-legend.apexcharts-align-center.position-right {
    display: none;
}

.apexcharts-toolbar {
    display: none !important;
}

#sales_charts,
.chart-set {
    overflow: hidden;
}

.apexcharts-canvas {
    width: 100% !important;
}

.page-wrapper {
    position: relative;
    margin: 0 10px;
    margin-top: 2px;
}

.page-wrapper .content {
    min-height: calc(100vh - 94px);
    margin: 0 auto;
    border-radius: 15px;
    border-top: 4px solid #d5f4f7;
    background: #fafbfe;
    padding: 12px;
}

.card {
    border-radius: 8px;
    border: 0;
    box-shadow: 0px 16px 40px 0px #0000000a;
    margin-bottom: 12px;
}

.page-wrapper .content .card:last-child {
    margin-bottom: 0;
}

.card .card-header {
    background: transparent;
    padding: 15px;
    border-bottom: 1px solid rgb(0 0 0 / 8%);
}

.card .card-header .dropset {
    color: #6e6b7b;
    padding: 0 10px;
}

.card .card-header .card-title {
    font-size: 18px;
    color: #212b36;
    margin: 0;
}

.btn-white {
    background-color: #fff;
    border: 1px solid rgba(145, 158, 171, 0.32);
    color: #212b36;
    padding: 3px 10px;
    border-radius: 5px;
    min-width: 90px;
}

.inputcheck {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    color: #000;
    font-weight: 500;
    font-size: 14px;
}

.inputcheck input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.inputcheck input:checked ~ .checkmark:after {
    display: block;
}

.inputcheck .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #e9ecef;
    background: #f8f8f8;
    border-radius: 5px;
}

.inputcheck .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.inputcheck .checkmark:after {
    position: absolute;
    top: 3px;
    left: 6px;
    width: 5px;
    height: 10px;
    border: solid #05bbc9;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}

.inputcheck input:checked ~ .checkmark {
    border: 1px solid #05bbc9;
}

.animation-tada {
    animation: tada 1.5s ease infinite;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 50px !important;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #3abdc8;
    border-radius: 10px;
}

.swal2-header .swal2-title {
    font-size: 18px;
}

.none {
    display: none !important;
}

.text-red {
    color: #ea5455 !important;
    font-family: 'bold';
}

.text-green {
    color: #2cb6c1 !important;
    font-family: 'bold';
}

a:focus,
a:hover {
    outline: none;
}

.form-control:focus {
    box-shadow: none;
    border-color: #05bbc9;
}

.form-group input[type="text"]:focus {
    border-color: #05bbc9;
    background: #fff;
}

.dropdown-menu {
    padding: 0;
}

.dropdown-menu .dropdown-item {
    color: #212b36;
    font-size: 13px;
    padding: 8px 15px;
    font-weight: 500;
}

.input-group .scanner-set {
    background: #05bbc9;
    border-radius: 5px !important;
    width: 40px;
    color: #fff;
    font-weight: 600;
    padding: 0;
}

.input-group .scanner-set:hover {
    background: #1b2850;
}

.input-group {
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
}

#filter_inputs,
#filter_inputs1,
#filter_inputs2 {
    display: none;
}

.form-group {
    margin-bottom: 25px;
}

.form-group label {
    margin-bottom: 2px;
    color: #64748b;
    font-size: 14px;
    display: block;
    font-family: "bold";
}

.form-group label .manitory {
    color: #ff0000;
}

.form-group .input-groupicon {
    position: relative;
}

.form-group .input-groupicon .addonset {
    position: absolute;
    left: 12px;
    top: 50%;
    line-height: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

@media (max-width: 991px) {
    .form-group .input-groupicon .addonset {
        display: none;
    }
}

.form-group textarea {
    height: 136px;
    width: 100%;
    padding: 10px 15px;
    resize: unset;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #05bbc9;
}

.section-header {
    margin-bottom: 1.875rem;
}

.section-header .section-title {
    color: #333;
    font-weight: 600;
}

.section-header .line {
    background-color: #05bbc9;
    height: 2px;
    margin: 0;
    width: 60px;
}

.comp-section .card-header {
    border: 1px solid #f0f1f5;
    background-color: #fff;
    padding: 10px;
}

.comp-section .card-header .card-title {
    margin: 0;
}

.comp-section .tab-content {
    padding-top: 20px;
}

.comp-section .bullets {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
}

.comp-section .bullets li {
    margin-bottom: 5px;
}

.comp-section .bullets ul {
    padding-left: 20px;
}

.comp-section ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
}

.comp-section ol li {
    margin-bottom: 5px;
}

.comp-section ol ul {
    padding-left: 20px;
}

.comp-section .list-unstyled li {
    margin-bottom: 5px;
}

.comp-section .list-unstyled ul {
    padding-left: 20px;
}

.comp-section .nav-tabs > li > a {
    margin-right: 0;
    color: #888888;
}

.comp-section .avatar-group .avatar {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem;
}

.comp-section .avatar-group .avatar + .avatar {
    margin-left: -1.35rem;
}

.comp-section .nav-tabs.nav-tabs-solid {
    background-color: #fafafa;
    border: 0;
}

.comp-section .nav-tabs.nav-tabs-solid.nav-tabs-rounded a {
    border-radius: 50px;
}

.comp-section .nav-tabs.nav-tabs-solid a {
    padding: 10px 30px;
}

.comp-section .nav-tabs.nav-tabs-solid a.active {
    background-color: #05bbc9;
    border-color: #05bbc9;
    color: #fff;
}

.comp-section .nav-tabs .nav-tabs-bottom {
    border-bottom: 1px solid #e6e6e6;
}

.comp-section .nav-tabs .nav-tabs-bottom li {
    margin-bottom: -1px;
}

.comp-section .nav-tabs .nav-tabs-bottom li a.active {
    border-bottom-width: 2px;
    border-color: transparent;
    border-bottom-color: #05bbc9;
    background-color: transparent;
    transition: none 0s ease 0s;
}

.comp-section .progress {
    margin-bottom: 1.5rem;
}

.comp-section .progress.progress-lg {
    height: 18px;
}

.comp-section .progress.progress-md {
    height: 8px;
}

.comp-section .progress.progress-sm {
    height: 6px;
}

.comp-section .progress.progress-xs {
    height: 4px;
}

.btn-outline-primary {
    color: #05bbc9;
    border-color: #05bbc9;
}

.btn-outline-primary:hover {
    border-color: #05bbc9;
    background: #05bbc9;
    color: #fff;
}

.btn-outline-info:hover,
.btn-outline-warning:hover {
    color: #fff;
}

.cardhead .card-header {
    border-bottom: 1px solid #e8ebed;
    background: transparent;
    padding: 15px;
}

.cardhead .card-header .card-title {
    margin: 0;
}

.breadcrumb {
    margin: 0;
}

.breadcrumb .breadcrumb-item a {
    color: #000;
}

.ribbon {
    height: 30px;
    line-height: 30px;
    clear: left;
    color: #fff;
    z-index: 1;
    padding: 0 20px;
    position: absolute;
    top: 12px;
    left: 0;
}

.ribbon.ribbon-primary {
    background: #05bbc9;
}

.ribbon.ribbon-secondary {
    background: #f62d51;
}

.ribbon.ribbon-success {
    background: #26af48;
}

.ribbon.ribbon-right {
    left: auto;
    right: -2px;
}

.ribbon.ribbon-vertical-left {
    clear: none;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    position: absolute;
    top: -2px;
    left: 12px;
    padding: 0 5px;
}

.ribbon.ribbon-vertical-right {
    clear: none;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    position: absolute;
    top: -2px;
    right: 12px;
    left: auto;
    padding: 0 5px;
}

.ribbon-wrapper {
    position: relative;
    padding-top: 40px;
}

.ribbon-vertical-left-wrapper {
    padding-left: 40px;
    position: relative;
}

.ribbon-vertical-right-wrapper {
    padding-right: 40px;
    position: relative;
}

.list-group .list-group-item .handle {
    padding: 0 5px;
    margin-left: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: move;
    font-size: 16px;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle > i:first-child,
.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
    background-color: #05bbc9;
}

/*-----------------
 Alert
-----------------------*/
.alertify .ajs-commands {
    right: 4px;
    left: auto;
    margin: -14px 0 0 24px;
}

.alertify .ajs-body .ajs-content .ajs-input:focus-visible {
    outline: 0;
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
    font-weight: 500;
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: #05bbc9;
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-primary {
    text-align: right;
}

.alertify .ajs-header {
    border-bottom: 1px solid #eeeeee;
}

.alertify .ajs-footer {
    border-top: 1px solid #eeeeee;
}

.alertify-notifier .ajs-message {
    background-color: #05bbc9;
    border-color: #05bbc9;
    color: #fff;
    text-shadow: none !important;
}

.alertify-notifier .ajs-message.ajs-success {
    background-color: #26af48;
    border-color: #26af48;
}

.alertify-notifier .ajs-message.ajs-error {
    background-color: #f62d51;
    border-color: #f62d51;
}

.alertify-notifier .ajs-message.ajs-warning {
    background-color: #05bbc9;
    border-color: #05bbc9;
}

.alertify-notifier.ajs-right {
    right: 10px;
    left: auto;
}

.alertify-notifier.ajs-right .ajs-message {
    right: -320px;
    left: auto;
}

.alertify-notifier.ajs-right .ajs-message.ajs-visible {
    right: 290px;
    left: auto;
}

.alertify-notifier.ajs-left {
    left: 10px;
    right: auto;
}

.alertify-notifier.ajs-left .ajs-message {
    left: -300px;
    right: auto;
}

.alertify-notifier.ajs-left .ajs-message.ajs-visible {
    left: 0;
    right: auto;
}

body[data-layout-mode="dark"] .alertify .ajs-dialog {
    background-color: #333;
}

body[data-layout-mode="dark"] .alertify .ajs-footer {
    color: #b8bcc9;
    background-color: #333;
    border-color: #333;
}

body[data-layout-mode="dark"] .alertify .ajs-footer .ajs-buttons .ajs-button {
    color: #b8bcc9;
}

body[data-layout-mode="dark"] .alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: #05bbc9;
}

body[data-layout-mode="dark"] .alertify .ajs-header {
    color: #b8bcc9;
    background-color: #333;
    border-color: #333;
}

body[data-layout-mode="dark"] .alertify .ajs-body {
    color: #b8bcc9;
}

body[data-layout-mode="dark"] .alertify .ajs-body .ajs-content .ajs-input {
    color: #b8bcc9;
    background-color: #333;
    border: 1px solid #333;
}

body[data-layout-mode="dark"] .alertify .ajs-content .ajs-input {
    display: block;
    width: 100%;
    border-radius: 2px;
    border: 1px solid #cccccc;
    margin: 4px;
    padding: 8px;
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
    background-color: transparent;
    color: #000;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.timeline {
    position: relative;
    list-style: none;
    max-width: 1200px;
    float: left;
    margin: 0 auto;
    padding: 20px 0;
}

.timeline:before {
    content: " ";
    width: 3px;
    margin-left: -1.5px;
    background-color: #e9ecef;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
}

@media (max-width: 991px) {
    .timeline:before {
        left: 40px;
    }
}

.timeline li {
    position: relative;
    margin-bottom: 20px;
    float: left;
    width: 100%;
}

.timeline li:before,
.timeline li:after {
    content: " ";
    display: table;
}

.timeline li .timeline-badge {
    z-index: 1;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    color: #fff;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    left: 50%;
}

@media (max-width: 991px) {
    .timeline li .timeline-badge {
        top: 16px;
        left: 15px;
        margin-left: 0;
    }
}

.timeline li .timeline-badge.success {
    background-color: #26af48;
}

.timeline li .timeline-badge.warning {
    background-color: #ff9900;
}

.timeline li .timeline-badge.danger {
    background-color: #f62d51;
}

.timeline li .timeline-badge.info {
    background-color: #0d6efd;
}

.timeline li .timeline-panel {
    float: left;
    position: relative;
    width: 46%;
    padding: 20px;
    border: 1px solid #e9ecef;
    border-radius: 0.25rem;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}

@media (max-width: 991px) {
    .timeline li .timeline-panel {
        float: right;
        width: calc(100% - 90px);
    }
}

.timeline li.timeline-inverted .timeline-panel {
    float: right;
}

/*-----------------
 Timeline
-----------------------*/
.timeline {
    position: relative;
    list-style: none;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0 20px;
}

.timeline:before {
    content: " ";
    position: absolute;
    width: 3px;
    margin-left: -1.5px;
    background-color: #e9ecef;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
}

.timeline > li {
    position: relative;
    margin-bottom: 20px;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li > .timeline-panel {
    float: left;
    position: relative;
    width: 46%;
    padding: 20px;
    border: 1px solid #e9ecef;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}

.timeline > li > .timeline-panel:before {
    content: " ";
    display: inline-block;
    position: absolute;
    border-top: 8px solid transparent;
    border-right: 0 solid #e9ecef;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #e9ecef;
    position: absolute;
    top: 26px;
    right: -8px;
}

.timeline > li > .timeline-panel:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 27px;
    right: -7px;
    border-top: 7px solid transparent;
    border-right: 0 solid #fff;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #fff;
}

.timeline > li > .timeline-badge {
    z-index: 1;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    color: #fff;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    left: 50%;
}

.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
    right: auto;
    left: -8px;
    border-right-width: 8px;
    border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
    right: auto;
    left: -7px;
    border-right-width: 7px;
    border-left-width: 0;
}

.timeline-badge.primary {
    background-color: #05bbc9;
}

.timeline-badge.success {
    background-color: #26af48;
}

.timeline-badge.warning {
    background-color: #ff9900;
}

.timeline-badge.danger {
    background-color: #f62d51;
}

.timeline-badge.info {
    background-color: #0d6efd;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
    font-weight: 400;
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
    margin-top: 5px;
}

.cd-horizontal-timeline .events a {
    padding-bottom: 6px;
    color: #05bbc9;
}

.cd-horizontal-timeline .filling-line,
.cd-horizontal-timeline .events a.selected::after {
    background: #05bbc9;
}

.cd-horizontal-timeline .events a.selected::after {
    border-color: #05bbc9;
}

.cd-horizontal-timeline .m-t-40 {
    margin-top: 25px !important;
}

.cd-horizontal-timeline ol,
.cd-horizontal-timeline ul {
    list-style: none;
}

.cd-timeline-navigation a:hover,
.cd-timeline-navigation a:focus {
    border-color: #05bbc9;
}

.cd-horizontal-timeline a,
.cd-horizontal-timeline a:hover,
.cd-horizontal-timeline a:focus {
    color: #05bbc9;
}

.cd-horizontal-timeline blockquote,
.cd-horizontal-timeline q {
    quotes: none;
}

.cd-horizontal-timeline blockquote:before,
.cd-horizontal-timeline blockquote:after,
.cd-horizontal-timeline q:before,
.cd-horizontal-timeline q:after {
    content: "";
    content: none;
}

.cd-horizontal-timeline table {
    border-collapse: collapse;
    border-spacing: 0;
}

.cd-horizontal-timeline {
    opacity: 0;
    margin: 2em auto;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.cd-horizontal-timeline::before {
    content: "mobile";
    display: none;
}

.cd-horizontal-timeline.loaded {
    opacity: 1;
}

.cd-horizontal-timeline .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
}

.cd-horizontal-timeline .events-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
    margin: 0 40px;
}

.cd-horizontal-timeline .events-wrapper::after,
.cd-horizontal-timeline .events-wrapper::before {
    content: "";
    z-index: 2;
    height: 100%;
    width: 20px;
    position: absolute;
    top: 0;
}

.cd-horizontal-timeline .events-wrapper::before {
    left: 0;
}

.cd-horizontal-timeline .events-wrapper::after {
    right: 0;
}

.cd-horizontal-timeline .events {
    z-index: 1;
    height: 2px;
    background: #dfdfdf;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: absolute;
    top: 30px;
    left: 0;
}

.cd-horizontal-timeline .filling-line {
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: #05bbc9;
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform-origin: left center;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: absolute;
    top: 0;
    left: 0;
}

.cd-horizontal-timeline .events a {
    z-index: 2;
    text-align: center;
    font-size: 16px;
    padding-bottom: 15px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    position: absolute;
    bottom: 0;
}

.cd-horizontal-timeline .events a::after {
    content: "";
    height: 12px;
    width: 12px;
    border: 2px solid #dfdfdf;
    background-color: #f8f8f8;
    transition: background-color 0.3s, border-color 0.3s;
    border-radius: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    position: absolute;
    right: auto;
    bottom: -5px;
    left: 50%;
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
    background-color: #05bbc9;
    border-color: #05bbc9;
}

.cd-horizontal-timeline .events a.selected {
    pointer-events: none;
}

.cd-horizontal-timeline .events a.selected::after {
    background-color: #05bbc9;
    border-color: #05bbc9;
}

.cd-horizontal-timeline .events a.older-event::after {
    border-color: #05bbc9;
}

.cd-timeline-navigation a {
    z-index: 1;
    height: 34px;
    width: 34px;
    border: 2px solid #dfdfdf;
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    transition: border-color 0.3s;
    border-radius: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
    top: 50%;
    bottom: auto;
}

.cd-timeline-navigation a::after {
    content: "";
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
}

.cd-timeline-navigation a.prev {
    transform: translateY(-50%) rotate(180deg);
    -webkit-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
}

.cd-timeline-navigation a.next {
    right: 0;
}

.no-touch .cd-timeline-navigation a:hover {
    border-color: #7b9d6f;
}

.cd-timeline-navigation a.inactive {
    cursor: not-allowed;
}

.cd-timeline-navigation a.inactive::after {
    background-position: 0 -16px;
}

.no-touch .cd-timeline-navigation a.inactive:hover {
    border-color: #dfdfdf;
}

.cd-horizontal-timeline .events-content {
    position: relative;
    width: 100%;
    margin: 2em 0;
    overflow: hidden;
    transition: height 0.4s;
}

.cd-horizontal-timeline .events-content li {
    z-index: 1;
    width: 100%;
    padding: 0 5%;
    opacity: 0;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
}

.cd-horizontal-timeline .events-content li.selected {
    position: relative;
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
}

.cd-horizontal-timeline .events-content li.enter-right,
.cd-horizontal-timeline .events-content li.leave-right {
    -webkit-animation-name: cd-enter-right;
    -moz-animation-name: cd-enter-right;
    animation-name: cd-enter-right;
}

.cd-horizontal-timeline .events-content li.enter-left,
.cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-name: cd-enter-left;
    -moz-animation-name: cd-enter-left;
    animation-name: cd-enter-left;
}

.cd-horizontal-timeline .events-content li.leave-right,
.cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-direction: reverse;
    -moz-animation-direction: reverse;
    animation-direction: reverse;
}

.cd-horizontal-timeline .events-content li > * {
    max-width: 800px;
    margin: 0 auto;
}

.cd-horizontal-timeline .events-content h2 {
    font-weight: 600;
    margin-bottom: 0;
}

.cd-horizontal-timeline .events-content em {
    display: block;
    font-style: italic;
    margin: 10px auto;
}

.cd-horizontal-timeline .events-content em::before {
    content: "- ";
}

.cd-horizontal-timeline .events-content p {
    font-size: 16px;
}

.cd-horizontal-timeline .timeline:before {
    content: " ";
    display: none;
    bottom: 0;
    left: 0%;
    width: 0px;
    margin-left: -1.5px;
    background-color: #eeeeee;
}

.cd-horizontal-timeline .events-content em,
.cd-horizontal-timeline .events-content p {
    line-height: 30px;
}

.h-250 {
    height: 250px;
}

.icons-list {
    padding: 0;
}

.icons-list li {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 18px;
    border-radius: 50px;
    border: 1px solid #e6ebf1;
    color: #05bbc9;
    margin: 5px;
    -webkit-box-shadow: 0 2px 3px #d7c5ff;
    -moz-box-shadow: 0 2px 3px #d7c5ff;
    box-shadow: 0 2px 3px #d7c5ff;
}

.cardhead .page-title {
    font-weight: 600;
    font-size: 22px;
}

.email-header .btn-white {
    min-width: auto;
}

.email-header .btn-white i {
    margin-right: 5px;
}

.icons-items .icons-list {
    padding: 0;
}

.icons-items .icons-list li {
    width: 40px;
    height: 40px;
    font-size: 18px;
    border-radius: 50px;
    border: 1px solid #e6ebf1;
    color: #05bbc9;
    margin: 5px;
    -webkit-box-shadow: 0 2px 3px #d7c5ff;
    -moz-box-shadow: 0 2px 3px #d7c5ff;
    box-shadow: 0 2px 3px #d7c5ff;
}

.custom-file-container {
    box-sizing: border-box;
    position: relative;
    display: block;
}

.custom-file-container label {
    color: #4361ee;
}

.custom-file-container .custom-file-container__custom-file {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
    margin-top: 5px;
}

.custom-file-container input[type="file"] {
    height: auto;
    min-height: calc(1.5em + 0.75rem + 2px);
}

.custom-file-container .custom-file-container__custom-file__custom-file-input {
    box-sizing: border-box;
    min-width: 14rem;
    max-width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-container .custom-file-container__custom-file__custom-file-control {
    box-sizing: border-box;
    z-index: 1;
    height: auto;
    overflow: hidden;
    line-height: 1.5;
    user-select: none;
    background-clip: padding-box;
    border-radius: 0.25rem;
    height: auto;
    border: 1px solid #f3f2f7;
    color: #212b36;
    font-size: 15px;
    padding: 8px 10px;
    letter-spacing: 1px;
    background-color: #f3f2f7;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

.custom-file-container .custom-file-container__custom-file__custom-file-control__button {
    box-sizing: border-box;
    z-index: 6;
    display: block;
    height: auto;
    padding: 10px 16px;
    line-height: 1.25;
    background-color: rgba(27, 85, 226, 0.23);
    color: #4361ee;
    border-left: 1px solid #e0e6ed;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
}

.custom-file-container .custom-file-container__image-preview {
    box-sizing: border-box;
    transition: all 0.2s ease;
    margin-top: 50px;
    margin-bottom: 40px;
    height: 250px;
    width: 100%;
    border-radius: 4px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    overflow: auto;
    padding: 15px;
}


.error-box {
    max-width: 480px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 1.875rem 0;
}

.error-box h1 {
    color: #05bbc9;
    font-size: 10em;
    line-height: 155px;
}

.error-box h3 {
    font-size: 26px;
    margin-bottom: 5px;
}

.error-box p {
    margin-bottom: 25px;
    font-size: 20px;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link::after {
    left: auto;
    right: 62%;
}

.apexcharts-legend-marker {
    margin-left: 3px;
}

table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:after {
    right: 0.5em;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    top: 14px;
    color: #babfc7;
    font-size: 12px;
    opacity: 1;
}

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:before {
    right: 0.5em;
    content: "\f106";
    font-family: "Font Awesome 5 Free";
    top: 5px;
    color: #babfc7;
    font-size: 12px;
    opacity: 1;
}

.paging_numbers {
    float: right;
}

.dataTables_info {
    float: right;
    padding-right: 15px;
    font-size: 12px;
    color: #5e5873;
    font-weight: 600;
}

.custom-select {
    min-width: 90px;
    background: url(../img/icons/dropdown.svg) no-repeat 8% 50%;
    padding-left: 30px !important;
    background-size: 10px;
}

.pagination li {
    margin: 0 5px;
}

.pagination li a {
    border-radius: 5px;
    background: #637381;
    color: #fff;
}

.pagination li a:hover {
    background: #05bbc9;
    color: #fff;
}

.pagination li.active a.page-link {
    background: #05bbc9;
    border-color: #05bbc9;
    border-radius: 5px;
}

.dataTables_length,
.dataTables_paginate {
    margin-top: 15px !important;
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: 23px;
}

.header {
    position: relative;
    display: flex;
    gap: 35px;
    padding: 5px 10px;
    align-items: center;
    background: #fff;
    background: linear-gradient(180deg, #defbff, #fff);
    height: 60px;
}

.header:hover .top-nav-search form .form-control {
    border: 1px solid #f1f1f1;
}

.header .searchinputs {
    position: absolute;
    top: -80px;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.header .searchinputs.show {
    position: absolute;
    top: 0px;
}

.header .dropdown-menu {
    transition: all 0.5s ease;
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    border: 0;
    left: 0;
    display: block;
    opacity: 0;
    visibility: hidden;
    box-shadow: rgb(98 95 95 / 7%) 0px 6px 12px 2px;
    border-top: 2px solid #d5f4f7;
    width: 200px;
    border-radius: 10px 0;
    overflow: hidden;
}

.header .dropdown-menu.show {
    transform: translateY(15px);
    -webkit-transform: translateY(15px);
    opacity: 1;
    visibility: visible;
}

@media (max-width: 991px) {
    .header .dropdown-menu {
        transition: none;
    }
}

.header .header-left .logo img {
    height: 50px;
    max-width: 210px;
    object-fit: contain;
}

.header .page-title-box {
    float: left;
    height: 60px;
    margin-bottom: 0;
    padding: 17px 20px;
    border-radius: 0;
}

@media (max-width: 991px) {
    .header .page-title-box {
        display: none;
    }
}

.header .page-title-box a {
    color: #05bbc9;
    font-size: 11px;
    font-weight: 700;
    border: 1px solid #05bbc9;
    margin: 0;
    padding: 5px;
    border-radius: 5px;
}

.header .page-title-box a:hover {
    background: #05bbc9;
    color: #fff;
}

.header .mobile_btn {
    display: none;
    cursor: pointer;
}

.header .responsive-search {
    display: none;
    color: #fff;
    font-size: 20px;
    height: 60px;
    line-height: 60px;
    padding: 0 15px;
}

@media (max-width: 991px) {
    .header .responsive-search {
        display: block;
    }
}

.header .top-nav-search form {
    margin-top: 10px;
    position: relative;
    width: 230px;
}

@media (max-width: 991px) {
    .header .top-nav-search form {
        display: none;
    }
}

.header .top-nav-search form .form-control {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.15);
    height: 40px;
    padding: 10px 50px 10px 15px;
    border-radius: 50px;
}

.header .top-nav-search form .form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.header .top-nav-search form .btn {
    background-color: transparent;
    border-color: transparent;
    color: rgba(255, 255, 255, 0.7);
    min-height: 40px;
    position: absolute;
    top: 0;
    left: 0;
}

@media (max-width: 991px) {
    .header .top-nav-search.active form {
        display: block;
        position: absolute;
        left: 0;
    }

    .header .top-nav-search.active form .form-control {
        background-color: #fff;
    }

    .header .top-nav-search.active form .btn {
        background-color: transparent;
        border-color: transparent;
        min-height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        padding: 7px 15px;
    }
}

.header .userset .usernames {
    line-height: initial;
    text-align: right;
    padding-right: 10px;
}

.header .userset .usernames span {
    display: block;
    line-height: initial;
}

.header .menu-drop-user .dropdown-item {
    color: #637381;
    padding: 5px 10px !important;
    font-size: 15px;
    display: flex;
    gap: 7px;
}

.header .menu-drop-user .dropdown-item:hover,
.header .menu-drop-user .dropdown-item:focus,
.header .menu-drop-user .dropdown-item:active {
    background: #f7f7f7;
}

.header .menu-drop-user .dropdown-item svg {
    stroke-width: 1px;
    margin-right: 10px;
    width: 18px;
}

.header .menu-drop-user .logout {
    color: #f62d51;
}

@media (max-width: 991px) {
    .header .has-arrow .dropdown-toggle > span:nth-child(2) {
        display: none;
    }

    .header .has-arrow .dropdown-toggle:after {
        display: none !important;
    }
}

.header .profilename .user-img .status {
    bottom: 10px;
}

.header .user-img {
    display: inline-block;
    position: relative;
}

.header .user-img i {
    font-size: 38px !important;
    color: #54677c;
    display: flex;
    line-height: inherit !important;
}

.header .user-img .status {
    border: 2px solid #fff;
    height: 10px;
    width: 10px;
    margin: 0;
    position: absolute;
    right: 0;
    bottom: 30px;
    border-radius: 50%;
    display: inline-block;
    background: #28c76f;
}

.header .mobile-user-menu {
    color: #fff;
    display: none;
    float: right;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    text-align: left;
    width: 60px;
    z-index: 10;
    padding: 0 20px;
    position: absolute;
    top: 0;
    left: 0;
}

@media (max-width: 575px) {
    .header .mobile-user-menu {
        display: block;
    }
}

.header .mobile-user-menu a {
    color: #05bbc9;
    padding: 0;
}

.header .mobile-user-menu a::after {
    display: none;
}

.header .mobile-user-menu .dropdown-menu {
    padding: 0;
}

.header .mobile-user-menu .dropdown-menu a {
    color: #333;
    line-height: 40px;
    padding: 0 10px;
}

.logo-small img {
    width: 30px;
}

@media (min-width: 992px) {
    .mini-sidebar.expand-menu .header-left {
        flex-direction: row;
        justify-content: space-between;
    }

    .mini-sidebar .header-left {
        width: 80px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .mini-sidebar .header-left .logo-small {
        display: block;
        padding: 20px 0 0;
    }

    .mini-sidebar .header-left .logo {
        display: none;
    }

    .mini-sidebar .menu-arrow {
        display: none !important;
    }

    .mini-sidebar .chat-user {
        display: none !important;
    }

    .mini-sidebar .badge {
        display: none !important;
    }

    .mini-sidebar .user-menu .badge {
        display: flex !important;
    }

    .mini-sidebar.expand-menu .menu-arrow {
        display: block !important;
    }

    .mini-sidebar.expand-menu .chat-user {
        display: block !important;
    }

    .mini-sidebar.expand-menu .badge {
        display: block !important;
    }

    .mini-sidebar.expand-menu .logo-small {
        display: none;
    }

    .mini-sidebar.expand-menu .logo {
        display: block;
    }

    .mini-sidebar.expand-menu #toggle_btn {
        opacity: 1;
    }

    .mini-sidebar.expand-menu .header-left {
        padding: 0 20px;
        display: flex;
    }

    .mini-sidebar .noti-dot:before {
        display: none;
    }
}

.header-pos.header {
    position: relative;
}

.searchpart {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    background: #fff;
    z-index: 100000;
    width: 350px;
    margin-right: -350px;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-box-shadow: 0 0px 7px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0px 7px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0px 7px rgba(0, 0, 0, 0.2);
}

.searchpart.active {
    margin-right: 0;
}

.searchpart .searchcontent {
    padding: 20px;
    display: block;
    height: 100%;
}

.searchpart .searchhead {
    border-bottom: 1px solid #b8bcc9;
    color: #333;
}

.searchpart .searchcontents {
    padding: 25px 0;
}

.searchpart .searchparts {
    position: relative;
}

.searchpart .searchparts input[type="text"] {
    width: 100%;
    height: 50px;
    border: 1px solid #b8bcc9;
    padding: 0 15px;
    border-radius: 50px;
}

.searchpart .searchparts .btn-searchs {
    position: absolute;
    top: 5px;
    right: 10px;
    background: #05bbc9;
    border-radius: 50px;
    color: #fff;
}

.searchpart .recentsearch {
    margin-top: 15px;
    border-top: 1px solid #9f9f9f;
    padding-top: 15px;
}

.searchpart .recentsearch h2 {
    font-size: 16px;
    margin-bottom: 25px;
    color: #333;
    font-weight: 700;
}

.searchpart .recentsearch li {
    border-bottom: 1px solid #9f9f9f;
    padding: 10px;
}

.searchpart .recentsearch li h6 {
    font-size: 14px;
}

.searchpart .searchhead h3 {
    font-weight: 600;
    font-size: 20px;
}

.header-pos {
    padding: 10px;
    background: #fff;
    margin-bottom: 20px;
    border-bottom: 1px solid #e8ebed;
    height: 80px;
}

@media (max-width: 991px) {
    .header-pos {
        padding: 10px;
        height: auto;
    }
}

.header-pos #mobile_btn {
    display: none;
    float: left;
}

@media (max-width: 991px) {
    .header-pos #mobile_btn {
        color: #05bbc9;
        font-size: 24px;
        height: 60px;
        line-height: 60px;
        width: 60px;
        z-index: 10;
        padding: 0 20px;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
    }
}

.header-pos.header .topnav-dropdown-header {
    height: 40px;
    padding: 10px;
}

.header-pos.header .topnav-dropdown-header .clear-noti {
    color: #ea5455 !important;
    float: right;
    font-size: 11px !important;
    text-transform: uppercase;
}

.header-pos.header .topnav-dropdown-footer {
    border-top: 1px solid #eeeeee;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
}

.header-pos.header .topnav-dropdown-footer a {
    display: block;
    text-align: center;
    color: #333;
    padding: 5px 12px;
    font-size: 12px !important;
}

.header-pos.header .user-menu.nav li {
    height: 40px;
}

.header-pos.header .user-menu.nav li a {
    color: #212b36;
    line-height: normal;
    height: auto;
    font-size: 15px;
    padding: 0 8px;
}

.header-pos.header .user-menu.nav li a.dropdown-toggle.nav-link.show {
    background: transparent;
}

.header-pos.header .user-menu.nav li.notification-message {
    height: auto;
}

.header-pos.header .user-img img {
    margin-top: 0;
}

.header-pos.header .user-img .status {
    border: 2px solid #fff;
    height: 10px;
    width: 10px;
    margin: 0;
    border-radius: 50%;
    display: inline-block;
    background: #28c76f;
    position: absolute;
    bottom: 6px;
    left: 0;
}

@media (max-width: 991px) {
    .header-pos.header .user-menu {
        display: none;
    }
}

.header-pos.header .user-menu .noti-content .notification-list a {
    line-height: normal;
    height: auto;
    padding: 8px;
}

@media (max-width: 991px) {
    .header-split {
        justify-content: center;
    }
}

@media (max-width: 991px) {
    .header-split .header-menu {
        display: none;
    }
}

.header-split .header-menu ul li {
    margin-right: 10px;
}

.header-split .header-menu ul li a {
    border: 1px solid #e0e0e0;
    color: #000;
    border-radius: 5px;
    padding: 5px 8px;
}

.header-split .header-menu ul li a.links {
    border: 0;
}

.header-split .header-menu ul li a:hover {
    background: #05bbc9;
    color: #fff;
}

.header-split .header-menu ul li a:hover img {
    filter: brightness(0) invert(1);
}

.header-split .header-menu ul li.mobile-show {
    display: block;
}

@media (max-width: 991px) {
    .header-split .header-menu ul li.mobile-show {
        display: block;
    }
}

.mobile-show {
    display: none;
}

@media (max-width: 991px) {
    .mobile-show {
        display: block;
    }
}

.header-menus {
    position: absolute;
    right: 30px;
    display: none;
}

@media (max-width: 991px) {
    .header-menus {
        display: block;
    }
}

.bar-icon {
    display: inline-block;
    width: 35px;
}

.bar-icon span {
    background-color: #05bbc9;
    display: block;
    float: right;
    height: 3px;
    margin-bottom: 7px;
    width: 100%;
    border-radius: 2px;
    transition: all 0.5s;
}

.bar-icon span:last-child {
    margin-bottom: 0;
}

.menu-opened .slide-nav .bar-icon {
    display: inline-block;
    width: 31px;
    margin-top: 20px;
}

.menu-opened .slide-nav .bar-icon span:first-child {
    position: relative;
    top: 10px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}

.menu-opened .slide-nav .bar-icon span:nth-child(2) {
    display: none;
}

.menu-opened .slide-nav .bar-icon span:last-child {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
}

hr {
    background-color: #e8ebed;
    opacity: 1;
}

.profilename {
    padding: 0px;
}

.profilename .profileset {
    padding: 6px;
}

.profilename .profileset h6 {
    font-size: 16px;
    color: #212b36;
    text-transform: capitalize;
    line-height: 20px;
}

.profilename .profileset h5 {
    font-size: 14px;
    color: #637381;
}

.profilename .profileset .user-img img {
    margin-top: 0;
}

.user-menu {
    margin: 0;
    position: relative;
    margin-right: auto;
}

.user-menu .dropdown-toggle::after {
    border: 0;
}

.user-menu.nav > li > a {
    color: #212b36;
    font-size: 15px;
    padding: 0;
}

.user-menu.nav > li > a.dropdown-toggle.nav-link.show {
    background: #eeeeee;
}

.user-menu.nav > li > a .badge {
    color: #fff;
    font-weight: 700;
    position: absolute;
    top: 6px;
    right: 3px;
    background: #05bbc9;
    width: 20px;
    height: 20px;
}

.user-menu.nav > li > a i {
    font-size: 20px;
    line-height: 60px;
}

.user-menu.nav > li.flag-nav a img {
    width: 24px;
    height: 24px;
}

.user-menu.nav > li.flag-nav a .dropdown-menu {
    max-height: 150px;
    min-width: 120px;
    overflow-y: auto;
}

.user-menu.nav > li.flag-nav a .dropdown-menu .dropdown-item {
    padding: 5px 15px;
}

.user-menu.nav > li.flag-nav .dropdown-menu {
    max-height: 170px;
    min-width: 150px;
    overflow-y: auto;
}

.user-menu.nav > li.flag-nav .dropdown-menu .dropdown-item {
    padding: 5px 15px;
}

.user-menu.nav > li.flag-nav .dropdown-menu a img {
    width: auto;
}

.searchinputs {
    padding: 0 15px;
    position: relative;
}

.searchinputs input {
    width: 230px;
    height: 35px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    padding: 0 8px 0 40px;
    border-radius: 5px;
    position: relative;
    top: 4px;
    left: 24px;
}

.searchinputs .search-addon span {
    background: #05bbc9;
    width: 27px;
    height: 27px;
    z-index: 9;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 43px;
}

.sidebar.new-header .sidebar-menu > ul > li {
    border-left: 1px solid #30707812;
    padding-left: 10px;
}

.sidebar.new-header .sidebar-menu > ul > li:last-child {
    border-left: 0;
    padding-left: 0;
}

.sidebar.new-header .sidebar-menu > ul > li > a:hover {
    background: transparent;
}

.sidebar.new-header .sidebar-menu > ul > li > a:hover span {
    color: #05bbc9;
}

.sidebar.new-header .sidebar-menu > ul > li a {
    font-size: 15px;
    display: flex;
    gap: 5px;
    color: #535f6b;
    padding: 5px 0;
    transition: 0.3s;
    font-family: "bold";
}

.sidebar.new-header .sidebar-menu > ul > li a.subdrop > span {
    color: #05bbc9;
}

.sidebar.new-header .sidebar-menu > ul > li a svg {
    width: 18px;
    margin-right: 7px;
}

.sidebar.new-header .sidebar-menu > ul > li .menu-arrow {
    position: initial;
}

.sidebar.new-header .sidebar-menu > ul > li ul {
    position: absolute;
    width: 250px;
    height: auto;
    top: 100%;
}

.sidebar.new-header .sidebar-menu > ul > li ul li.submenu a {
    padding: 8px 15px !important;
}

.sidebar.new-header .sidebar-menu > ul > li ul li.submenu a .menu-arrow {
    float: left;
    margin-left: 15px;
}

.sidebar.new-header .sidebar-menu > ul > li ul li.submenu a i {
    margin-left: 7px;
}

.sidebar.new-header .sidebar-menu > ul > li ul li.submenu a img {
    position: relative;
    top: 3px;
    width: 18px;
    margin-right: 7px;
}

.sidebar.new-header .sidebar-menu > ul > li ul li.submenu a svg {
    width: 18px;
    margin-right: 7px;
}

.sidebar.new-header .sidebar-menu > ul > li ul li.submenu ul {
    box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
}

.sidebar.new-header .sidebar-menu > ul > li ul li.submenu ul {
    position: relative;
    padding: 0;
}

.sidebar.new-header .sidebar-menu > ul > li ul li.submenu ul li a {
    padding: 8px 40px 8px 8px !important;
}

@media (max-width: 1199px) {
    .sidebar.new-header .sidebar-menu > ul > li {
        margin-right: 0;
    }
}

@media (min-width: 992px) {
    .hide-sidebar {
        display: none;
    }
}

@media (max-width: 991px) {
    .new-header {
        display: none;
    }
}

.header-two .header-left {
    border: 0 !important;
    padding: 0 10px;
}

.header-two .top-nav-search {
    float: left;
    margin-left: 10px;
}

.header-two .top-nav-search .btn {
    left: 0;
    right: auto !important;
}

.header-two .top-nav-search .searchinputs input {
    z-index: 1;
}

.sidebar.sidebar-two {
    right: auto;
    top: 90px;
    border: 0 !important;
}

.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a {
    display: flex !important;
    padding: 8px 15px 8px 8px;
}

.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a img {
    width: 18px;
}

.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a span {
    margin-right: 10px;
}

.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a::after {
    content: none;
}

.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a .menu-arrow {
    top: 10px;
}

.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li ul li a {
    padding: 8px 40px 8px 8px;
}

.sidebar.sidebar-two .sidebar-menu > ul li a .menu-arrow::before {
    content: "\f104";
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
}

.sidebar.sidebar-two .sidebar-menu .menu-arrow {
    position: absolute;
    top: 12px;
    left: 15px;
}

.page-wrapper-two .dash-widget .dash-widgetcontent {
    margin-left: 10px;
}

@media (max-width: 991px) {
    .sidebar.sidebar-two {
        right: 0;
        top: 60px;
    }
}

.page-wrapper-three {
    margin-right: 0;
    padding-top: 0;
}

.page-wrapper-three .content {
    background: #fafbfe;
    border-radius: 20px;
    margin-top: -82px;
}

.header-three {
    position: relative;
}

.header-three .header-left {
    border-right: 0 !important;
    padding: 25px;
}

.logo-white {
    display: none !important;
}

.sidebar.side-three.new-header {
    position: relative;
    top: 0;
    width: 100%;
    height: 150px;
    background: #1b2950;
    border-top: 1px solid #d9d9d9;
    box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
    z-index: unset;
}

.sidebar.side-three.new-header .sidebar-menu {
    padding: 0 15px;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li {
    margin: 0 0 0 15px;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li > a {
    padding: 22px 15px;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li > a img,
.sidebar.side-three.new-header .sidebar-menu > ul > li > a svg {
    filter: brightness(0) invert(1);
    margin-right: 0;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li > a:hover {
    background: transparent;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li > a:hover span {
    color: #05bbc9 !important;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li > a:hover img,
.sidebar.side-three.new-header .sidebar-menu > ul > li > a:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
}

.sidebar.side-three.new-header .sidebar-menu > ul > li > a img {
    top: 0 !important;
    width: auto;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li > a::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -10px;
    width: 1px;
    height: 15px;
    background: #6b7a99;
    transform: translateY(-50%);
}

@media (max-width: 1199px) {
    .sidebar.side-three.new-header .sidebar-menu > ul > li > a {
        padding: 22px 8px;
    }
}

.sidebar.side-three.new-header .sidebar-menu > ul > li > a span {
    color: #fff !important;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li a {
    font-weight: 500;
    font-size: 15px;
    color: #fff;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li a img {
    position: relative;
    top: 3px;
    width: 18px;
    margin-right: 7px;
    filter: brightness(0) invert(1);
}

.sidebar.side-three.new-header .sidebar-menu > ul > li a svg {
    width: 18px;
    margin-left: 7px;
    margin-right: 0;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li a:hover img,
.sidebar.side-three.new-header .sidebar-menu > ul > li a:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
}

.sidebar.side-three.new-header .sidebar-menu > ul > li .menu-arrow {
    position: initial;
    transform: rotate(90deg);
    margin-left: 15px;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li .menu-arrow::before {
    content: "\f105";
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul {
    position: absolute;
    width: 250px;
    height: auto;
    z-index: 1;
    background: #1b2950;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a {
    padding: 8px 15px !important;
    color: #fff;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a .menu-arrow {
    float: left;
    margin-left: 15px;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a i {
    margin-left: 7px;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a img {
    position: relative;
    top: 3px;
    width: 18px;
    margin-left: 7px;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a svg {
    width: 18px;
    margin: 0 0 0 7px;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a:hover {
    color: #05bbc9;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a:hover img {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu ul {
    box-shadow: none;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu ul {
    position: relative;
    padding: 0;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu ul li a {
    padding: 8px 40px 8px 10px !important;
    color: #fff;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul li a {
    padding: 8px 15px !important;
}

.sidebar.side-three.new-header .sidebar-menu > ul > li ul li a::after {
    content: none !important;
}

@media (max-width: 1199px) {
    .sidebar.side-three.new-header .sidebar-menu > ul > li {
        margin-right: 0;
    }
}

.sidebar.side-three.new-header .sidebar-menu > ul > li:first-child > a::before {
    content: none;
}

@media (max-width: 1199px) {
    .sidebar.side-three.new-header .sidebar-menu > ul > li {
        margin: 0 0 0 6px;
    }
}

@media (min-width: 992px) {
    .hide-sidebar {
        display: none;
    }
}

@media (max-width: 991px) {
    .header-three {
        position: fixed;
    }

    .new-header {
        display: none;
    }

    .page-wrapper-three {
        padding-top: 60px;
    }

    .page-wrapper-three .content {
        margin-top: 0;
    }
}

.sidebar.sidebar-four {
    border: 0;
    box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
}

.sidebar.sidebar-four .sidebar-menu {
    border-radius: 0 20px 20px 0;
}

.sidebar.sidebar-four .sidebar-menu .ui-aside {
    float: left;
    width: 100%;
    max-width: 66px;
    margin-left: 0px;
    color: rgba(255, 255, 255, 0.5);
    -webkit-transition: all 0.299s;
    -ms-transition: all 0.299s;
    transition: all 0.299s;
    margin: 0;
    padding: 0;
    box-shadow: 0px 0 14px rgba(198, 198, 198, 0.25);
    min-height: 100vh;
}

.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs {
    padding: 0 19px;
    border: 0;
}

.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link {
    padding: 20px 5px;
    border-bottom: 1px solid #d9d9d9;
    border-width: 0 0 1px;
}

.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link:hover {
    border-width: 0 0 1px;
    border-bottom: 1px solid #d9d9d9;
}

.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link:active,
.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link.active {
    border-color: #fff #fff #d9d9d9;
}

.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link:active img,
.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link.active img {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
}

.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link:active::after,
.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link.active::after {
    content: none;
}

.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link::before {
    content: none;
}

.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item:last-child .nav-link {
    border-bottom: 0;
}

.sidebar.sidebar-four .tab-content-four {
    padding-top: 20px;
    margin-right: 10px;
    width: 100%;
}

.sidebar.sidebar-four ul ul {
    display: none;
}

.sidebar.sidebar-four .sidebar-menu {
    padding: 0 0 0 10px;
}

.sidebar.sidebar-four .sidebar-menu ul li a {
    padding: 10px 25px 10px 15px;
    position: relative;
    color: #637381;
}

.sidebar.sidebar-four .sidebar-menu ul li a svg {
    width: 18px;
    color: #637381;
}

.sidebar.sidebar-four .sidebar-menu ul li a img {
    width: 18px;
    color: #637381;
}

.sidebar.sidebar-four .sidebar-menu ul li a:hover {
    color: #05bbc9;
}

.sidebar.sidebar-four .sidebar-menu ul li a:hover img {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
}

.sidebar.sidebar-four .sidebar-menu ul li a:hover span {
    color: #05bbc9;
}

.sidebar.sidebar-four .sidebar-menu ul li a:hover svg {
    color: #fff;
}

.sidebar.sidebar-four .sidebar-menu ul li a::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: #c8c8c8;
    width: 5px;
    height: 5px;
}

.sidebar.sidebar-four .sidebar-menu ul li a.active {
    color: #05bbc9;
}

.sidebar.sidebar-four .sidebar-menu ul li a.active svg {
    color: #fff;
}

.sidebar.sidebar-four .sidebar-menu ul li a.active img {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
}

.sidebar.sidebar-four .sidebar-menu ul li a.active span {
    color: #fff;
}

.sidebar.sidebar-four .sidebar-menu ul li a span {
    margin-left: 0;
    font-size: 15px;
    font-weight: 500;
    color: #637381;
}

.sidebar.sidebar-four .sidebar-menu ul li a .menu-arrow {
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    text-rendering: auto;
    line-height: 40px;
    font-size: 18px;
    line-height: 18px;
    position: absolute;
    top: 12px;
    left: 15px;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.sidebar.sidebar-four .sidebar-menu ul li a .menu-arrow::before {
    content: "\f104";
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
}

.sidebar.sidebar-four .sidebar-menu ul li a.subdrop .menu-arrow {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
}

.sidebar.sidebar-four .sidebar-menu ul li ul li a {
    padding: 10px 25px 10px 15px;
    right: 14px;
}

.header-four .header-left {
    border-right: 0 !important;
}

.page-wrapper-four {
    margin-right: 250px;
}

@media (max-width: 992px) {
    .page-wrapper-four {
        margin-right: 0;
    }
}

div#global-loader {
    background: #fff;
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100vh;
}

div#global-loader .whirly-loader:not(:required) {
    animation: whirly-loader 1.25s linear infinite;
    background: transparent;
    box-shadow: 0 26px 0 6px #05bbc9, 0.90971px 26.05079px 0 5.93333px #05bbc9,
    1.82297px 26.06967px 0 5.86667px #05bbc9,
    2.73865px 26.05647px 0 5.8px #05bbc9,
    3.65561px 26.01104px 0 5.73333px #05bbc9,
    4.57274px 25.93327px 0 5.66667px #05bbc9,
    5.48887px 25.8231px 0 5.6px #05bbc9,
    6.40287px 25.68049px 0 5.53333px #05bbc9,
    7.31358px 25.50548px 0 5.46667px #05bbc9,
    8.21985px 25.2981px 0 5.4px #05bbc9,
    9.12054px 25.05847px 0 5.33333px #05bbc9,
    10.01448px 24.78672px 0 5.26667px #05bbc9,
    10.90054px 24.48302px 0 5.2px #05bbc9,
    11.77757px 24.1476px 0 5.13333px #05bbc9,
    12.64443px 23.78072px 0 5.06667px #05bbc9, 13.5px 23.38269px 0 5px #05bbc9,
    14.34315px 22.95384px 0 4.93333px #05bbc9,
    15.17277px 22.49455px 0 4.86667px #05bbc9,
    15.98776px 22.00526px 0 4.8px #05bbc9,
    16.78704px 21.48643px 0 4.73333px #05bbc9,
    17.56953px 20.93855px 0 4.66667px #05bbc9,
    18.33418px 20.36217px 0 4.6px #05bbc9,
    19.07995px 19.75787px 0 4.53333px #05bbc9,
    19.80582px 19.12626px 0 4.46667px #05bbc9,
    20.5108px 18.468px 0 4.4px #05bbc9, 21.1939px 17.78379px 0 4.33333px #05bbc9,
    21.85416px 17.07434px 0 4.26667px #05bbc9,
    22.49067px 16.34043px 0 4.2px #05bbc9,
    23.10251px 15.58284px 0 4.13333px #05bbc9,
    23.68881px 14.80241px 0 4.06667px #05bbc9, 24.24871px 14px 0 4px #05bbc9,
    24.7814px 13.1765px 0 3.93333px #05bbc9,
    25.28607px 12.33284px 0 3.86667px #05bbc9,
    25.76198px 11.46997px 0 3.8px #05bbc9,
    26.2084px 10.58888px 0 3.73333px #05bbc9,
    26.62462px 9.69057px 0 3.66667px #05bbc9,
    27.01001px 8.77608px 0 3.6px #05bbc9,
    27.36392px 7.84648px 0 3.53333px #05bbc9,
    27.68577px 6.90284px 0 3.46667px #05bbc9,
    27.97502px 5.94627px 0 3.4px #05bbc9,
    28.23116px 4.97791px 0 3.33333px #05bbc9,
    28.4537px 3.99891px 0 3.26667px #05bbc9,
    28.64223px 3.01042px 0 3.2px #05bbc9,
    28.79635px 2.01364px 0 3.13333px #05bbc9,
    28.91571px 1.00976px 0 3.06667px #05bbc9, 29px 0 0 3px #05bbc9,
    29.04896px -1.01441px 0 2.93333px #05bbc9,
    29.06237px -2.03224px 0 2.86667px #05bbc9,
    29.04004px -3.05223px 0 2.8px #05bbc9,
    28.98185px -4.07313px 0 2.73333px #05bbc9,
    28.88769px -5.09368px 0 2.66667px #05bbc9,
    28.75754px -6.1126px 0 2.6px #05bbc9,
    28.59138px -7.12863px 0 2.53333px #05bbc9,
    28.38926px -8.14049px 0 2.46667px #05bbc9,
    28.15127px -9.1469px 0 2.4px #05bbc9,
    27.87755px -10.1466px 0 2.33333px #05bbc9,
    27.56827px -11.1383px 0 2.26667px #05bbc9,
    27.22365px -12.12075px 0 2.2px #05bbc9,
    26.84398px -13.09268px 0 2.13333px #05bbc9,
    26.42956px -14.05285px 0 2.06667px #05bbc9, 25.98076px -15px 0 2px #05bbc9,
    25.49798px -15.93291px 0 1.93333px #05bbc9,
    24.98167px -16.85035px 0 1.86667px #05bbc9,
    24.43231px -17.75111px 0 1.8px #05bbc9,
    23.85046px -18.63402px 0 1.73333px #05bbc9,
    23.23668px -19.49789px 0 1.66667px #05bbc9,
    22.5916px -20.34157px 0 1.6px #05bbc9,
    21.91589px -21.16393px 0 1.53333px #05bbc9,
    21.21024px -21.96384px 0 1.46667px #05bbc9,
    20.4754px -22.74023px 0 1.4px #05bbc9,
    19.71215px -23.49203px 0 1.33333px #05bbc9,
    18.92133px -24.2182px 0 1.26667px #05bbc9,
    18.10379px -24.91772px 0 1.2px #05bbc9,
    17.26042px -25.58963px 0 1.13333px #05bbc9,
    16.39217px -26.23295px 0 1.06667px #05bbc9, 15.5px -26.84679px 0 1px #05bbc9,
    14.58492px -27.43024px 0 0.93333px #05bbc9,
    13.64796px -27.98245px 0 0.86667px #05bbc9,
    12.69018px -28.50262px 0 0.8px #05bbc9,
    11.7127px -28.98995px 0 0.73333px #05bbc9,
    10.71663px -29.4437px 0 0.66667px #05bbc9,
    9.70313px -29.86317px 0 0.6px #05bbc9,
    8.67339px -30.2477px 0 0.53333px #05bbc9,
    7.6286px -30.59666px 0 0.46667px #05bbc9,
    6.57001px -30.90946px 0 0.4px #05bbc9,
    5.49886px -31.18558px 0 0.33333px #05bbc9,
    4.41643px -31.42451px 0 0.26667px #05bbc9,
    3.32401px -31.6258px 0 0.2px #05bbc9,
    2.22291px -31.78904px 0 0.13333px #05bbc9,
    1.11446px -31.91388px 0 0.06667px #05bbc9, 0 -32px 0 0 #05bbc9,
    -1.11911px -32.04713px 0 -0.06667px #05bbc9,
    -2.24151px -32.05506px 0 -0.13333px #05bbc9,
    -3.36582px -32.02361px 0 -0.2px #05bbc9,
    -4.49065px -31.95265px 0 -0.26667px #05bbc9,
    -5.61462px -31.84212px 0 -0.33333px #05bbc9,
    -6.73634px -31.69198px 0 -0.4px #05bbc9,
    -7.8544px -31.50227px 0 -0.46667px #05bbc9,
    -8.9674px -31.27305px 0 -0.53333px #05bbc9,
    -10.07395px -31.00444px 0 -0.6px #05bbc9,
    -11.17266px -30.69663px 0 -0.66667px #05bbc9,
    -12.26212px -30.34982px 0 -0.73333px #05bbc9,
    -13.34096px -29.96429px 0 -0.8px #05bbc9,
    -14.4078px -29.54036px 0 -0.86667px #05bbc9,
    -15.46126px -29.07841px 0 -0.93333px #05bbc9,
    -16.5px -28.57884px 0 -1px #05bbc9,
    -17.52266px -28.04212px 0 -1.06667px #05bbc9,
    -18.52792px -27.46878px 0 -1.13333px #05bbc9,
    -19.51447px -26.85936px 0 -1.2px #05bbc9,
    -20.48101px -26.21449px 0 -1.26667px #05bbc9,
    -21.42625px -25.53481px 0 -1.33333px #05bbc9,
    -22.34896px -24.82104px 0 -1.4px #05bbc9,
    -23.2479px -24.07391px 0 -1.46667px #05bbc9,
    -24.12186px -23.29421px 0 -1.53333px #05bbc9,
    -24.96967px -22.48279px 0 -1.6px #05bbc9,
    -25.79016px -21.64052px 0 -1.66667px #05bbc9,
    -26.58223px -20.76831px 0 -1.73333px #05bbc9,
    -27.34477px -19.86714px 0 -1.8px #05bbc9,
    -28.07674px -18.938px 0 -1.86667px #05bbc9,
    -28.7771px -17.98193px 0 -1.93333px #05bbc9,
    -29.44486px -17px 0 -2px #05bbc9,
    -30.07908px -15.99333px 0 -2.06667px #05bbc9,
    -30.67884px -14.96307px 0 -2.13333px #05bbc9,
    -31.24325px -13.91039px 0 -2.2px #05bbc9,
    -31.7715px -12.83652px 0 -2.26667px #05bbc9,
    -32.26278px -11.74269px 0 -2.33333px #05bbc9,
    -32.71634px -10.63018px 0 -2.4px #05bbc9,
    -33.13149px -9.5003px 0 -2.46667px #05bbc9,
    -33.50755px -8.35437px 0 -2.53333px #05bbc9,
    -33.84391px -7.19374px 0 -2.6px #05bbc9,
    -34.14px -6.0198px 0 -2.66667px #05bbc9,
    -34.39531px -4.83395px 0 -2.73333px #05bbc9,
    -34.60936px -3.63759px 0 -2.8px #05bbc9,
    -34.78173px -2.43218px 0 -2.86667px #05bbc9,
    -34.91205px -1.21916px 0 -2.93333px #05bbc9, -35px 0 0 -3px #05bbc9,
    -35.04531px 1.22381px 0 -3.06667px #05bbc9,
    -35.04775px 2.45078px 0 -3.13333px #05bbc9,
    -35.00717px 3.6794px 0 -3.2px #05bbc9,
    -34.92345px 4.90817px 0 -3.26667px #05bbc9,
    -34.79654px 6.13557px 0 -3.33333px #05bbc9,
    -34.62643px 7.36007px 0 -3.4px #05bbc9,
    -34.41316px 8.58016px 0 -3.46667px #05bbc9,
    -34.15683px 9.79431px 0 -3.53333px #05bbc9,
    -33.85761px 11.001px 0 -3.6px #05bbc9,
    -33.5157px 12.19872px 0 -3.66667px #05bbc9,
    -33.13137px 13.38594px 0 -3.73333px #05bbc9,
    -32.70493px 14.56117px 0 -3.8px #05bbc9,
    -32.23675px 15.72291px 0 -3.86667px #05bbc9,
    -31.72725px 16.86968px 0 -3.93333px #05bbc9, -31.17691px 18px 0 -4px #05bbc9,
    -30.58627px 19.11242px 0 -4.06667px #05bbc9,
    -29.95589px 20.2055px 0 -4.13333px #05bbc9,
    -29.28642px 21.27783px 0 -4.2px #05bbc9,
    -28.57852px 22.32799px 0 -4.26667px #05bbc9,
    -27.83295px 23.35462px 0 -4.33333px #05bbc9,
    -27.05047px 24.35635px 0 -4.4px #05bbc9,
    -26.23192px 25.33188px 0 -4.46667px #05bbc9,
    -25.37819px 26.27988px 0 -4.53333px #05bbc9,
    -24.49018px 27.1991px 0 -4.6px #05bbc9,
    -23.56888px 28.0883px 0 -4.66667px #05bbc9,
    -22.6153px 28.94626px 0 -4.73333px #05bbc9,
    -21.6305px 29.77183px 0 -4.8px #05bbc9,
    -20.61558px 30.56385px 0 -4.86667px #05bbc9,
    -19.57168px 31.32124px 0 -4.93333px #05bbc9,
    -18.5px 32.04294px 0 -5px #05bbc9,
    -17.40175px 32.72792px 0 -5.06667px #05bbc9,
    -16.27818px 33.37522px 0 -5.13333px #05bbc9,
    -15.1306px 33.98389px 0 -5.2px #05bbc9,
    -13.96034px 34.55305px 0 -5.26667px #05bbc9,
    -12.76875px 35.08186px 0 -5.33333px #05bbc9,
    -11.55724px 35.56951px 0 -5.4px #05bbc9,
    -10.32721px 36.01527px 0 -5.46667px #05bbc9,
    -9.08014px 36.41843px 0 -5.53333px #05bbc9,
    -7.81748px 36.77835px 0 -5.6px #05bbc9,
    -6.54075px 37.09443px 0 -5.66667px #05bbc9,
    -5.25147px 37.36612px 0 -5.73333px #05bbc9,
    -3.95118px 37.59293px 0 -5.8px #05bbc9,
    -2.64145px 37.77443px 0 -5.86667px #05bbc9,
    -1.32385px 37.91023px 0 -5.93333px #05bbc9;
    display: inline-block;
    height: 8px;
    overflow: hidden;
    position: relative;
    text-indent: -9999px;
    width: 8px;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    border-radius: 100%;
}

@keyframes loader {
    to {
        left: -100vw;
    }
}

@-webkit-keyframes whirly-loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

@keyframes whirly-loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

.select2-container {
    min-width: 100% !important;
    width: auto !important;
    z-index: 99;
}

.select2-container .select2-selection--single {
    height: 40px;
}

.select2-container--default .select2-selection--single {
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 5px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #637381;
    line-height: 40px;
    border-radius: 5px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
    right: 6px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    position: absolute;
    top: 50%;
    left: 50%;
    border-color: #637381;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    height: 0;
    margin-left: -10px;
    margin-top: -3px;
    width: 0;
    transform: rotate(45deg) translateY(-50%);
    -webkit-transform: rotate(45deg) translateY(-50%);
    -ms-transform: rotate(45deg) translateY(-50%);
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #000;
    border-width: 2px 0 0 2px;
    margin-top: 3px;
    padding: 3px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #05bbc9;
    color: #fff;
}

.select2-container--focus .select2-selection--single {
    background: #fff !important;
    border-color: #05bbc9;
}

span.select2-container.select2-container--default.select2-container--open {
    z-index: 9999;
}

.sidebar .slimScrollDiv {
    width: 260px !important;
    overflow: visible !important;
    background: #fff;
}

@media (max-width: 575px) {
    .sidebar .slimScrollDiv {
        width: 100% !important;
    }
}

.sidebar .sidebar-menu > ul > li.active a {
    color: #fff;
}

.sidebar .sidebar-menu > ul > li > a {
    position: relative;
    color: #212b36;
}

.sidebar .sidebar-menu > ul > li > a svg {
    width: 18px;
    color: #637381;
}

.sidebar .sidebar-menu > ul > li > a.active {
    background: #1b2850;
    color: #fff;
    border-radius: 5px;
}

.sidebar .sidebar-menu > ul > li > a.active span {
    color: #fff;
}

.sidebar .sidebar-menu > ul > li > a.active svg {
    color: #fff;
}

.sidebar .sidebar-menu > ul > li > a span {
    font-size: 17px;
    color: #212b36;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: 0.5s;
}

.sidebar .sidebar-menu > ul > li > a span i {
    font-size: 20px;
    display: flex;
}

.sidebar .sidebar-menu > ul > li > a span img {
    width: 24px;
}

.sidebar .sidebar-menu > ul > li > a.subdrop span img {
    animation: toBottomFromTop 0.6s forwards;
}

@keyframes toBottomFromTop {
    49% {
        transform: translateY(100%);
    }

    50% {
        opacity: 0;
        transform: translateY(-100%);
    }

    51% {
        opacity: 1;
    }
}

.sidebar .sidebar-menu > ul > li > a .menu-arrow {
    display: inline-block;
    text-rendering: auto;
    line-height: 40px;
    font-size: 11px;
    line-height: 18px;
    position: absolute;
    top: 12px;
    left: 15px;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.sidebar .sidebar-menu > ul > li > a .menu-arrow::before {
    content: "\F282";
    font-family: bootstrap-icons !important;
}

.sidebar .sidebar-menu > ul > li.active a {
    background: #1b2850;
    border-radius: 5px;
}

.sidebar .sidebar-menu > ul > li.active a img {
    filter: brightness(0) invert(1);
}

.sidebar .sidebar-menu > ul > li.active a span {
    color: #fff;
}

.sidebar .sidebar-menu > ul > li.submenu ul {
    background: #ffffff;
    border-radius: 0 0 7px 7px;
    padding: 5px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    display: block;
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 10;
}

.sidebar .sidebar-menu > ul > li.submenu ul.active {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.sidebar .sidebar-menu > ul > li.submenu ul li a {
    font-size: 16px;
    padding: 8px 15px;
    padding-right: 30px;
    position: relative;
}

.sidebar .sidebar-menu > ul > li.submenu ul li a.active {
    color: #05bbc9;
}

.sidebar .sidebar-menu > ul > li.submenu ul li a.active:after {
    background: #05bbc9;
    border: 1px solid #05bbc9;
}

.sidebar .sidebar-menu > ul > li.submenu ul li a::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50px;
    position: absolute;
    top: 16px;
    right: 12px;
    background: #cacfd5;
}

.sidebar .sidebar-menu > ul > li.submenu ul li a::before {
    content: '';
    background: #e9e9e9;
    height: 100%;
    width: 2px;
    position: absolute;
    right: 15px;
    top: 0;
}

.sidebar .sidebar-menu > ul > li.submenu ul li a:hover {
    color: #05bbc9;
}

.sidebar .sidebar-menu > ul > li.submenu ul li a:hover:after {
    background: #05bbc9;
    border: 1px solid #05bbc9;
}

.sidebar .nav-link {
    padding: 10px;
    color: #212b36;
    position: relative;
}

.sidebar .nav-link.active {
    color: #05bbc9;
    background: transparent;
    position: relative;
}

.sidebar .nav-link.active:after {
    content: "";
    border-width: 10px;
    border-color: transparent #05bbc9 transparent transparent;
    border-style: solid;
    position: absolute;
    top: 36px;
    right: 0;
}

.sidebar .sidebar-left {
    width: 100px;
    -webkit-box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
    box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
}

.sidebar .sidebar-right {
    width: 250px;
    height: 100%;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: none;
}

@media (max-width: 991px) {
    .sidebar .sidebar-right {
        width: calc(100% - 70px);
    }
}

.sidebar .sidebar-right .slimScrollDiv {
    width: 250px !important;
    overflow: visible !important;
}

@media (max-width: 991px) {
    .sidebar .sidebar-right .slimScrollDiv {
        width: 100% !important;
    }
}

.sidebar .sidebar-right .tab-content {
    padding: 20px 20px 40px;
}

@media (max-width: 991px) {
    .sidebar .sidebar-right .tab-content {
        padding: 10px;
    }
}

.sidebar .sidebar-right p {
    font-size: 10px;
    color: #9e9e9e;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
}

.sidebar .sidebar-right ul li.active a {
    background: linear-gradient(46.62deg, #05bbc9 0%, #423b93 93.64%);
    color: #fff;
}

.sidebar .sidebar-right ul li a {
    font-weight: 400;
    font-size: 14px;
    color: #212b36;
}

.sidebar .sidebar-right ul li a.active {
    color: #05bbc9;
}

.sidebar .sidebar-right .menu-arrow {
    -webkit-transition: -webkit-transform 0.15s;
    -o-transition: -o-transform 0.15s;
    transition: transform 0.15s;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    text-rendering: auto;
    line-height: 40px;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 18px;
    top: 0px;
    position: relative;
    font-weight: 700;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
}

.sidebar .sidebar-right .menu-arrow:before {
    content: "\f105";
}

.sidebar li a.subdrop .menu-arrow {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
}

.sidebar .nav-items .nav-link {
    border-radius: 0;
    border-bottom: 1px solid #d8d8d8;
    padding: 20px 0;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.sidebar .nav-items .nav-link:hover {
    box-shadow: 0 0 0 150px #423b93 inset;
    color: #fff;
}

.sidebar .nav-items .nav-link span {
    margin-top: 15px;
    font-weight: 500;
}

.sidebar .sidemenu a {
    width: 100%;
    font-weight: 500;
    display: block;
    border-radius: 5px;
    margin: 0 0 10px;
    padding: 10px;
}

.sidebar .sidemenu a:hover {
    background: linear-gradient(46.62deg, #05bbc9 0%, #423b93 93.64%);
    color: #fff;
}

.sidebar .sidemenu a:hover svg {
    color: #fff;
}

.sidebar .sidemenu a svg {
    stroke-width: 1px;
}

.header-left.active #toggle_btn {
    position: relative;
    opacity: 1;
}

.header-left.active #toggle_btn:after {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #fff;
    border: 2px solid #092c4c;
    border-radius: 20px;
    position: absolute;
    top: 6px;
    left: 6px;
}

@media (min-width: 991px) {
    .mini-sidebar .header-left #toggle_btn {
        opacity: 0;
    }

    .mini-sidebar .header-left #toggle_btn:after {
        border: 0;
    }

    .mini-sidebar.expand-menu .header-left #toggle_btn {
        opacity: 1;
    }

    .mini-sidebar .sidebar-right {
        display: none;
    }

    .mini-sidebar .sidebar .sidebar-menu ul li a {
        padding: 10px;
    }

    .mini-sidebar .sidebar .sidebar-menu ul li a span {
        display: none;
    }

    .mini-sidebar .sidebar {
        width: 80px;
    }

    .expand-menu .sidebar-right {
        display: block;
        -webkit-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .expand-menu .sidebar {
        width: 260px;
    }

    .expand-menu .sidebar .sidebar-menu ul > li > a {
        padding: 10px 15px;
    }

    .expand-menu .sidebar .sidebar-menu ul > li > a span {
        display: block;
    }

    .expand-menu .header-left {
        width: 260px;
    }

    #toggle_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        margin-right: 15px;
    }

    #toggle_btn:before {
        content: "";
        width: 18px;
        height: 18px;
        border: 2px solid #092c4c;
        border-radius: 20px;
    }
}

.sidebarrightmenu .sidebar-right {
    display: block;
}

.slide-nav .sidebar {
    margin-right: 0;
}

.sidebar-overlay {
    display: none;
    height: 100%;
    width: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
}

.sidebar-overlay.active {
    display: block;
}

.nosidebar .sidebar {
    left: -350px;
}

.nosidebar .page-wrapper {
    margin: 0;
}

.nosidebar #toggle_btn img {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}

.mini-sidebar .active.subdrop ~ ul {
    display: none !important;
}

.mini-sidebar.expand-menu .active.subdrop ~ ul {
    display: block !important;
}

.active.subdrop ~ ul {
    display: block !important;
}

.tabs-set .nav-tabs {
    border-bottom: 0;
    margin-bottom: 20px;
}

.tabs-set .nav-tabs .nav-link {
    color: #212b36;
    padding: 8px 15px;
    font-size: 16px;
    font-weight: 700;
    border: 0;
    min-width: 120px;
    margin-right: 10px;
    background: #f8f8f8;
    border-radius: 5px !important;
}

.tabs-set .nav-tabs .nav-link:focus {
    border-color: transparent;
}

.tabs-set .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.tabs-set .nav-tabs .nav-link.active {
    border: 0;
    border-bottom: 2px solid #05bbc9;
    background: #05bbc9;
    color: #fff;
}

.tabs-sets .nav-tabs {
    border-bottom: 0;
    margin: 0 0 30px;
}

.tabs-sets .nav-tabs .nav-link {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    border: 0;
    background: #e9ecef;
    min-width: 112px;
    margin-right: 15px;
    padding: 15px;
    border-radius: 5px;
}

.tabs-sets .nav-tabs .nav-link:focus {
    border-color: transparent;
}

.tabs-sets .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.tabs-sets .nav-tabs .nav-link.active {
    background: #05bbc9;
    color: #fff;
}

.topnav-dropdown-header {
    border-bottom: 1px solid #08080812;
    text-align: center;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    padding: 0 12px;
}

.topnav-dropdown-header .notification-title {
    color: #333;
    display: block;
    float: left;
    font-size: 14px;
}

.topnav-dropdown-header .clear-noti {
    color: #ea5455;
    float: right;
    font-size: 11px;
    text-transform: uppercase;
}

.notifications .noti-content {
    height: 290px;
    width: 350px;
    overflow-y: auto;
    position: relative;
}

.notifications .notification-list li {
    margin-top: 0;
    border-bottom: 1px solid #f5f5f5;
    font-size: 14px;
}

.notifications .notification-list li a {
    display: block;
    padding: 12px;
    border-radius: 2px;
}

.notifications .notification-list li .noti-title {
    color: #333;
    font-weight: 500;
}

.notifications .notification-list li .noti-details {
    color: #989c9e;
    margin: 0;
}

.notifications .notification-list li p.noti-time {
    margin: 0;
}

.notifications .notification-list li .notification-time {
    font-size: 12px;
    line-height: 1.35;
    color: #637381;
}

.notifications .notification-list li .list-item {
    border: 0;
    padding: 0;
    position: relative;
}

.notifications .notification-list li .list-item .list-left {
    height: 48px;
    position: absolute;
    width: 48px;
}

.notifications .notification-list li .list-item .list-body {
    padding: 0 0 0 50px;
}

.notifications .notification-list li .list-item .list-body .message-author {
    color: #333;
    float: left;
    font-weight: 500;
    width: 175px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notifications .notification-list li .list-item .list-body .message-time {
    color: #888888;
    float: right;
    font-size: 11px;
}

.notifications .notification-list li .list-item .list-body .message-content {
    color: #333;
    font-size: 13px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.topnav-dropdown-footer {
    border-top: 1px solid #eeeeee;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    padding: 0 12px;
}

.topnav-dropdown-footer a {
    display: block;
    text-align: center;
    color: #333;
}

.list-left {
    height: 48px;
    position: absolute;
    width: 48px;
}

.list-body {
    padding: 0 0 0 50px;
}

.list-body .message-author {
    color: #333;
    float: left;
    font-weight: 500;
    width: 175px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.list-body .message-time {
    color: #888888;
    float: right;
    font-size: 11px;
}

.list-body .message-content {
    color: #333;
    font-size: 13px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.activity {
    width: 100%;
}

.activity .activity-box {
    position: relative;
}

.activity .activity-list {
    position: relative;
}

.activity .activity-list li {
    background-color: #fff;
    position: relative;
    border: 1px solid #e7e7e7;
    margin: 0 0 10px;
    padding: 20px 10px 10px;
    font-size: 15px;
}

.activity .activity-list li .activity-user {
    height: 50px;
    margin: 0;
    padding: 0;
    width: 50px;
    position: absolute;
    top: 12px;
    left: 8px;
}

.activity .activity-list li .activity-content {
    background-color: #fff;
    position: relative;
    margin: 0 0 0 60px;
    padding: 0;
}

.activity .activity-list li .time {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 1.35;
}

.activity .activity-list li a.name {
    color: #000;
}

.activity .activity-list li a {
    color: #212b36;
    font-weight: 600;
    font-size: 15px;
}

.activity .activity-list li a img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.table {
    margin: 0;
}

.table thead {
    background: #fafbfe;
    border-bottom: 1px solid #e9ecef;
}

.table thead th {
    color: #212b36;
    padding: 5px;
    white-space: nowrap;
    font-family: "bold";
}

.table tbody {
    border: 0 !important;
}

.table tbody tr td {
    padding: 7px;
    color: #637381;
    border-bottom: 1px solid #e9ecef;
    vertical-align: middle;
}

.table tbody tr td a {
    color: #212b36;
}

.table tbody tr td a:hover {
    color: #05bbc9;
}

.table tbody tr.active {
    border-bottom: 0;
}

.table tbody tr.active td {
    font-size: 18px;
    color: #5e5873;
    font-weight: 600;
    border-bottom: 0;
}

.table-height {
    min-height: 300px;
}

@media (max-width: 991px) {
    .table-height {
        min-height: auto;
        margin-bottom: 15px;
    }
}

.max-widthauto {
    max-width: 100% !important;
}

.dataTables_filter + .dataTables_filter {
    display: none;
}

.dataview .dataTables_length,
.dataview .dataTables_info,
.dataview .dataTables_paginate {
    display: none !important;
}

.dataTables_length label:before {
    content: "Show  per page :";
    margin-left: 5px;
}

.dataTables_length {
    float: left;
    font-size: 12px;
}

.productimgname {
    gap: 10px;
    justify-content: start;
    width: 200px;
    max-width: 300px;
    text-align: right;
}

.productimgname p {
    font-family: "bold";
    font-size: 15px;
}

.productimgname img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.form-group .calc-no {
    width: 50px !important;
    text-align: center;
}

.table .form-group input[type="text"] {
    height: 40px;
}

.table .scanner-set {
    width: 40px;
    height: 40px;
}

.modal-barcode ul li {
    border-radius: 7px;
    width: 25%;
    margin-bottom: 10px;
    padding: 0 5px;
}

@media (max-width: 991px) {
    .modal-barcode ul li {
        width: 50%;
    }
}

.modal-barcode ul li a {
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    display: block;
    padding: 10px;
    text-align: center;
}

.modal .modal-title {
    font-weight: 700;
}

@media (max-width: 991px) {
    .modal .modal-title {
        font-size: 16px;
    }
}

.modal .close,
.modal .btn-close {
    color: #ea5455;
    opacity: 1;
    font-size: 20px;
    width: 22px;
    height: 22px;
    border: 0;
    font-weight: 700;
    background: transparent;
    border-radius: 50px;
}

.modal .close:hover,
.modal .btn-close:hover {
    background: #ea5455;
    color: #fff;
}

.modal .modal-footer {
    border: 0;
    justify-content: flex-start;
}

.modal .modal-footer .btn {
    min-width: 130px;
    padding: 12px;
}

@media (max-width: 991px) {
    .modal .modal-footer .btn {
        min-width: auto;
        padding: 7px 12px;
    }
}

.modal .modal-footer .btn-print {
    border: 1px solid #05bbc9;
    color: #05bbc9;
}

.modal .modal-footer .btn-print img {
    margin-left: 10px;
}

.modal .modal-footer .btn-print:hover {
    background: #05bbc9;
    color: #fff;
}

.modal .modal-footer .btn-print:hover img {
    filter: brightness(0) invert(1);
}

.modal .modal-footer .btn-secondary {
    background: #212b36;
}

.modal .modal-footer .btn-secondary:hover {
    -webkit-box-shadow: inset 0 0px 0px 100px #fff;
    -moz-box-shadow: inset 0 0px 0px 100px #fff;
    box-shadow: inset 0 0px 0px 100px #fff;
    color: #212b36;
}

.account-page .customizer-links {
    box-shadow: 0px 4px 18px rgba(77, 76, 76, 0.25);
}

.customizer-links {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    background: #fe9f43;
    border-radius: 0 5px 5px 0;
    z-index: 9;
    width: 47px;
    min-height: 170px;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.customizer-links ul {
    width: 100%;
}

.customizer-links ul li {
    margin: 10px 5px 0;
    padding: 0 0 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.customizer-links ul li:last-child {
    border-bottom: 0;
}

.customizer-links ul li a {
    padding: 10px 10px;
    text-align: center;
    border-radius: 5px;
}

.customizer-links ul li a:hover {
    background: #1b2850;
}

.customizer-links ul li a img {
    height: 18px;
}

.sidebar-settings {
    width: 450px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -450px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    -webkit-transition: ease all 0.8s;
    -ms-transition: ease all 0.8s;
    transition: ease all 0.8s;
}

.sidebar-settings.show-settings {
    left: 0;
    -webkit-transition: ease all 0.8s;
    -ms-transition: ease all 0.8s;
    transition: ease all 0.8s;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    width: 100%;
}

.sidebar-content {
    max-width: 450px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    height: 100vh;
    overflow-x: hidden;
}

.sidebar-content .sidebar-header {
    padding: 20px;
    border: 1px solid #d9d9d9;
}

.sidebar-content .sidebar-header h5 {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: uppercase;
}

@media (max-width: 991px) {
    .sidebar-content .sidebar-header h5 {
        font-size: 18px;
    }
}

.sidebar-content .sidebar-header a {
    font-size: 24px;
}

.sidebar-content .sidebar-header a img {
    width: 15px;
}

.sidebar-content .sidebar-body {
    padding: 20px;
}

.sidebar-content .sidebar-body .theme-title {
    font-size: 18px;
    font-weight: 700;
    color: #6b7a99;
    margin-bottom: 10px;
}

.switch-wrapper {
    border-bottom: 1px solid #d9d9d9;
    margin: 0 0 20px;
    padding: 0 0 20px;
}

.switch-wrapper #dark-mode-toggle .light-mode {
    border-radius: 0 5px 5px 0;
}

.switch-wrapper #dark-mode-toggle .dark-mode {
    border-radius: 5px 0 0 5px;
}

.switch-wrapper #dark-mode-toggle .light-mode,
.switch-wrapper #dark-mode-toggle .dark-mode {
    background: #d9d9d9;
    font-size: 16px;
    color: #000;
    padding: 11px 18px;
    cursor: pointer;
}

.switch-wrapper #dark-mode-toggle .light-mode i,
.switch-wrapper #dark-mode-toggle .light-mode img,
.switch-wrapper #dark-mode-toggle .dark-mode i,
.switch-wrapper #dark-mode-toggle .dark-mode img {
    margin-left: 8px;
}

.switch-wrapper #dark-mode-toggle .light-mode img,
.switch-wrapper #dark-mode-toggle .dark-mode img {
    filter: brightness(0) saturate(100%);
}

.switch-wrapper #dark-mode-toggle .light-mode.active,
.switch-wrapper #dark-mode-toggle .dark-mode.active {
    background: #fe9f43;
    color: #fff;
}

.switch-wrapper #dark-mode-toggle .light-mode.active img,
.switch-wrapper #dark-mode-toggle .dark-mode.active img {
    filter: brightness(0) invert(1);
}

.layout-wrap {
    margin-bottom: 20px;
}

.layout-wrap .status-toggle {
    margin-left: 8px;
}

.layout-wrap .status-toggle .checktoggle {
    background: #000;
    width: 25px;
    height: 15px;
}

.layout-wrap .status-toggle .checktoggle :after {
    width: 12px;
    height: 12px;
    right: 4px;
}

.layout-wrap .layout-img img {
    max-width: 120px;
}

.layout-wrap .status-text {
    font-size: 16px;
    color: #000;
}

.layout-link {
    position: relative;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: inline-block;
}

/****** PAGES ******/
.chat-window .card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #e8ebed;
}

.chat-window .badge-success {
    background-color: #26af48 !important;
}

.chat-page .footer {
    display: none;
}

.chat-search .input-group .form-control {
    margin: 0;
    padding: 0 36px 0 0;
    height: 46px;
}

.chat-search .input-group .input-group-prepend {
    color: #5e5873;
    pointer-events: none;
    z-index: 4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
}

.chat-cont-left .chat-scroll {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding: 0;
}

.chat-cont-left .chat-scroll .media {
    border-bottom: 1px solid #e8ebed;
    margin: 0;
    padding: 10px;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

.chat-cont-left .chat-scroll .media.active {
    background-color: #f5f5f5;
}

.chat-cont-left .chat-scroll .media:hover {
    background-color: #f5f5f5;
}

.chat-cont-left .chat-scroll .media .media-img-wrap {
    margin: 0 15px 0 0;
    position: relative;
}

.chat-cont-left .chat-scroll .media .media-img-wrap .avatar > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat-cont-left .chat-scroll .media .media-body .user-name {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #212b36;
    font-size: 16px;
    font-weight: 700;
}

.chat-cont-left .chat-scroll .media .media-body .user-last-chat {
    color: #333;
    font-size: 14px;
    line-height: 24px;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-cont-left .chat-scroll .media .media-body .last-chat-time {
    color: #637381;
    font-size: 13px;
}

.chat-cont-left .chat-scroll .media .media-body div:last-child {
    text-align: right;
}

.chat-cont-right {
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
}

.chat-cont-right .chat-scroll {
    max-height: calc(100vh - 293px);
    overflow-y: auto;
}

.chat-cont-right .msg_head .back-user-list {
    display: none;
    margin: 0 0 0 -10px;
    padding: 0 10px;
}

.chat-cont-right .img_cont {
    position: relative;
    height: 45px;
    width: 45px;
}

.chat-cont-right .img_cont .user_img {
    height: 45px;
    min-width: 45px;
    width: 45px;
    border: 1.5px solid #05bbc9;
}

.chat-cont-right .user_info {
    margin: 0 15px 0 auto;
    padding: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-cont-right ul.list-unstyled {
    margin: 0 auto;
    padding: 0;
    width: 100%;
}

.chat-cont-right ul.list-unstyled .media.sent {
    margin-bottom: 20px;
}

.chat-cont-right ul.list-unstyled .media.sent .avatar {
    order: 3;
    height: 30px;
    width: 30px;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body {
    margin: 0 20px 0 0;
    padding: 0;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box {
    margin: 0;
    padding: 0 0 0 50px;
    position: relative;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box + .msg-box {
    margin: 5px 0 0;
    padding: 0;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box p {
    margin: 0;
    padding: 0;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box:first-child::before {
    border-top: 6px solid transparent;
    border-right: 6px solid #e3e3e3;
    border-bottom: 6px solid #e3e3e3;
    border-left: 6px solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    top: 0;
    right: auto;
    left: 38px;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box > div {
    margin: 0;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #e3e3e3;
    display: inline-block;
    position: relative;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box:first-child > div {
    border-radius: 0 10px 10px 10px;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments {
    margin: 0 -1px;
    padding: 4px 0;
    width: 100%;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments .chat-attachment {
    position: relative;
    max-width: 130px;
    overflow: hidden;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments .chat-attachment:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    content: "";
    opacity: 0.4;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments .chat-attachment:hover .chat-attach-download {
    opacity: 1;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments > div {
    margin: 0 1px;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments img {
    max-width: 100%;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments .chat-attach-download {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
    color: #fff;
    width: 32px;
    line-height: 32px;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info {
    margin: 5px 0 0;
    padding: 0;
    clear: both;
    flex-direction: row-reverse;
    list-style: none;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info li {
    margin: 0;
    padding: 0 0 0 16px;
    position: relative;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info li:not(:last-child)::after {
    position: absolute;
    top: 50%;
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    background: #05bbc9;
    content: "";
    height: 4px;
    width: 4px;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info .chat-time {
    color: rgba(50, 65, 72, 0.4);
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info a {
    color: #333;
}

.chat-cont-right ul.list-unstyled .media.received {
    position: relative;
    margin-bottom: 20px;
}

.chat-cont-right ul.list-unstyled .media.received .media-body {
    position: relative;
    margin: 0 0 0 20px;
    padding: 0;
}

.chat-cont-right ul.list-unstyled .media.received .media-body::before {
    border-top: 6px solid transparent;
    border-left: 6px solid #05bbc9;
    border-bottom: 6px solid #05bbc9;
    border-right: 6px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    top: 0px;
    right: -6px;
    left: auto;
    width: 0;
}

.chat-cont-right ul.list-unstyled .media.received .media-body > div {
    margin: 0;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #05bbc9;
    display: table;
    position: relative;
}

.chat-cont-right ul.list-unstyled .media.received .media-body > div p {
    color: #fff;
    margin: 0;
    padding: 0;
}

.chat-cont-right ul.list-unstyled .media.received .msg-box {
    display: block;
}

.chat-cont-right ul.list-unstyled .media.received .msg-box + .msg-box {
    margin-top: 5px;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-info {
    margin: 5px 0 0;
    padding: 0;
    clear: both;
    list-style: none;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-info .chat-time {
    color: rgba(255, 255, 255, 0.8);
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments {
    margin: 0 -1px;
    padding: 4px 0;
    width: 100%;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments .chat-attachment {
    position: relative;
    max-width: 130px;
    overflow: hidden;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments .chat-attachment:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    content: "";
    opacity: 0.4;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments .chat-attachment:hover .chat-attach-download {
    opacity: 1;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments > div {
    margin: 0 1px;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments img {
    max-width: 100%;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments .chat-attach-download {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
    opacity: 0;
    color: #fff;
    width: 32px;
    line-height: 32px;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
}

.chat-cont-right ul.list-unstyled .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}

.chat-cont-right ul.list-unstyled .chat-date:before {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin-right: 28px;
    position: absolute;
    top: 50%;
    right: 50%;
    width: 100%;
}

.chat-cont-right ul.list-unstyled .chat-date:after {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin: 0 0 0 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
}

.card-footer {
    background-color: #fff;
    border-top: 1px solid #e8ebed;
    margin: 0;
    padding: 1rem;
}

.card-footer .type_msg {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border: 0 !important;
    overflow-y: auto;
}

@media (max-width: 992px) {
    .chat-window.chat-slide .chat-cont-right {
        right: 0;
        opacity: 1;
        visibility: visible;
    }

    .chat-cont-right {
        position: absolute;
        right: -100px;
        opacity: 0;
        visibility: hidden;
        z-index: 99;
    }

    .chat-cont-right .msg_head .back-user-list {
        display: flex;
        align-items: center;
    }
}

.msg-typing {
    width: auto;
    height: 24px;
    padding-top: 8px;
}

.msg-typing span {
    height: 8px;
    width: 8px;
    float: left;
    background-color: #fff;
    display: block;
    opacity: 0.4;
    margin: 0 1px;
    border-radius: 50%;
}

.msg-typing span:nth-of-type(1) {
    animation: 1s blink infinite 0.33333s;
}

.msg-typing span:nth-of-type(2) {
    animation: 1s blink infinite 0.66666s;
}

.msg-typing span :nth-of-type(3) {
    animation: 1s blink infinite 0.99999s;
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}

.page-header {
    background: unset;
    padding: 10px 17px;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(180deg, #fff, transparent);
    margin: -12px;
    margin-bottom: 0;
    flex-wrap: wrap;
    gap: 7px;
}

.page-header h4 {
    font-size: 20px;
    font-family: "bold";
}

.page-header h4 i {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 24px;
    color: #05bbc9;
    text-decoration: none;
    background: rgba(5, 187, 201, 0.2);
}

.page-header .page-title {
    display: flex;
    align-items: center;
}

.page-header .page-title h6 {
    font-size: 16px;
    padding-right: 10px;
    border-right: 1px solid #ddd;
    margin-right: 10px;
    line-height: 17px;
}

.page-header .btn-added img {
    filter: brightness(0) invert(1);
}

@media (max-width: 991px) {
    .page-header .btn-added {
        padding: 3px 8px;
    }
}

.page-header .btn-added:hover {
    background: #1b2850;
}

.table-top {
    margin-bottom: 15px;
}

@media (max-width: 575px) {
    .table-top {
        flex-direction: column;
        gap: 15px;
    }
}

.btn-filter {
    background: #05bbc9;
    width: 34px;
    height: 34px;
    padding: 0;
}

.btn-filter:hover {
    background-color: #0498a4;
}

.btn-filters {
    width: 34px;
    height: 34px;
    padding: 0;
}

.btn-success {
    background: #4da56c;
    border-color: #4da56c;
    transition: 0.3s;
}

.btn-success:hover,
.btn-success:active,
.btn-success:focus {
    background: #3e8958;
    border-color: #3e8958;
    box-shadow: unset;
}

.search-set .btn-filter span {
    display: none;
}

.search-set .btn-filter.setclose {
    background: #ea5455;
}

.search-set .btn-filter.setclose > img {
    display: none;
}

.search-set .btn-filter.setclose span {
    display: block;
}

.search-set .btn-filter.setclose span img {
    display: block;
}

.search-set .btn-filter span {
    display: none;
}

.search-set .btn-filter > img {
    display: block;
}

.search-set .search-input {
    position: relative;
}

.search-set .search-input input[type="search"] {
    background: transparent;
    height: 40px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    width: 200px;
    border-radius: 5px;
    padding: 0 15px 0 30px;
}

.search-set .search-input .btn-searchset {
    padding: 2px;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: 6px;
}

.swal2-actions .swal2-confirm {
    margin-right: 10px;
}

.wordset ul li {
    margin: 0 10px;
}

.wordset ul li a {
    display: block;
}

.wordset ul li a img {
    width: 28px;
    height: 28px;
    object-fit: contain;
}

.checkboxs {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
}

.checkboxs input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkboxs input ~ .checkmarks {
    border: 1px solid #b9b9c3;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.checkboxs input:checked ~ .checkmarks {
    background-color: #05bbc9;
    border: 1px solid #05bbc9;
}

.checkboxs input:checked ~ .checkmarks:after {
    display: block;
}

.checkboxs .checkmarks {
    height: 18px;
    width: 18px;
    position: relative;
    display: block;
    margin: 0 auto;
    border-radius: 2px;
}

.checkboxs .checkmarks:after {
    content: "";
    position: absolute;
    display: none;
}

.checkboxs .checkmarks:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}

table .checkboxs {
    padding: 0;
    margin: 0;
}

.image-upload {
    border: 1px dashed rgb(151 221 227);
    position: relative;
    border-radius: 5px;
    background: #f9ffff;
}

.image-upload:hover {
    background: #eef9f9;
}

.image-upload input[type="file"] {
    width: 100%;
    opacity: 0;
    height: 135px;
    position: relative;
    z-index: 1;
    cursor: pointer;

}

.image-upload .image-uploads {
    text-align: center;
    width: 100%;
    padding: 15px 0;
    border-radius: 5px;
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #0da3af;
}

.image-upload .imagePreview {
    height: 100%;
    max-width: 300px;
    object-fit: contain;
}

.image-upload .image-uploads i {
    display: flex;
    font-size: 48px;
    margin-bottom: 5px;
}

.image-upload .image-uploads h4 {
    font-size: 17px;
}

.image-upload.image-upload-new input[type="file"] {
    height: 258px;
}

.image-upload.image-upload-new .image-uploads {
    height: 100%;
    flex-direction: column;
}

.checktoggle {
    background-color: rgba(115, 103, 240, 0.2);
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 13px;
    margin-bottom: 0;
    position: relative;
    width: 25px;
}

.checktoggle:after {
    content: " ";
    display: block;
    transform: translate(2px, -50%);
    width: 10px;
    height: 10px;
    background-color: #fff;
    transition: left 300ms ease, transform 300ms ease;
    border-radius: 50%;
    transform: translate(2px, -50%);
    -webkit-transform: translate(2px, -50%);
    -ms-transform: translate(2px, -50%);
    position: absolute;
    top: 50%;
    left: 0;
}

.check:checked + .checktoggle:after {
    background-color: #fff;
    left: 100%;
    transform: translate(calc(-90% - 3px), -45%);
}

.check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

.check:checked + .checktoggle {
    background-color: #28c76f;
}

#filter_inputs {
    border: 0;
    margin: 0;
}

#filter_inputs .card-body {
    padding: 0;
}

.dash-widget {
    background: #fff;
    margin-bottom: 25px;
    border-radius: 6px;
    padding: 20px;
    border: 1px solid #e8ebed;
}

.dash-widget.dash1 .dash-widgetimg span {
    background: rgba(40, 199, 111, 0.12);
}

.dash-widget.dash2 .dash-widgetimg span {
    background: rgba(0, 207, 232, 0.12);
}

.dash-widget.dash3 .dash-widgetimg span {
    background: rgba(234, 84, 85, 0.12);
}

.dash-widget .dash-widgetcontent {
    margin-right: 20px;
}

.dash-widget .dash-widgetcontent h5 {
    color: #212b36;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
}

.dash-widget .dash-widgetcontent h6 {
    font-weight: 400;
    font-size: 14px;
    color: #212b36;
}

.dash-widget .dash-widgetimg span {
    width: 48px;
    height: 48px;
    background: rgba(249, 110, 111, 0.12);
    border-radius: 50px;
}

.dash-count {
    background: #05bbc9;
    color: #fff;
    min-height: 98px;
    width: 100%;
    border-radius: 6px;
    margin: 0 0 25px;
    padding: 20px;
}

.dash-count.das1 {
    background: #00cfe8;
}

.dash-count.das2 {
    background: #1b2850;
}

.dash-count.das3 {
    background: #28c76f;
}

.dash-count h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
}

.dash-count h5 {
    font-size: 14px;
}

.dash-count .dash-imgs {
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.dash-count .dash-imgs img {
    width: 50px;
    height: 50px;
}

.dash-count .dash-imgs i {
    font-size: 36px;
    color: #fff;
}

.dash-count .dash-imgs svg {
    width: 50px;
    height: 50px;
}

.dash-count:hover .dash-imgs {
    transform: scale(1.25);
    -webkit-transform: scale(1.25);
    -ms-transform: scale(1.25);
}

.btn-white.dropdown-toggle:after {
    display: none;
}

@media (max-width: 991px) {
    .graph-sets > ul {
        display: none;
    }
}

.graph-sets > ul li {
    margin-left: 35px;
}

.graph-sets > ul li span {
    color: #637381;
    font-weight: 400;
    position: relative;
}

.graph-sets > ul li span:after {
    content: "";
    width: 12px;
    height: 12px;
    background-color: #28c76f;
    border-radius: 50px;
    position: absolute;
    top: 3px;
    left: -20px;
}

.graph-sets > ul li:last-child span:after {
    background-color: #ea5455;
}

.inbox-menu {
    display: inline-block;
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
}

.inbox-menu li {
    display: inline-block;
    width: 100%;
}

.inbox-menu li a {
    color: #333;
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
    font-weight: 600;
    text-transform: capitalize;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
}

.inbox-menu li a:hover {
    background: rgba(33, 33, 33, 0.05);
}

.inbox-menu li a i {
    font-size: 16px;
    padding-right: 10px;
    color: #878787;
}

.compose-btn {
    margin-bottom: 25px;
}

.top-action-left .dropdown-toggle {
    height: 40px;
}

.top-action-left .dropdown-toggle::after {
    display: none;
}

.table-inbox .starred.fas.fa-star {
    color: #ffd200;
}

.table-inbox .checked {
    background-color: #ffffcc;
}

.table-inbox tbody tr td {
    font-size: 14px;
    color: #212b36;
}

.unread .name,
.unread .subject,
.unread .mail-date {
    color: #000;
    font-weight: 700;
}

.login-content .login-logo {
    display: block;
    margin-bottom: 25px;
    width: max-content;
}

.login-content .login-userheading {
    margin-bottom: 30px;
}

.login-content .form-login {
    margin-bottom: 15px;
}

.login-content .form-login label {
    width: 100%;
    color: #212b36;
    margin-bottom: 3px;
    font-size: 15px;
    font-family: "bold";
}

.login-content .form-login .form-addons {
    position: relative;
}

.login-content .form-login .form-addons img {
    position: absolute;
    top: 16px;
    left: 15px;
}

.login-content .form-login input {
    padding: 8px 12px;
    color: #212529 !important;
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
}

input::placeholder {
    color: #7d8ca3;
    font-size: 14px;
    font-family: "bold";
}

.login-content .form-login input:focus {
    border: 1px solid #05bbc9;
}

.login-content .form-login .btn-login {
    background: #05bbc9;
    border-color: #05bbc9;
    padding: 7px 20px;
    transition: all 0.5s ease;
    margin-top: 20px;
    color: #fff;
    display: flex;
    gap: 7px;
}

.login-content .form-login .btn-login:hover {
    box-shadow: 0 50px #fff inset !important;
    color: #05bbc9;
}

.login-content .form-login .btn-login:focus {
    box-shadow: unset;
}

.login-content .form-login .alreadyuser {
    margin-bottom: 15px;
}

.login-content .form-login .alreadyuser h4 {
    font-size: 14px;
    font-weight: 400;
    color: #b8bcc9;
}

.login-content .form-login .alreadyuser h4 a {
    color: #05bbc9;
    font-weight: 600;
}

.login-content .signinform {
    margin-bottom: 25px;
}

.login-content .signinform h4 {
    font-size: 14px;
    font-weight: 400;
    color: #212b36;
}

.login-content .signinform h4 a {
    color: #212b36;
    font-weight: 600;
}

.login-content .form-setlogin {
    text-align: center;
    position: relative;
    color: #b8bcc9;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 40px;
}

.login-content .form-setlogin h4 {
    position: relative;
    font-size: 14px;
}

.login-content .form-setlogin h4:after {
    content: "";
    background: #cccccc;
    width: 175px;
    height: 1px;
    position: absolute;
    top: 10px;
    left: 0;
}

@media (max-width: 991px) {
    .login-content .form-setlogin h4:after {
        height: 0px;
    }
}

.login-content .form-setlogin h4:before {
    content: "";
    background: #cccccc;
    width: 175px;
    height: 1px;
    position: absolute;
    top: 10px;
    right: 0;
}

@media (max-width: 991px) {
    .login-content .form-setlogin h4:before {
        height: 0px;
    }
}

.login-content .toggle-password {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #637381;
    font-size: 18px;
    border-radius: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 10px;
}

.login-content .toggle-password:hover {
    color: #05bbc9;
}

.login-content .toggle-passwords {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #cccccc;
    font-size: 18px;
    border-radius: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 10px;
}

.login-content .toggle-passwords:hover {
    color: #05bbc9;
}

.login-content .pass-group {
    font-size: 14px;
}

.login-content .pass-group .fas {
    font-size: 13px;
    color: #939ea8;
}

.login-content .form-sociallink {
    margin-top: 25px;
}

.login-content .form-sociallink ul li {
    width: 50%;
    text-align: center;
}

@media (max-width: 575px) {
    .login-content .form-sociallink ul li {
        width: 100%;
    }
}

.login-content .form-sociallink ul li:first-child {
    padding-left: 15px;
}

@media (max-width: 575px) {
    .login-content .form-sociallink ul li:first-child {
        padding-left: 0;
    }
}

.login-content .form-sociallink ul li a {
    color: #212b36;
    width: 100%;
    border: 1px solid rgba(145, 158, 171, 0.23);
    border-radius: 5px;
    margin: 0 0 10px;
    padding: 9px 4px;
    font-weight: 500;
}

.login-content .form-sociallink ul li a:hover {
    background: #05bbc9;
    color: #fff;
}

.login-img img {
    width: 100%;
    display: block;
}

.hover-a {
    position: relative;
}

.hover-a:after {
    content: "";
    height: 2px;
    width: 100%;
    background: #05bbc9;
    transform: scale(0);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: absolute;
    bottom: -3px;
    left: 0;
}

.hover-a:hover:after {
    transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
}

.hover-a:hover {
    color: #05bbc9 !important;
}

.product-list ul {
    margin-bottom: 15px;
}

.product-list ul li {
    width: 25%;
    padding: 0 15px;
}

@media (max-width: 991px) {
    .product-list ul li {
        width: 50%;
        padding: 0 5px;
    }
}

@media (max-width: 575px) {
    .product-list ul li {
        width: 100%;
        padding: 0;
    }
}

.product-list ul li .productviews {
    border: 1px solid rgba(145, 158, 171, 0.32);
    margin: 0 0 10px;
    padding: 15px 15px 10px;
    border-radius: 5px;
}

.product-list ul li .productviews .productviewsimg img {
    max-width: 48px;
    max-height: 48px;
}

.product-list ul li .productviews .productviewscontent {
    margin-right: 10px;
    width: calc(100% - 48px);
}

.product-list ul li .productviews .productviewscontent .productviewsname h2 {
    font-weight: 500;
    font-size: 14px;
    color: #000;
}

.product-list ul li .productviews .productviewscontent .productviewsname h3 {
    color: #212b36;
    font-size: 13px;
    font-weight: 400;
}

.product-list ul li .productviews .productviewscontent a {
    color: #ea5455;
    font-weight: 700;
    border-radius: 50px;
    font-size: 20px;
    width: 22px;
    height: 22px;
}

.product-list ul li .productviews .productviewscontent a:hover {
    background: #ea5455;
    color: #fff;
}

.bar-code-view {
    width: 282px;
    border: 1px solid #d8d8d8;
    padding: 15px;
    margin-bottom: 20px;
    max-width: 100%;
}

.input-checkset ul li {
    margin-right: 30px;
}

@media (max-width: 991px) {
    .input-checkset ul li {
        margin-right: 10px;
    }
}

@media (max-width: 991px) {
    .productdetails .product-bar li {
        border: 1px solid #d8d8d8;
    }

    .productdetails .product-bar li:first-child {
        border: 1px solid #d8d8d8;
    }

    .productdetails .product-bar h4 {
        width: 50%;
        border-right: 1px solid #d8d8d8;
    }

    .productdetails .product-bar h6 {
        width: 50%;
    }
}

.productdetails.productdetailnew {
    margin-bottom: 25px;
}

.productdetails.productdetailnew ul li h4 {
    width: 50%;
}

.productdetails.productdetailnew ul li h6 {
    width: 50%;
}

.productdetails.productdetailnew ul li h6.manitorygreen {
    color: #0f9d58;
}

.productdetails.productdetailnew ul li h6.manitoryblue {
    color: #2aa4f4;
}

.productdetails > ul > li {
    border: 1px solid #d8d8d8;
    border-top: 0;
}

@media (max-width: 991px) {
    .productdetails > ul > li {
        border: 0;
    }
}

.productdetails > ul > li h4 {
    color: #212b36;
    font-size: 14px;
    font-weight: 500;
    width: 30%;
    border-right: 1px solid #d8d8d8;
    padding: 10px;
}

@media (max-width: 991px) {
    .productdetails > ul > li h4 {
        width: 100%;
        border-right: 0;
    }
}

.productdetails > ul > li h6 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    width: 70%;
    padding: 10px;
}

@media (max-width: 991px) {
    .productdetails > ul > li h6 {
        width: 100%;
    }
}

.productdetails > ul > li .input-checkset {
    padding: 10px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    width: 70%;
}

.productdetails > ul > li:first-child {
    border-top: 1px solid #d8d8d8;
}

@media (max-width: 991px) {
    .productdetails > ul > li:first-child {
        border: 0;
    }
}

@media (max-width: 991px) {
    .productdetails.product-respon {
        overflow: hidden;
    }

    .productdetails.product-respon ul {
        overflow: auto;
    }

    .productdetails.product-respon ul .input-checkset li {
        margin-bottom: 15px;
    }
}

.slider-product {
    text-align: center;
    width: 100%;
}

.slider-product img {
    width: auto !important;
    margin: 0 0 10px;
}

@media (max-width: 991px) {
    .slider-product img {
        height: auto;
    }
}

.slider-product h4 {
    font-size: 14px;
    color: #212b36;
    font-weight: 500;
}

.slider-product h6 {
    font-size: 13px;
    font-weight: 400;
    color: #212b36;
}

.slider-product-details .owl-carousel .owl-nav {
    position: relative;
}

.slider-product-details .owl-carousel .owl-nav button {
    width: 21px;
    height: 21px;
    color: #292d32;
    border: 1px solid #292d32;
    position: absolute;
    top: -30px;
    border-radius: 50px;
}

.slider-product-details .owl-carousel .owl-nav button:hover {
    background: #292d32;
    color: #fff;
}

.slider-product-details .owl-carousel .owl-nav button span {
    font-size: 16px;
}

.slider-product-details .owl-carousel .owl-nav .owl-next {
    position: absolute;
    top: -30px;
    left: 0;
}

.requiredfield {
    margin-bottom: 15px;
}

@media (max-width: 991px) {
    .requiredfield {
        margin-bottom: 10px;
    }
}

.requiredfield h4 {
    font-size: 14px;
    color: #637381;
}

.action-set {
    width: 18px;
    height: 18px;
    display: inline-block;
    font-size: 11px;
    border-radius: 50px;
}

.badges {
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    min-width: 80px;
}

.badges.bg-lightgreen {
    background: #28c76f;
}

.badges.bg-lightred {
    background: #ea5455;
}

.badges.bg-lightyellow {
    background: #ff9900;
}

.badges.bg-lightpurple {
    background: #7367f0;
}

.badges.bg-lightgrey {
    background: #637381;
}

.add-icon span,
.add-icon a {
    width: 40px;
    height: 40px;
    background: #05bbc9;
    border-radius: 5px;
}

@media (max-width: 575px) {

    .add-icon span,
    .add-icon a {
        position: relative;
        left: -5px;
        z-index: 10000;
    }
}

.add-icon a:hover {
    background: #1b2850;
}

.cal-icon {
    background-image: url(../img/icons/calendars.svg);
    background-repeat: no-repeat;
    background-position: 95% 50%;
}

.calc-no {
    background: #f8f8f8;
    border: 0 !important;
}

.total-order {
    max-width: 400px;
    width: 100%;
    background: #f3f3f7;
    padding: 15px 20px;
    border-radius: 5px;
    height: max-content;
}

.total-order li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
}

.total-order li h4 {
    font-weight: normal;
    font-size: 18px;
}

.total-order li h5 {
    display: flex;
    gap: 3px;
    font-size: 14px;
    align-items: center;
}

.total-order li h5 span {
    font-size: 18px;
}

.total-order li.discount h5 {
    color: #12a05c;
}

.total-order li.total {
    border-top: 1px dashed #d7d7d7;
    padding-top: 15px;
    margin-top: 15px;
    font-family: "bold";
    align-items: center;
    color: #404553;
    margin-bottom: 0;
}

.total-order li.total h4 {
    font-size: 20px;
}

.total-order li.total h5 span {
    font-size: 20px;
}

.total-order li .items-count {
    background: #39677c;
    padding: 2px;
    padding-bottom: 0;
    border-radius: 50px;
    line-height: 22px;
    color: #fff;
    min-width: 50px;
    text-align: center;
}

.card-sales-split {
    padding-bottom: 25px;
    border-bottom: 1px solid #e9ecef;
}

@media (max-width: 991px) {
    .card-sales-split {
        padding-bottom: 10px;
    }
}

@media (max-width: 575px) {
    .card-sales-split {
        flex-direction: column;
    }

    .card-sales-split ul {
        margin-top: 15px;
    }
}

.card-sales-split h2 {
    font-size: 14px;
    font-weight: 600;
}

.card-sales-split li {
    margin: 0 15px;
}

.image-upload-new {
    min-height: 258px;
}

.image-upload-new .productviewscontent {
    text-align: left;
    margin-top: 15px;
}

.image-upload-new .productviewscontent .productviewsname h2 {
    font-weight: 500;
    font-size: 14px;
    color: #000;
}

.image-upload-new .productviewscontent .productviewsname h3 {
    color: #212b36;
    font-size: 13px;
    font-weight: 400;
}

.image-upload-new .productviewscontent a {
    width: 22px;
    height: 22px;
    color: #ea5455;
    font-weight: 700;
    border-radius: 50px;
    font-size: 18px;
}

.image-upload-new .productviewscontent a:hover {
    background: #ea5455;
    color: #fff;
}

.image-upload-new .productviews {
    width: 100%;
    padding: 0 10px;
}

.uploadedimage .image-uploads {
    max-width: 100%;
}

.productviewset {
    padding-left: 18px;
    position: relative;
}

.productviewset .productviewsimg {
    max-width: 94px;
}

.productviewset .productviewscontent a {
    background: #fff;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    position: absolute;
    top: 4px;
    left: 82px;
}

.main-wrappers {
    background-color: #fafbfe;
}

.product-view {
    width: 100%;
    border: 1px solid #e5e5e5;
    margin-bottom: 20px;
}

.product-view:hover {
    border: 1px solid #05bbc9;
}

.product-view .product-viewimg {
    position: relative;
    max-height: 120px;
    overflow: hidden;
}

.product-view .product-viewimg h6 {
    background: #0f9d58;
    color: #fff;
    font-size: 8px;
    font-weight: 600;
    padding: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.product-view .product-viewcontent {
    padding: 13px;
}

.product-view .product-viewcontent h3 {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.product-view .product-viewcontent h4 {
    font-size: 12px;
    color: #6e6b7b;
    font-weight: 500;
    margin-bottom: 5px;
}

.product-view .product-viewcontent h5 {
    font-size: 14px;
    color: #6e6b7b;
    font-weight: 500;
}

.main-set {
    padding: 0 30px;
}

@media (max-width: 991px) {
    .main-set {
        padding: 0 10px;
    }
}

.text-underline {
    position: relative;
}

.text-underline::after {
    content: "";
    background: #637381;
    width: 10px;
    height: 2px;
    position: absolute;
    bottom: 12px;
    left: 10px;
}

.select-split {
    margin-bottom: 20px;
}

.select-split .select-group {
    width: calc(100% - 55px);
}

.select-split .btn-filters {
    width: 40px;
    height: 40px;
    margin: 0 0 0 15px;
    padding: 0;
}

.select-split .select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #e9ecef;
    height: 40px;
    border-radius: 4px;
}

.select-split .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 40px;
}

.select-split .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
}

.table tr:hover .productimg .productcontet h4 img {
    opacity: 1;
}

.table-product tr th {
    font-weight: 600;
    color: #6e6b7b;
    font-size: 14px;
}

.table-product tr td {
    font-weight: 600;
    color: #6e6b7b;
    font-size: 14px;
}

.btn-totallabel {
    color: #fff;
    background: #7367f0;
    border-radius: 5px;
    margin: 0 0 15px;
    padding: 12px;
}

.btn-totallabel h5 {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
}

.btn-totallabel h6 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
}

.setvaluecash {
    margin: 0 0 15px 0;
}

.setvaluecash ul li {
    width: 33%;
    margin-right: 10px;
}

.setvaluecash ul li:nth-child(1) {
    margin: 0;
}

.setvaluecash ul li a {
    border: 1px solid #e9ecef;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    min-height: 95px;
    border-radius: 5px;
    padding: 10px 20px;
}

.setvaluecash ul li a:hover {
    border: 1px solid #7367f0;
    color: #7367f0;
}

.setvaluecash ul li a img {
    margin-bottom: 10px;
}

.setvalue {
    padding: 15px 0;
}

.setvalue ul li {
    margin-bottom: 10px;
}

.setvalue ul li h5 {
    font-size: 13px;
    font-weight: 500;
}

.setvalue ul li h6 {
    font-size: 13px;
    font-weight: 500;
}

.setvalue ul li.total-value h5 {
    font-size: 16px;
    font-weight: 600;
    color: #7367f0;
}

.setvalue ul li.total-value h6 {
    font-size: 16px;
    font-weight: 600;
    color: #7367f0;
}

.product-lists {
    border: 1px solid #fafbfe;
    box-shadow: 0px 4px 4px 0px #ededed40;
    margin: 0 0 15px;
    padding: 15px;
}

@media (max-width: 568px) {
    .product-lists {
        flex-direction: column;
    }

    .product-lists li {
        margin: 10px 0;
    }
}

.increment-decrement .input-groups {
    margin-top: 10px;
}

.increment-decrement .input-groups input[type="button"] {
    background-color: transparent;
    font-size: 20px;
    border: 1px solid #b9b9c3;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    margin-top: 0px;
    line-height: 0;
    padding: 0;
    color: #b9b9c3;
}

.increment-decrement .quantity-field {
    width: 30px;
    text-align: center;
    background-color: transparent;
    float: left;
    border: 0;
}

.productimg .productimgs {
    width: 82px;
    margin-left: 13px;
}

.productimg .productimgs img {
    width: 82px;
    height: 82px;
    object-fit: cover;
}

.productimg .productcontet h4 {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    margin-bottom: 5px;
}

.productimg .productcontet h4 img {
    opacity: 0;
    margin-top: 5px;
    display: block;
}

.productimg .productlinkset h5 {
    background: #05bbc9;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
    margin-right: 10px;
    padding: 2px 10px;
}

.productimg .productlinkset a {
    position: relative;
    top: 2px;
}

.product-table {
    height: 34vh;
    overflow: auto;
}

@media (max-width: 991px) {
    .product-table {
        height: auto;
    }
}

.btn-set {
    margin: 15px 0 25px;
}

.btn-set h4 {
    font-weight: 700;
}

.product-detils {
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
}

@media (max-width: 991px) {
    .product-detils {
        height: auto;
    }
}

.tab-set {
    margin-bottom: 20px;
}

.tab-set ul li {
    width: 48%;
    text-align: center;
    margin-right: 5px;
}

@media (max-width: 575px) {
    .tab-set ul li {
        width: 100%;
        margin-right: 0;
    }
}

.tab-set ul li:last-child {
    margin: 0;
    width: 50%;
}

@media (max-width: 575px) {
    .tab-set ul li:last-child {
        width: 100%;
    }
}

.tab-set ul li a {
    background: #5e5873;
    width: 100%;
    display: block;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px;
}

@media (max-width: 575px) {
    .tab-set ul li a {
        border-radius: 0;
    }
}

.tab-set ul li.active a {
    background: #05bbc9;
}

#delete .modal-body .btn {
    min-width: 100px;
    padding: 10px;
}

.owl-product {
    padding-top: 35px;
}

.owl-product .owl-nav {
    margin-top: 10px;
    position: absolute;
    top: -10px;
    right: 0;
}

.owl-product .owl-nav button {
    border-radius: 50px !important;
    width: 20px;
    height: 20px;
    width: 19px;
    position: relative;
    height: 19px;
    background: #fff !important;
    color: #7367f0 !important;
}

.owl-product .owl-nav button span {
    font-size: 0;
}

.owl-product .owl-nav button.owl-next span:before {
    position: absolute;
    top: 2px;
    right: 5px;
    content: "\f053";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    color: #1a54c7;
}

.owl-product .owl-nav button.owl-prev span:before {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    color: #1a54c7;
    position: absolute;
    top: 2px;
    right: 7px;
}

.owl-product .owl-nav button:hover {
    background: #1a54c7 !important;
}

.owl-product .owl-nav button:hover span:before {
    color: #fff;
}

.actionproduct {
    text-align: right;
    margin-bottom: 15px;
}

.actionproduct ul {
    justify-content: end;
}

.actionproduct ul li {
    margin-left: 10px;
}

.btn-pos ul li {
    margin-right: 1px;
    margin-bottom: 10px;
}

@media (max-width: 991px) {
    .btn-pos ul li {
        width: 48%;
    }
}

@media (max-width: 575px) {
    .btn-pos ul li {
        width: 100%;
        margin-right: 10px;
    }
}

.btn-pos ul li a.btn {
    color: #888b94;
    font-size: 10px;
    width: 100%;
    padding: 5px 12px;
    background: #e9ecef;
    border-radius: 50px;
    border: 1px solid #e9ecef;
    padding: 5px 12px;
}

@media (max-width: 991px) {
    .btn-pos ul li a.btn {
        min-width: auto;
        padding: 6px 15px;
    }
}

.btn-pos ul li a.btn:hover {
    border: 1px solid #ea5455;
    color: #ea5455;
    background: rgba(234, 84, 85, 0.06);
}

.btn-pos ul li a.btn-hold {
    background: #ff9900;
}

.btn-pos ul li a.btn-quation {
    background: #6e6b7b;
}

.btn-pos ul li a.btn-void {
    background: #d0512e;
}

.btn-pos ul li a.btn-payment {
    background: #0f9d58;
}

.btn-pos ul li a.btn-recent {
    background: #05bbc9;
}

.calculator-set .calculatortotal {
    margin-bottom: 15px;
}

.calculator-set .calculatortotal h4 {
    width: 100%;
    background: #05bbc9;
    text-align: center;
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    border-radius: 5px;
}

.calculator-set ul li {
    width: 33.3%;
    text-align: center;
}

.calculator-set ul li a {
    width: 100%;
    border: 1px solid #b9b9c3;
    font-size: 24px;
    font-weight: 500;
    display: block;
    color: #000;
    border-radius: 0;
    padding: 10px;
}

.calculator-set ul li a.btn-closes {
    background: #ea5455;
}

.calculator-set ul li a.btn-reverse {
    background: #2e7d32;
}

.calculator-set ul li a:hover {
    background: #f5f5f5;
}

.hold-order {
    text-align: center;
    margin: 15px 0;
}

.hold-order h2 {
    padding: 0 0 30px;
    color: #05bbc9;
    font-size: 36px;
    font-weight: 700;
}

.para-set {
    margin-bottom: 30px;
}

.para-set p {
    color: #5e5873;
    font-size: 13px;
    font-weight: 500;
}

.delete-order {
    text-align: center;
}

.btn-set h4 {
    background: #05bbc9;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
}

.header-menuset {
    padding: 15px;
}

.header-menuset ul li {
    width: 50%;
    margin-bottom: 10px;
}

@media (max-width: 991px) {
    .header-menuset ul li {
        width: 100%;
    }
}

.header-menuset ul li .win-maximize {
    display: none;
}

.header-menuset ul li a {
    border: 1px solid #05bbc9;
    width: 95%;
    text-align: center;
    background: #05bbc9;
    color: #fff;
    padding: 10px;
}

.header-menuset ul li a img {
    filter: brightness(0) invert(1);
}

.invoice-load-btn {
    text-align: center;
    margin-top: 20px;
}

.invoice-load-btn .btn {
    font-weight: 700;
    font-size: 16px;
    color: #05bbc9;
    min-width: 150px;
    background: #fff;
    border: 2px solid #05bbc9;
    display: inline-flex;
    border-radius: 6px;
    padding: 12px 15px;
}

.invoice-load-btn .btn span {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 8px;
    vertical-align: 0;
    border: 3px solid #fc88037d;
    border-right-color: #05bbc9;
    animation: 0.75s linear infinite spinner-border;
    border-radius: 50%;
}

.invoice-load-btn .btn:hover {
    background: #05bbc9;
    color: #fff;
}

.invoice-load-btn .btn:hover span {
    border: 3px solid #fff;
    border-right-color: #fff;
}

.form-new .form-group input {
    height: 50px;
    background: #f8f8f8;
}

.form-new .form-group .scanner-set {
    min-width: 50px;
    position: relative;
    left: -2px;
}

.product-details {
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 4px 4px 0px #ededed40;
    border: 1px solid #f8f8f8 !important;
    height: 105px;
    flex-direction: column;
}

.product-details:hover {
    background: #7367f0;
}

.product-details:hover h6 {
    color: #fff;
}

.product-details.active {
    background-color: #7367f0 !important;
}

.product-details.active h6 {
    color: #fff;
}

.product-details img {
    width: auto !important;
}

.product-details h6 {
    font-size: 13px;
    color: #000;
    margin-top: 10px;
}

.btn-scanner-set {
    background: #7367f0;
    max-width: 160px;
    margin-left: auto;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
}

.btn-scanner-set:hover {
    background: #05bbc9;
    color: #fff;
}

.split-card {
    margin: 0 0 15px;
    border: 1px dashed #fafbfe;
    position: relative;
}

.split-card::before {
    position: absolute;
    content: "";
    background: #fafbfe;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    top: -10px;
    left: -10px;
}

.split-card::after {
    position: absolute;
    content: "";
    background: #fafbfe;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    top: -10px;
    right: -10px;
}

.totalitem {
    margin-bottom: 15px;
}

.totalitem h4 {
    padding: 7px 13px;
    background: #f8f8f8;
    color: #7367f0;
    font-size: 12px;
    font-weight: 700;
    border-radius: 50px;
}

.totalitem a {
    font-size: 14px;
    color: #ea5455;
    font-weight: 700;
}

.card.card-order {
    border: 0;
}

.btn-adds {
    border: 2px solid #28c76f;
    color: #28c76f;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;
    margin-bottom: 20px;
}

.btn-adds:hover {
    background: #28c76f;
    color: #fff;
}

.order-list {
    margin-bottom: 25px;
}

.order-list .orderid h4 {
    font-size: 18px;
    font-weight: 700;
    color: #5e5873;
    margin-bottom: 0;
}

.order-list .orderid h5 {
    font-size: 14px;
    font-weight: 400;
    color: #000;
}

.order-list .actionproducts > ul > li {
    margin-left: 15px;
}

.order-list .actionproducts > ul > li .deletebg {
    background: #fff;
    box-shadow: 0px 4px 14px 0px #c7c7c740;
    width: 30px;
    height: 30px;
    border-radius: 5px;
}

.tabs_wrapper .owl-product {
    margin-bottom: 25px;
}

.productset {
    border: 1px solid #f8f8f8;
    box-shadow: 0px 4px 4px 0px #ededed40;
    background: #fff;
    margin: 0 0 25px;
    border-radius: 5px;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.productset:hover {
    border: 1px solid #7367f0;
}

.productset .check-product {
    display: none;
    width: 34px;
    height: 23px;
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    background: #7367f0;
    border-radius: 4px 0;
}

.productset.active {
    border: 1px solid #7367f0;
}

.productset:hover .productsetimg img {
    transform: scale(1.2);
}

.productset:hover .productsetimg h6 {
    transform: translatey(0);
}

.productset .productsetimg {
    position: relative;
    overflow: hidden;
}

.productset .productsetimg img {
    transition: all 0.5s;
    border-radius: 5px 5px 0px 0px;
}

@media (max-width: 991px) {
    .productset .productsetimg img {
        max-height: 150px;
    }
}

.productset .productsetimg h6 {
    position: absolute;
    background: #05bbc9;
    color: #fff;
    font-size: 10px;
    padding: 5px;
    border-radius: 5px;
    top: 20px;
    right: 20px;
    transform: translatey(-60px);
    transition: all 0.5s;
}

.productset .productsetcontent {
    padding: 13px;
    text-align: center;
}

.productset .productsetcontent h4 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-bottom: 13px;
}

.productset .productsetcontent h5 {
    color: #6e6b7b;
    font-size: 12px;
}

.productset .productsetcontent h6 {
    color: #ea5455;
    margin: 0 0 0 auto;
    font-weight: 600;
    font-size: 14px;
}

.tabs_wrapper ul.tabs {
    display: inline-block;
    width: 100%;
    padding-left: 0;
}

.tabs_wrapper ul.tabs li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    border-radius: 5px;
    color: white;
    font-family: sans-serif;
    width: 100%;
}

.tabs_wrapper ul.tabs li.active {
    background-color: #7367f0;
}

.tabs_wrapper ul.tabs li.active .product-details {
    background: transparent;
    border: 0 !important;
}

.tabs_wrapper ul.tabs li.active .product-details h6 {
    color: #fff;
}

.tabs_wrapper .tabs_container {
    padding-top: 0px;
    display: inline-block;
    width: 100%;
}

.tabs_wrapper .tabs_container .tab_content {
    display: none;
    width: 100%;
}

.tabs_wrapper .tabs_container .tab_content.active {
    display: inline-block;
}

.profile-set {
    margin-bottom: 30px;
}

.profile-set .profile-head {
    height: 109px;
    background: linear-gradient(90deg, #ea5455 0%, #05bbc9 100%);
    border-radius: 0 10px 0 0;
}

@media (max-width: 991px) {
    .profile-set .profile-head {
        border-radius: 10px;
    }
}

@media (max-width: 767px) {
    .profile-set .profile-top {
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .profile-set .profile-top .profile-content {
        flex-direction: column;
    }
}

.profile-set .profile-top .profile-contentimg {
    margin-top: -40px;
    width: 130px;
    position: relative;
    border: 10px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.profile-set .profile-top .profile-contentimg img {
    border-radius: 50px;
}

.profile-set .profile-top .profileupload input[type="file"] {
    opacity: 0;
    width: 34px;
    height: 34px;
    display: block;
    z-index: 9;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 42px;
}

.profile-set .profile-top .profileupload a {
    background: #05bbc9;
    width: 34px;
    height: 34px;
    z-index: 1;
    border-radius: 50px;
    position: absolute;
    bottom: 10px;
    left: 41px;
}

.profile-set .profile-top .profile-contentname {
    padding-right: 20px;
}

@media (max-width: 767px) {
    .profile-set .profile-top .profile-contentname {
        text-align: center;
        padding: 20px 0 20px 0;
    }
}

.profile-set .profile-top .profile-contentname h2 {
    font-size: 18px;
    color: #212b36;
    font-weight: 700;
}

.profile-set .profile-top .profile-contentname h4 {
    color: #777;
    font-size: 14px;
}

/* new styles @mostafa 2023 */
.sidebarMinHeight {
    min-height: 430px;
}

.sidebarInner {
    overflow: scroll;
    width: 100%;
    height: 836px;
}

.displayBlock {
    display: block !important;
}

.languageHeader {
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    margin: 0px !important;
    transform: translate(0px, 62px) !important;
}

.notificationHeader {
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    margin: 0px !important;
    transform: translate(0px, 62px) !important;
}

.mobileMenuHeader {
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    margin: 58px 0px !important;
    transform: translate(0px, 2px) !important;
}

.table-img {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border: 0;
}

.warning-error {
    width: 22px;
    height: 22px;
}

.print-table .prevent-print {
    display: none;
}

.print-table {
    display: none;
}

.table thead th.prevent-print {
    width: 50px;
}

.table tr {
    text-align: center;
}

/* new layout page header */

.main-wrapper .user-menu {
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;
}

.form-group {
    margin-bottom: 15px;
}

/* select bootstrap */
.bt-select .form-select {
    border: 1px solid #ced4da;
    box-shadow: none;
    padding: 4px 10px;
    border-radius: 5px;
    background-position: left 7px center;
}

.bt-select .form-select:focus {
    border: 1px solid #05bbc9;
    box-shadow: none;
}

.bt-select .form-select optgroup {
    font-family: 'bold';
    font-size: 15px;
    color: #0da3af;
}

.bt-select .form-select option {
    color: #212b36;
    font-family: 'bold';
    font-size: 15px;
}


/* new layout page header */

.css-equal-content,
.css-equal-heights {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}

.activity .activity-list,
.notifications .notification-list,
.sidebar .sidebar-right ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.css-equal-content {
    color: red;
    float: left;
}

.pass-group .toggle-password,
.pass-group .toggle-passworda,
.profile-set .profile-top .profileupload a,
.productset .productsetimg,
.productset.active .check-product,
.order-list .actionproducts > ul > li .deletebg,
.product-details,
.header-menuset ul li a,
.owl-product .owl-nav button,
.productimg .productcontet h4,
.increment-decrement .input-groups input[type="button"],
.setvaluecash ul li a,
.select-split .btn-filters,
.product-view .product-viewimg,
.productviewset .productviewscontent a,
.image-upload-new .productviewscontent a,
.add-icon span,
.add-icon a,
.slider-product-details .owl-carousel .owl-nav button,
.product-list ul li .productviews .productviewscontent a,
.login-content .form-sociallink ul li a,
.login-content .toggle-passwords,
.login-content .toggle-password,
.dash-widget .dash-widgetimg span,
.image-upload.image-upload-new .image-uploads,
.search-set .btn-filter,
.page-header .btn-filter,
.customizer-links,
.modal .modal-footer .btn-print,
.modal .close,
.modal .btn-close,
.sidebar .nav-link,
div#global-loader,
.searchinputs .search-addon span,
.user-menu.nav > li > a .badge,
.error-page .main-wrapper,
.error-page,
.icons-items .icons-list li,
.icons-list li,
.form-group .btn-filters,
.input-group .scanner-set,
.avatar .avatar-title {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

.pass-group .toggle-password,
.pass-group .toggle-passworda,
.profile-set .profile-top .profileupload a,
.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top .profile-content,
.profile-set .profile-top,
.productset .productsetimg,
.productset.active .check-product,
.order-list .actionproducts > ul > li .deletebg,
.order-list .actionproducts > ul,
.order-list,
.totalitem,
.btn-scanner-set,
.product-details,
.invoice-load-btn .btn,
.header-menuset ul li a,
.header-menuset ul li,
.header-menuset ul,
.calculator-set ul,
.btn-pos ul,
.actionproduct ul,
.owl-product .owl-nav button,
.tab-set ul,
.productimg .productlinkset,
.productimg .productcontet h4,
.productimg,
.increment-decrement .input-groups input[type="button"],
.increment-decrement .input-groups,
.product-lists,
.setvalue ul li,
.setvaluecash ul li a,
.setvaluecash ul,
.btn-totallabel,
.select-split .btn-filters,
.select-split,
.product-view .product-viewimg,
.productviewset .productviewscontent a,
.image-upload-new .productviewscontent a,
.image-upload-new .productviewscontent,
.card-sales-split,
.total-order ul li,
.add-icon span,
.add-icon a,
.slider-product-details .owl-carousel .owl-nav button,
.input-checkset ul,
.bar-code-view,
.product-list ul li .productviews .productviewscontent a,
.product-list ul li .productviews .productviewscontent,
.product-list ul li .productviews,
.product-list ul,
.login-content .form-sociallink ul li a,
.login-content .form-sociallink ul,
.login-content .toggle-passwords,
.login-content .toggle-password,
.graph-sets > ul,
.graph-sets,
.dash-count,
.dash-widget .dash-widgetimg span,
.dash-widget,
.image-upload.image-upload-new .image-uploads,
.wordset ul,
.search-set .btn-filter,
.search-set,
.table-top,
.page-header .btn-added,
.page-header .btn-filter,
.page-header,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info,
.chat-search .input-group .input-group-prepend,
.switch-wrapper #dark-mode-toggle .light-mode,
.switch-wrapper #dark-mode-toggle .dark-mode,
.switch-wrapper #dark-mode-toggle,
.sidebar-content .sidebar-header,
.modal .modal-footer .btn-print,
.modal .close,
.modal .btn-close,
.modal-barcode ul,
.productimgname,
.sidebar .sub-menu a,
.sidebar .nav-link,
.sidebar .sidebar-menu > ul > li > a,
div#global-loader,
.sidebar.sidebar-four .sidebar-menu ul li a,
.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a,
.searchinputs .search-addon span,
.user-menu.nav > li.flag-nav a,
.user-menu.nav > li > a .badge,
.header-split .header-menu ul li a,
.header-split .header-menu ul,
.header-split,
.header-pos.header .user-menu.nav li,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .menu-drop-user .dropdown-item,
.header .userset,
.header .header-left,
.error-page,
.icons-items .icons-list li,
.icons-list li,
.form-group .btn-filters,
.input-group .scanner-set,
.btn-white,
.avatar .avatar-title {
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top,
.order-list,
.totalitem,
.invoice-load-btn .btn,
.header-menuset ul li,
.btn-pos ul,
.product-lists,
.setvalue ul li,
.btn-totallabel,
.image-upload-new .productviewscontent,
.card-sales-split,
.bar-code-view,
.product-list ul li .productviews .productviewscontent,
.dash-count,
.table-top,
.page-header,
.chat-cont-left .chat-scroll .media .media-body,
.sidebar-content .sidebar-header,
.sidebar .sub-menu a,
.header-split,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .header-left,
.btn-white {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.customizer-links ul li a,
.align-center {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.activity .activity-list,
.notifications .notification-list,
.sidebar .sidebar-right ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.switch-wrapper #dark-mode-toggle .light-mode,
.switch-wrapper #dark-mode-toggle .dark-mode,
.switch-wrapper #dark-mode-toggle,
.avatar-group {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.pass-group .toggle-password,
.pass-group .toggle-passworda,
.profile-set .profile-top .profileupload a,
.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top .profile-content,
.profile-set .profile-top,
.productset .productsetimg,
.productset.active .check-product,
.order-list .actionproducts > ul > li .deletebg,
.order-list .actionproducts > ul,
.order-list,
.totalitem,
.btn-scanner-set,
.product-details,
.header-menuset ul li a,
.header-menuset ul li,
.header-menuset ul,
.calculator-set ul,
.btn-pos ul li a.btn,
.btn-pos ul,
.actionproduct ul,
.owl-product .owl-nav button,
.tab-set ul,
.productimg .productlinkset,
.productimg .productcontet h4,
.productimg,
.increment-decrement .input-groups input[type="button"],
.increment-decrement .input-groups,
.product-lists,
.setvalue ul li,
.setvaluecash ul li a,
.setvaluecash ul,
.btn-totallabel,
.select-split .btn-filters,
.select-split,
.product-view .product-viewimg,
.productviewset .productviewscontent a,
.image-upload-new .productviewscontent a,
.image-upload-new .productviewscontent,
.card-sales-split ul,
.card-sales-split,
.total-order ul li,
.add-icon span,
.add-icon a,
.slider-product-details .owl-carousel .owl-nav button,
.productdetails > ul > li,
.input-checkset ul,
.bar-code-view,
.product-list ul li .productviews .productviewscontent a,
.product-list ul li .productviews .productviewscontent,
.product-list ul li .productviews,
.product-list ul,
.login-content .form-sociallink ul li a,
.login-content .form-sociallink ul,
.login-content .toggle-passwords,
.login-content .toggle-password,
.login-wrapper,
.graph-sets > ul,
.graph-sets,
.dash-count,
.dash-widget .dash-widgetimg span,
.dash-widget,
.image-upload.image-upload-new .image-uploads,
.wordset ul,
.search-set .btn-filter,
.search-set,
.table-top,
.page-header .btn-added,
.page-header .btn-filter,
.page-header,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments,
.chat-cont-right ul.list-unstyled .media.sent .media-body,
.chat-cont-left .chat-scroll .media .media-body,
.chat-search .input-group .input-group-prepend,
.sidebar-content .sidebar-header,
.customizer-links,
.modal .modal-footer .btn-print,
.modal .close,
.modal .btn-close,
.modal-barcode ul,
.productimgname,
.sidebar .nav-items .nav-link,
.sidebar .sub-menu a,
.sidebar .nav-link,
.sidebar .sidebar-menu > ul > li > a,
.sidebar,
div#global-loader,
.sidebar.sidebar-four .sidebar-menu ul li a,
.sidebar.sidebar-four .sidebar-menu,
.searchinputs .search-addon span,
.user-menu.nav > li.flag-nav a,
.user-menu.nav > li > a .badge,
.profilename .profileset,
.header-split .header-menu ul li a,
.header-split .header-menu ul,
.header-split,
.header-pos.header .user-menu.nav li,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .menu-drop-user .dropdown-item,
.header .userset,
.header .header-left,
.error-page .main-wrapper,
.error-page,
.icons-items .icons-list li,
.icons-items .icons-list,
.icons-list li,
.icons-list,
.form-group .btn-filters,
.input-group,
.input-group .scanner-set,
.dropdown-menu .dropdown-item,
.btn-white,
.avatar .avatar-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top,
.order-list,
.totalitem,
.invoice-load-btn .btn,
.header-menuset ul li,
.btn-pos ul,
.product-lists,
.setvalue ul li,
.btn-totallabel,
.image-upload-new .productviewscontent,
.card-sales-split,
.bar-code-view,
.product-list ul li .productviews .productviewscontent,
.dash-count,
.table-top,
.page-header,
.chat-cont-left .chat-scroll .media .media-body,
.sidebar-content .sidebar-header,
.sidebar .sub-menu a,
.header-split,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .header-left,
.btn-white {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.header-menuset ul,
.calculator-set ul,
.btn-pos ul,
.tab-set ul,
.productdetails > ul > li,
.input-checkset ul,
.product-list ul,
.login-content .form-sociallink ul,
.login-wrapper,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info,
.customizer-links,
.modal-barcode ul,
.error-page .main-wrapper,
.icons-items .icons-list,
.icons-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body {
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
}

.setvaluecash ul li a,
.chat-cont-right ul.list-unstyled .media.sent .media-body,
.sidebar .nav-items .nav-link {
    -ms-flex-direction: column;
    flex-direction: column;
}

.page-loader {
    background: #fff;
    position: fixed !important;
    z-index: 999999 !important;
}

.login-content {
    position: relative;
    height: 100vh;
    z-index: 99;
    background-color: #fff;
    background-image: url(../img/login-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.flex-login {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 50px;
}

.flex-login .right-div,
.flex-login .left-div {
    width: 100%;
}

.flex-login .login-logo img {
    max-height: 100px;
    max-width: 250px;
}

.login-userheading h2 {
    font-size: 28px;
    font-family: "bold";
    margin-bottom: 10px;
    background-image: linear-gradient(-225deg, #05bbc9 0%, #158d9d 100%);
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.login-userheading p {
    font-size: 17px;
    color: #212b36;
}

.form-addons,
.pass-group {
    position: relative;
}

.sidebar-menu .menu_list.nav {
    display: flex;
    gap: 10px;
    row-gap: 0 !important;
}

.flag-nav .nav-link {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    justify-content: center;
    transition: 0.3s;
}

.flag-nav .nav-link:hover {
    background: #cdf1f4;
}

.footer {
    padding: 5px 0 4px;
}

.footer p {
    font-size: 15px;
    text-align: center;
}

.line {
    background: linear-gradient(to bottom,
    rgb(0 0 0 / 8%),
    rgba(0, 0, 0, 0.03) 3px,
    transparent);
    display: block;
    height: 15px;
}

.line::after {
    content: "";
    background: linear-gradient(to left, #fafbfe, rgba(255, 255, 255, 0), #fafbfe);
    display: block;
    height: 15px;
}

.flex-right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.select-some {
    font-size: 16px;
}

.form-control {
    padding: 4px 10px;
    border-radius: 5px;
}

.pd-0 {
    padding: 0;
}

.flex-select-page {
    display: flex;
    gap: 5px;
    align-items: center;
}

.table-custom {
    border-collapse: separate;
    border-spacing: 0 8px;
    background: #fafafc;
    padding: 0 7px;
    border-radius: 7px;
    position: relative;
}

/*.table-vertical-line {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*  height: 100%;*/
/*  background: #ffffff14;*/
/*  width: 1px;*/
/*}*/

.table-custom.accounting-trial-balance-table {
    border-spacing: 0;
    padding: 7px;
}

.table-custom.accounting-trial-balance-table thead tr:last-child th {
    background: #c4cacf;
    border-radius: 0;
    border-left: 1px solid #ddd;
}

.table-custom.accounting-trial-balance-table thead tr:last-child th:last-child {
    border-left: 0
}

.table-custom.accounting-trial-balance-table tr td {
    border-bottom: 1px solid #ffffff4d;
    border-left: 1px solid #ffffff4d;
}

.table-custom.accounting-trial-balance-table tr td:last-child {
    border-left: 0;
}

table.table-custom.accounting-trial-balance-table tbody tr,
table.table-custom.accounting-trial-balance-table tbody tr td {
    border-radius: 0 !important;
    box-shadow: unset;
    color: #fff;
    position: relative;
}

table.table-custom.accounting-trial-balance-table tbody tr.tr-table-space {
    height: 2px;
}

table.table-custom.accounting-trial-balance-table tbody tr.bg-table-color-1 td {
    background: rgb(13 54 35 / 100%);
}

table.table-custom.accounting-trial-balance-table tbody tr.bg-table-color-2 td {
    background: rgb(13 54 35 / 90%);
}

table.table-custom.accounting-trial-balance-table tbody tr.bg-table-color-3 td {
    background: rgb(13 54 35 / 80%);
}

table.table-custom.accounting-trial-balance-table tbody tr.bg-table-color-4 td {
    background: rgb(13 54 35 / 70%);
}

table.table-custom.accounting-trial-balance-table tbody tr.bg-table-color-5 td {
    background: rgb(19 72 47 / 65%)
}

.table-custom.table > thead {
    border: 0;
    background: unset;
}

table.table-custom thead tr th {
    padding: 5px;
}

table.table-custom thead tr th:first-child {
    border-radius: 0 6px 6px 0;
}

table.table-custom thead tr th:last-child {
    border-radius: 6px 0 0 6px;
}

table.table-custom tbody tr {
    border-radius: 5px;
    box-shadow: rgb(99 99 99 / 10%) 0px 2px 8px 0px;
}

table.table-custom tbody tr td {
    border: 0;
    background: #fff;
}

table.table-custom tbody tr td:first-child {
    border-radius: 0 5px 5px 0;
}

table.table-custom tbody tr td:last-child {
    border-radius: 5px 0 0 5px;
}

.MuiPagination-root {
    margin-top: 10px;
}

.MuiPagination-ul .MuiButtonBase-root {
    color: #637381 !important;
    background-color: rgb(0 0 0 / 5%);
}

.MuiPagination-ul .MuiButtonBase-root:hover {
    background-color: rgb(235 235 235);
}

.MuiPagination-ul .MuiButtonBase-root.Mui-selected {
    color: #212b36 !important;
}

.flex-btn-table {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.flex-btn-table a,
.flex-btn-table button {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 4px;
    color: #fff !important;
    transition: 0.3s;
    border: 0;
}

.flex-btn-table i {
    display: flex;
}

.btn-info {
    background: #2b93c3;
    border-color: #2b93c3;
    color: #fff;
    transition: 0.3s;
}

.btn-info:hover,
.btn-info:active,
.btn-info:focus {
    background: #237ea9;
    border-color: #237ea9;
    color: #fff;
    box-shadow: unset !important;
}

.btn-danger {
    background: #e05a6b;
    border-color: #e05a6b;
    color: #fff;
    transition: 0.3s;
}

.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus {
    background: #a93b49;
    border-color: #a93b49;
    color: #fff;
    box-shadow: unset !important;
}

.loader-div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.loader {
    --s: 5px;
    width: calc(10 * var(--s));
    display: grid;
    aspect-ratio: 1;
    border-radius: 50%;
    -webkit-mask: radial-gradient(50% 50%,
    #0000 calc(99% - 2 * var(--s)),
    #000 calc(101% - 2 * var(--s)));
    animation: rotate 2s linear infinite;
    padding: 0 !important;
}

.loader:before {
    content: "";
    background: #f8a201;
    background: conic-gradient(from 25deg,
    #f8a201 25%,
    #fa2402 0 50%,
    #fdb3b0 0 75%,
    #02dde1 0);
    -webkit-mask: repeating-conic-gradient(#0000 0 25deg, #000 23% 25%),
    radial-gradient(var(--s) at var(--s) 50%, #000 97%, #0000) left/calc(100% - 2 * var(--s)) 100% repeat-x,
    radial-gradient(var(--s) at 50% var(--s), #000 97%, #0000) top / 100% calc(100% - 2 * var(--s)) repeat-y;
}

@keyframes rotate {
    to {
        transform: rotate(1turn);
    }
}

.flex-label {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.flex-error {
    position: absolute;
    top: 10px;
    left: -3px;
    background: #fff;
    border-radius: 50%;
}

.page-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}

.page-btn .btn {
    display: flex;
    align-items: center;
    gap: 5px;
    min-width: max-content;
    padding: 4px 12px;
}

.page-btn .btn i {
    display: flex;
}

.card-body .card-footer {
    padding: 0;
    padding-top: 15px;
    margin-top: 10px;
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
    justify-content: start;
    align-items: center;
}

.card-body .custom-footer {
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.card-footer .btn {
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.custom-btn-search {
    padding: 4px 10px;
    min-width: 100px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    direction: rtl;
}

.loader-btn {
    width: 17px;
    height: 17px;
    border: 3px dotted #fff;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation-btn 2s linear infinite;
}

@keyframes rotation-btn {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.btn {
    transition: 0.3s;
}

.swal2-popup {
    padding: 25px !important;
    width: 350px !important;
    box-shadow: 0 0 0 4px #ffffff70;
}

.swal2-icon {
    margin: 0 auto !important;
    margin-bottom: 15px !important;
}

.swal2-icon .swal2-icon-content {
    font-size: 2.75em !important;
}

.swal2-title {
    font-weight: normal !important;
    font-family: "bold";
    font-size: 19px !important;
    padding: 0 !important;
}

.swal2-html-container {
    margin: 0 !important;
    margin-top: 10px !important;
    font-size: 17px !important;
}

.swal2-actions {
    gap: 15px;
}

.swal2-styled {
    margin: 0 !important;
    min-width: 85px;
    padding: 5px 15px !important;
    border-radius: 3px !important;
}

.swal2-styled.swal2-confirm {
    background: #2b93c3 !important;
    border-color: #2b93c3 !important;
}

.swal2-styled.swal2-confirm:hover,
.swal2-styled.swal2-confirm:active,
.swal2-styled.swal2-confirm:focus {
    background: #237ea9 !important;
    border-color: #237ea9 !important;
    box-shadow: unset !important;
}

.swal2-styled.swal2-cancel {
    background: #e05a6b !important;
    border-color: #e05a6b !important;
}

.swal2-styled.swal2-cancel:hover,
.swal2-styled.swal2-cancel:active,
.swal2-styled.swal2-cancel:focus {
    background: #c14b5a !important;
    border-color: #c14b5a !important;
    box-shadow: unset !important;
}

.swal2-styled.swal2-deny {
    background: #4da56c !important;
    border-color: #4da56c !important;
}

.swal2-styled.swal2-deny:hover,
.swal2-styled.swal2-deny:active,
.swal2-styled.swal2-deny:focus {
    background: #3e8958 !important;
    border-color: #3e8958 !important;
    box-shadow: unset !important;
}

.card-custom .card-header {
    padding-bottom: 10px;
    border: 0;
}

.card-custom .card-body {
    padding: 15px;
    padding-top: 0;
}

.products-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    max-height: 207px;
    overflow: auto;
    padding-left: 5px;
}

.products-list li {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 5px;
    border-radius: 7px;
    background: #f3f3f7;
    text-align: right;
}

.products-list li span img {
    width: 35px;
    height: 35px;
    min-width: 35px;
    object-fit: contain;
}

.products-list li p {
    margin: 0;
    font-family: "bold";
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 18px;
    font-size: 14px;
}

.products-list li button {
    padding: 0;
    width: 26px;
    height: 26px;
    min-width: 26px;
    border-radius: 50%;
    margin-right: auto;
}

.products-list li button i {
    display: flex;
    justify-content: center;
    font-size: 20px;
}

.products-list .noresult img {
    height: 178px;
}

.products-list .noresult h4 {
    font-size: 19px;
}

table .form-group {
    margin: 0;
}

.select-table .bt-select .form-select {
    padding: 2px 6px;
    padding-left: 20px;
    font-size: 15px;
    background-position: left 5px center;
    background-size: 10px;
    width: auto;
    margin: 0 auto;
}

.select-table-sm .bt-select .form-select {
    padding: 2px 6px;
    padding-left: 20px;
    font-size: 15px;
    background-position: left 5px center;
    background-size: 10px;
    border-radius: 4px;
}

.input-table-sm .form-control {
    font-size: 15px;
    padding: 2px 10px;
    text-align: center;
    margin: 0 auto;
    border-radius: 4px;
}

.input-size .form-control {
    max-width: 250px;
}

.flex-select-input {
    position: relative;
}

.flex-select-input .select-table {
    position: absolute;
    right: 1px;
    top: 1px;
}

.flex-select-input .select-table .bt-select .form-select {
    border: 0;
    border-left: 1px solid #ced4da;
    background-color: #f3f3f3;
    border-radius: 0 4px 4px 0;
    padding: 2px 6px;
}

.purchase-unit {
    position: absolute;
    left: 2px;
    bottom: 2px;
    font-family: 'bold';
    width: max-content;
    background: #f3f3f3;
    height: 24px;
    padding: 0 7px;
    border-radius: 3px 0 0 3px;
    line-height: 24px;
}

.flex-select-input .input-table-sm .form-control {
    padding-left: 60px;
    min-width: 130px;
}

.flex-select-input .pdr-custom .form-control {
    padding-right: 90px;
    padding-left: 10px;
    min-width: 140px;
}

.price-red {
    font-size: 19px;
    font-family: "bold";
    color: #212529;
}

.flex-total-order {
    display: flex;
    gap: 40px;
    justify-content: space-between;
}

.MuiTooltip-tooltip {
    font-family: "regular" !important;
    background: #3a4242 !important;
    font-size: 13px !important;
    margin-top: 3px !important;
}

.noresult {
    text-align: center;
}

.noresult img {
    height: 200px;
}

.info-box {
    padding: 10px 15px;
    border-radius: 7px;
    gap: 15px;
    display: flex;
    align-items: center;
    margin: 12px 0;
    background: #05bbc91f;
    background: linear-gradient(270deg, #05bbc91f, transparent);
    color: #0c96a1;
}

.info-box i {
    font-size: 30px;
    display: flex;
}

.info-box p {
    margin: 0;
    font-size: 17px;
    font-family: 'bold';
}

.light-dark-mode .checkbox {
    display: none;
}

.light-dark-mode .checkbox-label {
    background-color: #0dbdcb;
    width: 48px;
    height: 24px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.light-dark-mode i {
    color: #ffffff;
    font-size: 15px;
    display: flex;
}

.light-dark-mode .checkbox-label .ball {
    background-color: #fff;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 3px;
    top: 3px;
    border-radius: 50%;
    transition: transform 0.2s linear;
}

.light-dark-mode .checkbox:checked + .checkbox-label .ball {
    transform: translateX(24px);
}

.list-pages-home {
    display: flex;
    gap: 15px;
    row-gap: 30px;
    justify-content: center;
    margin-bottom: 15px;
}

.list-pages-home li {
    width: 100%;
}

.list-pages-home li a {
    display: flex;
    gap: 12px;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 16px 40px 0px #0000000a;
    transition: unset;
}

.list-pages-home li i {
    font-size: 28px;
    background: #040e1c;
    color: #fff;
    width: 46px;
    height: 46px;
    min-width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: .3s;
}

.list-pages-home li a span {
    color: #0A0A0A;
    font-size: 28px;
    line-height: 30px;
    display: block;
}

.list-pages-home li a p {
    font-size: 17px;
    position: relative;
    z-index: 1;
    color: #404040;
}

.list-pages-home li:first-child a i {
    background: #9a69cb;
}

.list-pages-home li:nth-child(2) a i {
    background: #69cb83;
}

.list-pages-home li:nth-child(3) a i {
    background: #699ecb;
}

.list-pages-home li:nth-child(4) a i {
    background: #cb699b;
}

.list-pages-home li:nth-child(5) a i {
    background: #697acb;
}

.list-pages-home li:nth-child(6) a i {
    background: #cb9269;
}

.list-pages-home li:nth-child(7) a i {
    background: #69b9cb;
}

.list-pages-home li:first-child a:hover i {
    background: #9a69cb26;
    color: #9a69cb;
}

.list-pages-home li:nth-child(2) a:hover i {
    background: #69cb8326;
    color: #69cb83;
}

.list-pages-home li:nth-child(3) a:hover i {
    background: #699ecb26;
    color: #699ecb;
}

.list-pages-home li:nth-child(4) a:hover i {
    background: #cb699b26;
    color: #cb699b;
}

.list-pages-home li:nth-child(5) a:hover i {
    background: #697acb26;
    color: #697acb;
}

.list-pages-home li:nth-child(6) a:hover i {
    background: #cb926926;
    color: #cb9269;
}

.list-pages-home li:nth-child(7) a:hover i {
    background: #69b9cb26;
    color: #69b9cb;
}

.line-mg {
    margin-top: -12px;
}

.echarts-for-react {
    height: 220px !important;
    padding: 0 5px;
    direction: ltr;
}

.h-custom {
    height: 325px !important;
}

.title-chart {
    padding: 0 10px 15px;
    font-size: 20px;
    display: flex;
    gap: 10px;
}

.title-chart i {
    display: flex;
    font-size: 24px;
}

.btn:hover .loader-btn {
    border: 3px dotted #05bbc9;
}

.reports {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(5, 1fr);
}

.reports li {
    width: 100%;
}

.reports li a {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
    width: 100%;
    padding: 15px 10px;
    border-radius: 10px;
    border: 1px solid #c0d4e5;
    transition: all 0.2s ease;
    font-size: 20px;
    color: #385f81;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.reports li a:hover {
    transform: translateY(-5px);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    border: 1px solid #05bbc9;
    color: #0b949f;
    background: #ebfafb;
}

.reports li a i {
    display: flex;
    font-size: 26px;
    background: #eaf0f5;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.reports li a:hover i {
    background: #d6f5f7;
}

.sidebar.new-header .header-left {
    padding: 15px;
    padding-bottom: 5px;
    display: none;
}

tr.no-dts {
    box-shadow: unset !important;
}

tr.no-dts td {
    text-align: center !important;
    padding: 20px !important;
    font-size: 17px;
}

.MuiTypography-root.MuiDialogTitle-root {
    font-family: 'regular';
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 10px;
}

.MuiButtonBase-root.MuiIconButton-root {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    position: unset;
}

.MuiButtonBase-root.MuiIconButton-root i {
    display: flex;
}

.MuiDialogContent-root .card-footer {
    padding: 1rem 0 0;
    display: flex;
    gap: 15px;
}

.MuiBackdrop-root.MuiModal-backdrop {
    display: none;
}

.MuiDialog-container {
    background-color: rgb(11 25 47 / 80%);
    backdrop-filter: blur(4px);
}

.wrapper-calculator {
    grid-template-areas:
    "total total total total"
    "modif modif modif oper"
    "digit digit digit oper"
    "digit digit digit oper"
    "digit digit digit oper"
    "digit digit digit oper";
    grid-auto-columns: 1fr;
    direction: ltr;
    width: 300px;
    background-color: #182a46;
    padding: 10px;
}

.wrapper-calculator button {
    font-size: 25px;
    cursor: pointer;
    padding: 5px;
    border: 0;
    background: #eae3dc;
    color: #212938;
    border-radius: 5px;
}

.wrapper-calculator button:hover {
    background-color: #fff;
}

.operations button {
    background-color: #637097;
    color: #fff;
}

.operations button:hover {
    background-color: #3b496c;
}

.operations button.equals {
    background: #d03f2f;
}

.operations button.equals:hover {
    background-color: #93261a;
}

.wrapper-calculator,
.subgrid {
    display: grid;
    gap: 5px;
}

.show-input {
    grid-area: total;
}

.modifiers {
    grid-area: modif;
}

.operations {
    grid-area: oper;
}

.digits {
    grid-area: digit;
}

.show-input {
    font-size: 2rem;
    padding: 8px 5px;
    text-align: right;
    color: #ffffff;
    letter-spacing: 1px;
    border: 0;
    background: unset;
}

.modifiers {
    grid-auto-flow: column;
    grid-auto-columns: 2fr 68px;
}

.digits {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.digits button {
    flex: 1 0 26%;
}

.calc-modal .MuiDialogContent-root {
    padding: 0 !important;
    border: 0 !important;
}

.MuiDialogContent-root {
    border-bottom: 0;
}

.swal2-container {
    z-index: 99999 !important;
}

.swal2-container.swal2-backdrop-show {
    background-color: rgb(10 19 34 / 72%) !important;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}

.MuiDialog-paper {
    border-radius: 7px !important;
    box-shadow: 0 0 0 5px #ffffff21 !important;
}

.Toastify__toast {
    font-family: 'regular' !important;
    min-height: unset !important;
    direction: rtl !important;
}

.Toastify__close-button {
    align-self: center !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    background: #39913c !important;
}

.MuiModal-root {
    z-index: 99999;
}

.barcode-list {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.barcode-list svg {
    height: 100px;
    border: 1px dotted #ccc;
    padding: 5px;
    width: max-content;
}

.card-body .card-footer.pr-10 {
    padding-right: 10px;
}

.list-permissions {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    padding: 0;
    padding-bottom: 15px;
    margin-top: 15px;
}

.list-permissions li {
    background: #ffffff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 16px 40px 0px #0000000a;
}

.list-permissions li h4 {
    font-size: 18px;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.list-permissions .roles-check p {
    margin: 0;
    font-size: 15px;
    font-family: 'bold';
}

.list-permissions .roles-check .switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    line-height: 34px;
}

.switch input[type=checkbox] {
    appearance: none;
    height: 22px;
    width: 46px;
    background-color: #ffe1e1;
    position: relative;
    border-radius: 3px;
    cursor: pointer;
}

.switch input[type=checkbox]::before {
    content: '';
    display: block;
    height: 18px;
    width: 18px;
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #F44336;
    border-radius: 2px;
    transition: .3s ease;
}

.switch input[type=checkbox]:checked {
    background-color: #cfede5;
}

.switch input[type=checkbox]:checked::before {
    background-color: #009688;
    left: 26px;
}

.barcode-icon {
    font-size: 25px;
    color: #637381 !important;
}

.barcode-icon:hover {
    color: #27323c !important;
}

.barcode-icon i {
    display: flex;
    justify-content: center;
}

.bg-card {
    background-image: url(../img/login-bg.png);
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: left;
}

.profile-box {
    display: flex;
    gap: 30px;
}

.profile-box i {
    display: flex;
    font-size: 60px;
    color: #a2c6cf;
}

.profile-box .profile-dts h3 {
    font-size: 21px;
    margin-bottom: 0;
}

.profile-box .profile-dts p {
    margin: 0;
    font-size: 18px;
    color: #56687c;
}

.profile-box .profile-dts ul {
    display: flex;
    gap: 50px;
    margin-top: 20px;
}

.profile-box .profile-dts ul li:not(:last-child) {
    border-left: 1px solid #efefef;
    padding-left: 50px;
}

.profile-box .profile-dts ul li h4 {
    font-size: 17px;
    margin-bottom: 5px;
    color: #56687c;
}

.profile-box .profile-dts ul li span {
    font-size: 18px;
}

.card .pass-group {
    font-size: 14px;
}

.card .toggle-password {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #637381;
    font-size: 18px;
    border-radius: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 5px;
}

.mbody-0 .card-body {
    padding-bottom: 0;
}

.btn-permissions {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
}

.btn-permissions i,
.setting-icon i {
    display: flex;
}

.setting-icon {
    font-size: 23px;
    background: #05bbc9;
    color: #fff;
    width: 32px;
    height: 32px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    /* animation: animName 3s linear infinite; */
}

@keyframes animName {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.setting-sidebar-overlay {
    height: 100%;
    width: 100%;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: ease all 0.8s;
    transform: translateX(-100%);
}

.setting-sidebar-overlay.active {
    transform: translateX(0%);
}

.system-setting-sidebar {
    background-color: #fff;
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.05);
    z-index: 1001;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transition: ease all 0.8s;
    width: 380px;
    display: block;
    transform: translateX(-100%);
}

.system-setting-sidebar.active {
    transform: translateX(0);
}

.system-setting-sidebar .header-sidebar {
    padding: 15px;
    font-size: 20px;
    background-image: url(../img/header-sidebar.webp);
    background-size: contain;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.system-setting-sidebar .header-sidebar i:last-child {
    display: flex;
    width: 20px;
    height: 20px;
    background: #ffffff82;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    font-size: 22px;
    cursor: pointer;
}

.system-setting-sidebar .content-sidebar {
    padding: 20px 15px;
    overflow: auto;
    max-height: calc(100% - 62px);
}

.system-setting-sidebar .content-sidebar > li {
    border-top: 1px dashed #E1E3EA;
    padding-top: 12px;
    margin-top: 14px;
}

.system-setting-sidebar .content-sidebar > li:first-child {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
}

.system-setting-sidebar li.pos-only {
    display: none;
}

.system-setting-sidebar li h4 {
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.system-setting-sidebar li h4 i {
    display: flex;
    font-size: 15px;
}

.system-setting-sidebar li .p {
    margin-bottom: 15px;
    color: #383d5a;
}

.system-setting-sidebar li .flex-content {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 10px;
}

.system-setting-sidebar li .toggle-mode {
    width: 100%;
}

.system-setting-sidebar li .toggle-mode input {
    display: none;
}

.system-setting-sidebar li .toggle-mode label {
    cursor: pointer;
    width: 100%;
}

.system-setting-sidebar li .toggle-mode img {
    width: 100%;
    border-radius: 10px;
    border: 2px solid #ebebeb;
    margin-bottom: 8px;
}

.system-setting-sidebar li .toggle-mode span {
    font-size: 15px;
    font-family: 'bold';
    position: relative;
    padding-right: 29px;
}

.system-setting-sidebar li .toggle-mode span::after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    background: #ededed;
    border-radius: 50%;
    top: 2px;
    right: 0;
}

.system-setting-sidebar li .toggle-mode span::before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    background: #ededed;
    border: 5px solid #ededed;
    border-radius: 50%;
    top: 2px;
    right: 0;
    z-index: 1;
}

.system-setting-sidebar li .toggle-mode input:checked + label img {
    border: 2px solid #50cd89;
}

.system-setting-sidebar li .toggle-mode input:checked + label span::before {
    background: #ffffff;
    border: 5px solid #50cd89;
}

.system-setting-sidebar li .toggle-mode input:checked + label span::after {
    background: transparent;
}

.system-setting-sidebar li .switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    line-height: 34px;
}

.system-setting-sidebar li .switch p {
    margin: 0;
    font-size: 16px;
    font-family: 'bold';
}

.system-setting-sidebar li .switch input[type=checkbox] {
    border-radius: 40px;
    width: 48px;
    height: 24px;
    background: #ededed;
}

.system-setting-sidebar li .switch input[type=checkbox]::before {
    border-radius: 50%;
    background-color: #ffffff;
    height: 18px;
    width: 18px;
    top: 3px;
    left: 4px;
    box-shadow: 10px 0 calc(10px * 4) rgba(0, 0, 0, 0.1);
    transition: 0.15s ease-in-out;
}

.system-setting-sidebar li .switch input[type=checkbox]:checked {
    background: #50cd89;
}

.system-setting-sidebar li .switch input[type=checkbox]:checked::before {
    left: 26px;
}

.label-upload {
    border: 1px dashed rgb(151 221 227);
    border-radius: 5px;
    background: #f9ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #0da3af;
    cursor: pointer;
    padding: 20px;
}

.label-upload:hover {
    background: #eef9f9;
}

.label-upload i {
    display: flex;
    font-size: 48px;
    margin-bottom: 5px;
}

.label-upload span {
    font-size: 17px;
    text-align: center;
}

.label-upload input {
    margin-top: 12px;
    width: 65%;
    background: #f9ffff;
    display: none;
}

.file-status {
    padding: 10px;
    border: 1px solid #d8dded;
    border-radius: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.file-status .icon-excel {
    width: 54px;
    height: 54px;
    min-width: 54px;
    font-size: 30px;
    background: #54A86C;
    border-radius: 6px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-status .file-content {
    width: 60%;
    font-size: 17px;
    margin-left: auto;
}

.file-status .file-content p {
    margin: 0;
    line-height: 24px;
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    direction: ltr;
    text-align: right;
}

.file-status .file-content span {
    color: #5e6c84;
    line-height: 30px;
}

.file-status .progress-value {
    font-size: 20px;
    color: #5e6c84;
    margin: 0;
    letter-spacing: 1px;
    width: max-content;
}

.file-status .progress {
    display: flex;
    height: 3px;
    background: #d8dded;
    overflow: hidden;
    font-size: 0.65625rem;
    border-radius: 20px;
}

.file-status .progress-bar {
    height: 3px;
    background: #0079bf;
}

.file-status .loader-btn {
    border: 3px dotted #598ad1;
    min-width: 25px;
    width: 25px;
    height: 25px;
}

.file-status .bi-check-circle-fill {
    font-size: 25px;
    display: flex;
    color: #4CAF50;
}

.download-example {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: max-content;
    padding: 8px 25px;
    font-size: 16px;
    border-radius: 5px;
    gap: 10px;
    border: 1px solid transparent;
}

.download-example i {
    display: flex;
    font-size: 20px;
}

.holds-popup .MuiDialog-paper {
    width: 700px;
    max-width: 100%;
}

.installments-popup .MuiDialog-paper {
    width: 1000px;
    max-width: 100%;
}

.holds-popup .MuiAccordion-root {
    border-radius: 7px;
    border: 1px solid #ebebeb;
    margin-bottom: 10px;
    border-right: 5px solid #5bbdc5;
    border-left: 5px solid #5bbdc5;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.holds-popup .MuiAccordion-root::before {
    display: none;
}

.holds-popup .MuiAccordion-root.Mui-expanded {
    margin: 0;
    margin-bottom: 10px;
}

.holds-popup .MuiAccordion-root:last-child {
    margin: 0;
}

.holds-popup .MuiAccordionSummary-content {
    gap: 15px;
    align-items: center;
}

.holds-popup .MuiAccordionSummary-content .MuiTypography-root {
    border-right: 1px solid #ddd;
    padding-right: 15px;
    font-family: 'regular'
}

.holds-popup .MuiAccordionSummary-content .MuiTypography-root:first-child {
    border-right: 0;
    padding-right: 0;
}

.holds-popup .MuiAccordionSummary-content .MuiTypography-root:last-child {
    border-right: 0 !important;
    padding-right: 0;
    margin-right: auto;
    margin-left: 12px;
    border-left: 1px solid #ddd;
    padding-left: 15px;
    font-size: 22px;
}

.holds-popup .MuiTypography-root em {
    font-size: 14px;
    margin-right: 4px;
}

.holds-popup .btns-acc {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: end;
    margin-bottom: 10px;
    margin-top: -10px;
}

.holds-popup .btns-acc button {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 4px;
    color: #fff;
    transition: 0.3s;
    border: 1px solid transparent;
}

.holds-popup .btns-acc button.edit-invoise {
    background: #2b93c3;
    border-color: #2b93c3;
    color: #fff;
}

.holds-popup .btns-acc button.delete-invoise {
    background: #e05a6b;
    border-color: #e05a6b;
    color: #fff;
}

.holds-popup .btns-acc button i {
    display: flex;
}

.holds-popup .table-responsive {
    overflow-x: auto;
    max-height: 300px;
}

.holds-popup .MuiAccordion-root .table-custom {
    border-radius: 0;
    border-spacing: 0 1px;
    padding: 0;
    width: 100%;
}

.holds-popup .MuiAccordion-root .table-custom thead {
    position: sticky;
    top: 0;
    background: #eeeff5;
}

.holds-popup .MuiAccordion-root .table-custom thead tr th {
    font-size: 15px;
}

.holds-popup .MuiAccordion-root .table-custom thead tr th,
.holds-popup .MuiAccordion-root .table-custom tbody tr td {
    border-radius: 0 !important;
    text-align: center;
}

.holds-popup .MuiAccordion-root .table-custom tr th:first-child,
.holds-popup .MuiAccordion-root .table-custom tr td:first-child {
    text-align: right;
    padding-right: 12px;
}

.holds-popup .MuiAccordion-root .table-custom tr td {
    padding: 4px 7px;
}

.rest-total {
    font-size: 15px;
    display: flex;
    gap: 6px;
    align-items: center;
}

.rest-total span {
    font-size: 20px;
}

.switch-holder {
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 10px;
}

.switch-label {
    font-size: 18px;
}

.switch-toggle {
    height: 40px;
}

.switch-toggle input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -2;
}

.switch-toggle input[type="checkbox"] + label {
    position: relative;
    display: inline-block;
    width: 140px;
    height: 34px;
    border-radius: 20px;
    margin: 0;
    cursor: pointer;
    box-shadow: rgb(50 50 93 / 15%) 0px 0px 15px -12px inset, rgba(0, 0, 0, 0.2) 0px 10px 37px -18px inset;
}

.switch-toggle input[type="checkbox"] + label::before {
    position: absolute;
    content: 'نقدي';
    font-size: 16px;
    text-align: center;
    line-height: 26px;
    top: 4px;
    right: 5px;
    width: 70px;
    border-radius: 20px;
    background-color: #358c93;
    box-shadow: -3px -3px 5px rgba(255, 255, 255, .5), 3px 3px 5px rgba(0, 0, 0, .25);
    transition: .3s ease-in-out;
    color: #fff;
}

.switch-toggle input[type="checkbox"]:checked + label::before {
    right: 65px;
    content: 'آجل';
    background-color: #4e6374;
}

.type-sidebar {
    height: 95px;
    width: 90%;
    border: 2px solid #e6e9f3;
    border-radius: 5px;
    margin-bottom: 8px;
    padding: 5px;
}

.header-side {
    height: 10px;
    border-radius: 3px;
    position: relative;
    background-color: #f4f6fd;
}

.header-side ul {
    position: absolute;
    top: 3px;
    right: 5px;
    display: flex;
}

.header-side ul > li {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    top: calc(50% - 3px);
    background-color: #e5867f;
    margin-right: 2px;
    padding: 0;
}

.header-side ul > li:nth-child(2) {
    background-color: #ffce00;
}

.header-side ul > li:nth-child(3) {
    background-color: #7dc006;
}

.body-side {
    height: calc(100% - 14px);
    border-radius: 3px;
    position: relative;
    margin-top: 4px;
    text-align: center;
}

.body-side ul {
    height: 100%;
}

.body-side ul .sidebar-shape {
    border-radius: 3px;
    padding: 0;
    width: 100%;
    height: 12px;
    display: block;
    background: #9097b3;
}

.body-side ul .body-shape {
    width: 100%;
    padding: 0;
    margin: 0;
    height: calc(100% - 16px);
    border-radius: 3px;
    margin-top: 4px;
    background: #e0e3ef;
}

.vertical-side .body-side ul {
    display: flex;
    gap: 4px;
}

.vertical-side .body-side ul .sidebar-shape {
    width: 30px;
    height: 100%;
}

.vertical-side .body-side ul .body-shape {
    height: 100%;
    margin: 0;
}

.pos-profile-link {
    display: none !important;
}

.point-of-sale {
    perspective: 90px;
    position: relative;
    font-size: 17px;
    padding: 9px 12px;
    padding-bottom: 6px;
    margin-left: 10px;
    font-family: 'bold';
    color: #2ca9b3 !important;
    width: max-content;
}

.point-of-sale span {
    display: flex;
    z-index: 1;
    position: relative;
    gap: 7px;
    align-items: center;
}

.point-of-sale span img {
    width: 24px;
    height: 24px;
}

.point-of-sale::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: 2px solid #7cc7cd;
    border-radius: 7px;
    transform: rotateX(15deg);
    transition: .3s;
}

.point-of-sale:hover::after {
    background: #ffffff;
    border: 2px solid #73d7df;
}

.title-h3 {
    border-top: 1px solid #e8ebed;
    margin-top: 10px;
    padding-top: 15px;
    margin-bottom: 15px;
    font-size: 19px;
    font-family: "bold";
}

.tfoot-span {
    font-size: 22px;
    font-family: "bold";
    color: #e05a6b;
}

.flex-sp {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    direction: ltr;
    width: max-content;
    margin: 0 auto;
    font-size: 15px;
}

.flex-sp i {
    display: flex;
}

.svg-custom {
    margin: 0 auto;
    display: block;
}

.notes {
    width: max-content;
    display: block;
    margin: auto;
}

.notes i {
    font-size: 23px;
    display: flex;
    justify-content: center;
}

.flex-total-bonds {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 50px;
}

.flex-total-bonds h3 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
}

.flex-total-bonds h3 span {
    font-size: 23px;
    color: #d9556c;
}

.flex-total-bonds h3 span em {
    font-size: 18px;
}

.input-table-sm .flex-error {
    bottom: -14px;
    top: auto;
}

.custom-row {
    position: relative;
    background: #f3f3f7;
    border-radius: 5px;
    padding-top: 10px;
    margin: 0;
    margin-bottom: 8px;
}

.abs-x {
    padding: 0;
    position: absolute;
    left: -4px;
    width: auto;
    top: 0;
}

.abs-x button {
    padding: 0px 6px;
}

.row {
    --bs-gutter-x: 12px;
}

.custom-two-col {
    position: relative;
    width: 100%;
    display: block;
}

.custom-two-col::after {
    content: '';
    width: 90%;
    left: 0;
    right: 0;
    top: 10px;
    position: absolute;
    height: 15px;
    border: 1px solid #191c312e;
    border-bottom: 0;
    margin: auto;
}

.custom-two-col em {
    background: #fafafc;
    padding: 0 10px;
    position: relative;
    z-index: 1;
}

.w-75px {
    width: 75px;
    min-width: 75px;
}

.products-list.empty-content {
    grid-template-columns: 1fr;
}

.accordion-system {
    padding: 15px;
}

.accordion-system .item-accordion {
    margin-bottom: 15px;
}

.accordion-system .item-accordion:last-child {
    margin-bottom: 0;
}

.accordion-system .item-accordion .accordion-title {
    padding: 7px 15px;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid #c0d4e5;
    color: #385f81;
    background: #fff;
}

.accordion-system .item-accordion .accordion-title h3 {
    font-size: 19px;
}

.accordion-system .item-accordion .accordion-title i {
    font-size: 16px;
    display: flex;
    transition: .3s;
}

.accordion-system .item-accordion .accordion-contant {
    padding: 15px;
    position: relative;
    background: #fff;
    box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    margin-top: 6px;
    display: none;
}

.accordion-system .item-accordion.collapse-show .accordion-title {
    border: 1px solid #05bbc9;
    color: #0b949f;
    background: #ebfafb;
    box-shadow: unset;
}

.accordion-system .item-accordion.collapse-show .accordion-title i {
    transform: rotate(180deg);
}

.accordion-system .item-accordion.collapse-show .accordion-contant {
    display: block;
}

/* purchases bill */
.purchases-bill {
    background: #fff;
    border-radius: 8px;
    width: 950px;
    max-width: 100%;
    margin: 15px auto;
    position: relative;
    box-shadow: 0px 16px 40px 0px #0000000a;
    z-index: 1;
    overflow: hidden;
}

.purchases-bill::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30%;
    height: 30px;
    border-radius: 0 50px 0 0;
    z-index: 0;
    background-image: linear-gradient(to bottom, #50bec7, #4fa8ad);
}

.purchases-bill .bg-bill {
    padding: 20px;
}

.purchases-bill .title-bill {
    font-size: 21px;
    text-align: center;
    margin-bottom: 18px;
}

.purchases-bill .header-invoice {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}

.purchases-bill .header-invoice .logo-store {
    max-height: 100px;
    max-width: 200px;
}

.purchases-bill .header-invoice h3 {
    font-size: 18px;
}

.purchases-bill .bill-head {
    display: flex;
    align-items: center;
    gap: 15px;
}

.purchases-bill .bill-head p {
    margin: 0;
    display: flex;
    gap: 7px;
    font-size: 16px;
    color: #5d5f6a;
    min-width: max-content;
}

.purchases-bill .bill-head p span {
    color: #181C32;
    font-family: 'bold';
}

.purchases-bill .bill-head em {
    width: 100%;
    height: 12px;
    background: #f3f3f7;
    border-radius: 20px;
}

.purchases-bill .dts-bill {
    display: grid;
    gap: 15px;
    margin: 15px 0;
    grid-template-columns: repeat(3, auto);
    padding: 7px 10px;
    border-radius: 4px;
    background: #f9f9fb;
}

.purchases-bill .dts-bill p {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    min-width: max-content;
    color: #5d5f6a;
}

.purchases-bill .dts-bill span {
    color: #181C32;
    font-family: 'bold';
}

.purchases-bill .table-prd {
    width: 100%;
    border-bottom: 1px solid #efefef;
    text-align: center;
}

.purchases-bill .table-prd thead tr th {
    background: #f3f3f7;
    padding: 5px 7px;
    font-family: 'bold';
}

.purchases-bill .table-prd thead tr th:first-child {
    border-radius: 0 4px 4px 0;
    text-align: right;
}

.purchases-bill .table-prd thead tr th:last-child {
    border-radius: 4px 0 0 4px;
}

.purchases-bill .table-prd td {
    padding: 5px;
}

.purchases-bill .table-prd tr td:first-child {
    text-align: right;
}

.purchases-bill .table-prd td p {
    display: flex;
    justify-content: center;
    gap: 3px;
}

.purchases-bill .table-prd td p small {
    font-size: 13px;
}

.purchases-bill .total-bill {
    max-width: 310px;
    width: 100%;
    background: #f3f3f7;
    padding: 12px;
    border-radius: 7px;
    height: max-content;
    margin-right: auto;
}

.purchases-bill .total-bill li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.purchases-bill .total-bill li h4 {
    font-weight: normal;
    font-size: 16px;
}

.purchases-bill .total-bill li h5 {
    display: flex;
    gap: 3px;
    font-size: 14px;
    align-items: center;
}

.purchases-bill .total-bill li h5 span {
    font-size: 17px;
}

.purchases-bill .total-bill li.discount h5 {
    color: #12a05c;
}

.purchases-bill .total-bill li.total {
    border-top: 1px dashed #d7d7d7;
    padding-top: 5px;
    font-family: "bold";
    align-items: center;
    color: #404553;
    margin-bottom: 0;
}

.purchases-bill .total-bill li .items-count {
    background: #39677c;
    padding: 2px;
    padding-bottom: 0;
    border-radius: 50px;
    line-height: 22px;
    color: #fff;
    min-width: 50px;
    text-align: center;
}

.purchases-bill .flex-boxs {
    display: flex;
    gap: 20px;
    margin-top: 15px;
}

.purchases-bill .flex-boxs .right-box {
    width: 100%;
    flex-direction: column;
    display: flex;
    gap: 20px;
}

.purchases-bill .flex-boxs .table-prd {
    margin: 0;
    border: 0 !important;
}

.purchases-bill .flex-boxs .right-box h4 {
    display: flex;
    gap: 20px;
    font-size: 17px;
}

.purchases-bill .flex-boxs .right-box h4 span {
    font-family: 'bold';
}

.purchases-bill .flex-boxs .right-box .invoice-notes {
    display: flex;
    gap: 8px;
    margin-top: auto;
}

.purchases-bill .flex-boxs .right-box .invoice-notes i {
    font-size: 30px;
    color: #5d5f6a;
}

.purchases-bill .flex-boxs .right-box .invoice-notes p {
    font-size: 14px;
    line-height: 20px;
}

.purchases-bill .flex-boxs .right-box .invoice-notes p span {
    display: block;
    font-family: 'bold';
    font-size: 15px;
    margin-bottom: 1px;
}

.purchases-bill .footer-bill {
    padding: 20px 30px;
    border-radius: 100px 0 0 0;
    background-color: #f3f3f7;
    background-image: linear-gradient(to bottom, #f3f3f7, #ffffff);
    width: 80%;
    margin-top: 20px;
}

.purchases-bill .footer-bill h4 {
    display: flex;
    gap: 5px;
    font-size: 17px;
    margin-bottom: 5px;
}

.purchases-bill .footer-bill .contact-bill {
    display: flex;
    gap: 50px;
}

.purchases-bill .footer-bill .contact-bill li {
    display: flex;
    gap: 5px;
    font-size: 16px;
}

.purchases-bill .footer-bill .contact-bill li span {
    direction: ltr;
}

.print-bill {
    margin: 30px auto 15px;
    padding: 5px 25px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.print-bill i {
    display: flex;
    font-size: 18px;
}

/* end purchases bill */

/* cash receipt */

.cash-receipt {
    background: #fff;
    border-radius: 8px;
    min-width: 700px;
    margin: 25px auto;
    box-shadow: 0 0 0 2px #fff, 0 0 0 3px #181C32, 0px 16px 40px 0px #0000000a;
    padding: 25px 20px;
    border: 1px solid #181C32;
    width: max-content;
}

.cash-receipt h3 {
    width: max-content;
    margin: 0 auto;
    margin-bottom: 35px;
    border: 1px solid #363a4c;
    font-family: 'bold';
    font-size: 21px;
    padding: 2px 12px;
    border-radius: 6px;
}

.cash-receipt ul {
    margin-bottom: 15px;
}

.cash-receipt ul li {
    display: flex;
    gap: 9px;
    margin-bottom: 20px;
}

.cash-receipt ul li h5 {
    font-family: 'bold';
    font-size: 18px;
    min-width: max-content;
}

.cash-receipt ul li h6 {
    width: 100%;
    position: relative;
}

.cash-receipt h6::after {
    content: '';
    position: absolute;
    border-bottom: 1px dashed #bbb;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
}

.cash-receipt ul li h6.w-h6 {
    width: 100px;
    min-width: 100px;
}

.cash-receipt .flex {
    display: flex;
    justify-content: space-between;
    gap: 7px;
}

.cash-receipt .date-receipt {
    display: flex;
    gap: 7px;
}

.cash-receipt .date-receipt h5 {
    font-family: 'bold';
    font-size: 18px;
    min-width: max-content;
}

.cash-receipt .date-receipt p {
    position: relative;
    top: 1px;
    font-size: 18px;
}

.cash-receipt .receiver-name {
    text-align: center;
    min-width: 185px;
}

.cash-receipt .receiver-name h5 {
    font-family: 'bold';
    font-size: 18px;
    min-width: max-content;
}

.cash-receipt .receiver-name h6 {
    position: relative;
}

/* end cash receipt */

/* tree page */
.dts-tree {
    background: #f0f2f5;
    padding: 5px 15px 7px;
    border-radius: 6px;
    position: absolute;
    left: 10px;
    top: 10px;
    min-width: 200px;
}

.dts-tree h3 {
    font-size: 17px;
    margin-bottom: 8px;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 5px;
}

.dts-tree .dts-fx {
    display: flex;
    gap: 15px;
}

.dts-tree .dts-fx h6 {
    font-size: 16px;
}

.dts-tree p {
    display: flex;
    gap: 5px;
    font-size: 15px;
    margin: 0;
    color: #636887;
}

.dts-tree .flex-add-node {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 8px;
}

.dts-tree .add-node {
    background: #2d3748;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 14px;
    padding: 2px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid transparent;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.dts-tree .add-node i {
    display: flex;
}

.dts-tree .add-node:hover {
    background: #fff;
    color: #2d3748;
    border-color: #5a6476;
}

.side-tree {
    background-color: #fff;
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.05);
    z-index: 99999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transition: ease all 0.6s;
    width: 600px;
    display: block;
    transform: translateX(-100%);
}

.side-tree.active {
    transform: translateX(0);
}

.side-tree-overlay {
    height: 100%;
    width: 100%;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: ease all 0.6s;
    transform: translateX(-100%);
}

.side-tree-overlay.active {
    transform: translateX(0%);
}

.side-tree .header-tree {
    padding: 15px;
    font-size: 20px;
    background: linear-gradient(180deg, #49e5ca, transparent);
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.side-tree .header-tree i:last-child {
    display: flex;
    width: 20px;
    height: 20px;
    background: #ffffff82;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    font-size: 22px;
    cursor: pointer;
}

.side-tree .card-body {
    height: calc(100vh - 65px);
    overflow: auto;
}

.side-tree .dts-node {
    background: #f5f7f9;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    background: linear-gradient(0deg, #e3e8f070, transparent);
    display: flex;
    gap: 25px;
}

.side-tree .dts-node h3 {
    font-size: 18px;
    margin-bottom: 0;
    text-align: center;
}

.side-tree .dts-node .dts-fx {
    display: flex;
    gap: 35px;
    justify-content: center;
}

.side-tree .dts-node p {
    display: flex;
    gap: 10px;
    font-size: 17px;
    margin: 0;
    color: #636887;
}

.tabs-side {
    display: flex;
    gap: 10px;
    background: #E2E8F0;
    padding: 5px;
    border-radius: 50px;
    margin-bottom: 15px;
}

.tabs-side li {
    padding: 4px 20px;
    border-radius: 50px;
    font-size: 16px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.tabs-side li.active {
    background: #fff;
}

.content-tabs-side .contant-tab {
    display: none;
}

.content-tabs-side .contant-tab.active {
    display: block;
}

.side-tree .card-footer .btn {
    padding: 4px;
    min-width: 95px;
}

.tree-img {
    display: block;
    margin: 25px auto;
    height: 170px;
}

.flex-btns-tree {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
}

.flex-btns-tree button {
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 15px;
    transition: .2s;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -ms-transition: .2s;
    -o-transition: .2s;
}

.btn-1 {
    background-color: #489ca7;
    border-color: #489ca7;
    color: #fff;
}

.btn-1:hover {
    background-color: #fff;
    border-color: #489ca7;
    color: #489ca7;
}

.btn-2 {
    background-color: #6f9d56;
    border-color: #6f9d56;
    color: #fff;
}

.btn-2:hover {
    background-color: #fff;
    border-color: #6f9d56;
    color: #6f9d56;
}

.btn-3 {
    background-color: #c16080;
    border-color: #c16080;
    color: #fff;
}

.btn-3:hover {
    background-color: #fff;
    border-color: #c16080;
    color: #c16080;
}

.btn-4 {
    background-color: #4082a9;
    border-color: #4082a9;
    color: #fff;
}

.btn-4:hover {
    background-color: #fff;
    border-color: #4082a9;
    color: #4082a9;
}

.btn-5 {
    background-color: #9d62a7;
    border-color: #9d62a7;
    color: #fff;
}

.btn-5:hover {
    background-color: #fff;
    border-color: #9d62a7;
    color: #9d62a7;
}

.flex-radio-box {
    display: flex;
    gap: 10px;
    background: #E2E8F0;
    padding: 5px;
    border-radius: 50px;
    margin-bottom: 15px;
}

.flex-radio-box input {
    display: none;
}

.flex-radio-box label {
    padding: 4px 20px;
    border-radius: 50px;
    font-size: 16px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.flex-radio-box input:checked + label {
    background: #fff;
}

.totals-inviose-tree {
    margin-bottom: 12px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.totals-inviose-tree p {
    margin: 0;
    font-size: 17px;
}

.totals-inviose-tree p span {
    font-size: 19px;
}

.totals-inviose-tree p em {
    font-size: 16px;
}

.name-financial {
    margin: 20px 0;
}

.name-financial i {
    display: inline-flex;
    color: #4CAF50;
    margin-left: 7px;
    font-size: 22px;
}

.name-financial span {
    color: #0e94a5;
    margin: 0 2px;
}

.tree-view-side {
    height: calc(100vh - 500px);
    border: 2px solid #eff4fb;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 15px;
    overflow: auto;
}

.tree-view-side .MuiTreeItem-label {
    font-family: 'regular' !important;
    padding: 2px;
}

.tree-view-side .MuiTreeItem-iconContainer svg.close {
    opacity: 0.7;
}

.tree-view-side .MuiTreeItem-group {
    margin-right: 15px;
    padding-right: 18px;
    border-right: 1px solid rgb(0 0 0 / 48%);
}

.tree-view-side .MuiTreeItem-group .MuiTreeItem-root {
    position: relative;
}

.tree-view-side .MuiTreeItem-group .MuiTreeItem-root::after {
    content: '';
    border-bottom: 1px solid rgb(0 0 0 / 48%);
    position: absolute;
    right: -18px;
    top: 14px;
    width: 16px;
}

.tree-view-side .MuiTreeItem-content.Mui-selected svg.close {
    background: #3abdc8;
    fill: #3abdc8;
    box-shadow: 0 0 0 1px #3abdc8;
    opacity: 1;
    font-size: 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.prd-dts {
    gap: 20px;
    display: flex;
    align-items: start;
}

.prd-dts img {
    width: 300px;
    min-width: 300px;
    height: 120px;
    object-fit: contain;
}

.prd-dts .content-prd {
    width: 100%;
}

.prd-dts .content-prd h4 {
    font-size: 20px;
    margin-bottom: 12px;
}

.table-content-prd {
    display: flex;
    align-items: start;
    gap: 40px;
}

.table-content-prd .table tr {
    text-align: right;
    font-size: 15px;
    font-family: 'bold';
}

.table-content-prd .table tr td {
    padding: 3px 10px;
}

.table-content-prd .table tr :nth-child(odd) {
    background: rgb(173 165 165 / 5%);
    color: #181C32;
    width: 190px;
}

.custom-pd .btn-filters {
    margin-top: 23px;
}

.transfer-stock {
    display: flex;
    gap: 30px;
    row-gap: 15px;
    flex-wrap: wrap;
}

.transfer-stock p {
    font-size: 17px;
    color: #181c32b5;
    margin-bottom: 0;
    display: flex;
    gap: 5px;
}

.transfer-stock p span {
    color: #181c32;
    font-family: 'bold';
}

.tabs-radio {
    display: flex;
    gap: 10px;
    background: #E2E8F0;
    padding: 5px;
    border-radius: 50px;
    width: max-content;
    margin: auto;
    margin-bottom: 20px;
}

.tabs-radio input {
    display: none;
}

.tabs-radio li label {
    padding: 6px 24px;
    border-radius: 50px;
    font-size: 16px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-family: 'bold';
}

.tabs-radio li input:checked + label {
    background: #fff;
}

.movement-tabs-content .hide {
    display: none;
}

/* dark mode */

/* tree */
body[data-theme="dark"] .tree-view-side {
    border: 2px solid #142746;
}

body[data-theme="dark"] .tree-view-side .MuiTreeItem-group {
    border-right: 1px dashed rgb(107 117 133);
}

body[data-theme="dark"] .name-financial span {
    color: #37b4bf;
}

body[data-theme="dark"] .side-tree {
    background-color: #0f1f38;
}

body[data-theme="dark"] .side-tree .header-tree {
    color: #f3f4f5;
    background: linear-gradient(180deg, #224170, #111f37);
}

body[data-theme="dark"] .side-tree .header-tree i:last-child {
    background: #182a466b;
}

body[data-theme="dark"] .side-tree .dts-node {
    background: #162948;
}

body[data-theme="dark"] .side-tree .dts-node p {
    color: #babed1;
}

body[data-theme="dark"] .tabs-side {
    background: #293f62;
}

body[data-theme="dark"] .tabs-side li.active {
    background: #15294a;
}

body[data-theme="dark"] .dts-tree {
    background: #23385c;
}

body[data-theme="dark"] .dts-tree p {
    color: #babed1;
}

body[data-theme="dark"] .dts-tree .add-node {
    background: #245e9d;
}

body[data-theme="dark"] .dts-tree .add-node:hover {
    background: #fff;
    color: #245e9d;
    border-color: #245e9d;
}

body[data-theme="dark"] .flex-btns-tree button:hover {
    background: transparent;
}

body[data-theme="dark"] .dts-tree h3 {
    border-bottom: 1px solid #ffffff1c;
}

body[data-theme="dark"] .flex-radio-box {
    background: #293f62;
}

body[data-theme="dark"] .flex-radio-box input:checked + label {
    background: #15294a;
}

/* end tree */

/* vertical menu */
body[data-side="vertical"] .page-wrapper {
    width: calc(100vw - 250px);
    margin-right: auto;
}

body[data-side="vertical"] .sidebar.new-header {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 240px;
    display: block;
    z-index: 99;
}

body[data-side="vertical"] .header-left {
    display: none;
}

body[data-side="vertical"] .sidebar.new-header .header-left {
    display: block;
    padding: 5px 15px;
}

body[data-side="vertical"] .sidebar.new-header .header-left .logo img {
    max-width: 210px;
}

body[data-side="vertical"] .sidebar-menu .menu_list.nav {
    display: block;
}

body[data-side="vertical"] .sidebar.new-header .sidebar-menu > ul > li {
    border-left: 0;
    padding-left: 0;
}

body[data-side="vertical"] .sidebar-menu {
    padding: 0 15px;
    overflow: auto;
    height: calc(100vh - 65px);
}

body[data-side="vertical"] .sidebar.new-header .sidebar-menu > ul > li a {
    padding: 12px 0;
}

body[data-side="vertical"] .sidebar .sidebar-menu > ul > li.submenu ul li a {
    padding: 6px 30px 6px 2px;
    line-height: 22px;
}

body[data-side="vertical"] .sidebar.new-header .sidebar-menu > ul > li .menu-arrow {
    margin-right: auto;
}

body[data-side="vertical"] .sidebar .sidebar-menu > ul > li > a span img {
    width: 24px;
    animation: unset !important;
}

body[data-side="vertical"] .sidebar.new-header .sidebar-menu > ul > li ul {
    display: none;
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
    margin-top: 0;
    box-shadow: unset;
    padding: 0;
    transform: unset;
    border-radius: 0;
    opacity: 1;
    visibility: visible;
    background: unset;
    box-shadow: unset;
}

body[data-side="vertical"] .sidebar.new-header .sidebar-menu > ul > li ul.active {
    display: block;
}

body[data-side="vertical"] .sidebar.new-header .sidebar-menu > ul > li ul li a::after {
    top: 12px;
}

/* end vertical menu */

/* horizontal menu */
body[data-side="horizontal"] .header {
    z-index: 999;
}

body[data-side="horizontal"] .menu-overlay {
    height: 100%;
    width: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
}

body[data-side="horizontal"] .menu-overlay.active {
    opacity: 1;
    visibility: visible;
}

/* end horizontal menu */

body[data-theme="dark"],
body[data-theme="dark"] .bt-select .form-select option {
    color: #c4cbd3;
}

body[data-theme="dark"] .light-dark-mode .checkbox-label {
    background-color: #3c4d65;
}

body[data-theme="dark"] .MuiDialog-paper {
    background: #0B192F;
    color: #eaecf7;
}

body[data-theme="dark"] .MuiPagination-ul .MuiButtonBase-root {
    color: #eaecf7 !important;
    background-color: rgb(39 57 88);
    border: 1px solid rgb(255 255 255 / 0%);
}

body[data-theme="dark"] .MuiDialogContent-root {
    border-top: 1px solid rgb(255 255 255 / 11%);
}

body[data-theme="dark"] .form-group label {
    color: #eaecf7;
}

body[data-theme="dark"] .form-control {
    background-color: #182a46;
    color: #eaecf7;
    border: 1px solid #273d5e;
}

body[data-theme="dark"] .form-control::placeholder {
    color: #a1a5bd;
}

body[data-theme="dark"] .form-control:focus {
    background: #1d304e;
    border-color: #5b6485;
}

body[data-theme="dark"] .bt-select .form-select {
    border: 1px solid #273d5e;
    background-color: #182a46;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    color: #caccdb;
}

body[data-theme="dark"] .bt-select .form-select:focus {
    border-color: #5b6485;
}

body[data-theme="dark"] .card-footer {
    background: unset;
    border-color: #20314c;
}

body[data-theme="dark"] .btn-primary:hover,
body[data-theme="dark"] .btn-primary:active,
body[data-theme="dark"] .btn-primary:focus {
    background-color: #0f1f38;
    border-color: #0fc4dd;
    box-shadow: 0 50px #0f1f38 inset !important;
    color: #0fc4dd;
}

body[data-theme="dark"] ::-webkit-scrollbar-thumb {
    background: #b5babb;
}

body[data-theme="dark"] ::-webkit-scrollbar-thumb:hover {
    background: #ababb3;
}

body[data-theme="dark"] .swiper-button-next,
body[data-theme="dark"] .swiper-button-prev {
    color: #eaecf7;
    background: #2b4978;
    box-shadow: 0 0 0 10px #0f1f38;
}

body[data-theme="dark"] .btn-info {
    background: #2b4978;
    border-color: #2b4978 !important;
    color: #eaecf7;
}

body[data-theme="dark"] .btn-info:hover,
body[data-theme="dark"] .btn-info:active,
body[data-theme="dark"] .btn-info:focus {
    background: #1e365c;
    border-color: #1e365c !important;
}

body[data-theme="dark"] .bt-select .form-select option:disabled {
    color: #d3d3d3;
}

body[data-theme="dark"] .table thead th {
    color: #bbbecf;
    font-family: 'regular';
}

body[data-theme="dark"] table.table-custom tbody tr td {
    background: #102038;
    color: #bbbecf;
}

body[data-theme="dark"] .MuiTooltip-tooltip {
    background: #182A46 !important;
}

body[data-theme="dark"] .main-wrapper {
    background-color: #0f1f38;
}

body[data-theme="dark"] .header {
    background: #0e1e35;
    background: linear-gradient(180deg, #1c3356, #0e1d35);
}

body[data-theme="dark"] .page-wrapper .content {
    border-top: 4px solid #223756;
    background: #0B192F;
}

body[data-theme="dark"] .header .user-img i {
    color: #b3bfcd;
}

body[data-theme="dark"] .sidebar .sidebar-menu > ul > li > a span {
    color: #caccdb;
}

body[data-theme="dark"] .sidebar.new-header .sidebar-menu > ul > li {
    border-left-color: #ffffff1f;
}

body[data-theme="dark"] .list-pages-home li a {
    background: #142642;
    box-shadow: 0px 16px 40px 0px #051226;
}

body[data-theme="dark"] .list-pages-home li a span {
    color: #d5dadf;
}

body[data-theme="dark"] .list-pages-home li a p {
    color: #c4cbd3;
}

body[data-theme="dark"] .line {
    background: linear-gradient(to bottom, #4a638b, #1e3150 3px, transparent);
}

body[data-theme="dark"] .line::after {
    background: linear-gradient(to left, #0b192f, rgba(255, 255, 255, 0), #0b192f);
}

body[data-theme="dark"] .card {
    box-shadow: 0px 16px 40px 0px #0b182c;
    background: #0f1f38;
}

body[data-theme="dark"] .header .dropdown-menu {
    box-shadow: rgb(6 19 38) 0px 6px 12px 2px;
    border-top: 2px solid #223756;
    background: #102544;
    background: linear-gradient(90deg, #1c3356, #102544);
}

body[data-theme="dark"] .profilename .profileset h6 {
    color: #c8d0d7;
}

body[data-theme="dark"] hr {
    background-color: #1b2e4a;
}

body[data-theme="dark"] .header .menu-drop-user .dropdown-item {
    color: #a3b1bd;
}

body[data-theme="dark"] .header .menu-drop-user .logout {
    color: #e05a6b;
}

body[data-theme="dark"] .header .menu-drop-user .dropdown-item:hover,
body[data-theme="dark"] .header .menu-drop-user .dropdown-item:focus,
body[data-theme="dark"] .header .menu-drop-user .dropdown-item:active {
    background: #1e375e;
}

body[data-theme="dark"] .sidebar .sidebar-menu > ul > li.submenu ul {
    background: #102544;
    background: linear-gradient(179deg, #0e1d35, #1b3255);
}

body[data-theme="dark"][data-side="vertical"] .sidebar .sidebar-menu > ul > li.submenu ul {
    box-shadow: unset;
    background: unset;
}


body[data-theme="dark"] .sidebar.new-header .sidebar-menu > ul > li a {
    color: #afbcc7;
}

body[data-theme="dark"] .sidebar .sidebar-menu > ul > li.submenu ul li a::before {
    background: #3a4b65;
}

body[data-theme="dark"] .sidebar .sidebar-menu > ul > li.submenu ul li a::after {
    background: #475e81;
}

body[data-theme="dark"] .sidebar .sidebar-menu > ul > li.submenu ul li a.active:after,
body[data-theme="dark"] .sidebar .sidebar-menu > ul > li.submenu ul li a:hover:after {
    background: #05bbc9;
    border: 1px solid #05bbc9;
}

body[data-theme="dark"] .page-header {
    background: #0b192f;
    background: linear-gradient(180deg, #102648, transparent);
}

body[data-theme="dark"] .table-custom {
    border-spacing: 0 5px;
    background: #172946;
}

body[data-theme="dark"] .loader-btn {
    border-color: #c4cbd3;
}

body[data-theme="dark"] .page-header .page-title h6 {
    border-right: 1px solid #455b7e;
}

body[data-theme="dark"] .border-danger {
    border-color: #bf5660 !important;
}

body[data-theme="dark"] .checkboxs input ~ .checkmarks {
    border: 1px solid #58677c;
}

body[data-theme="dark"] .checkboxs input:checked ~ .checkmarks {
    border: 1px solid #05bbc9;
}

body[data-theme="dark"] .MuiPaginationItem-root.Mui-selected,
body[data-theme="dark"] .MuiPaginationItem-root:hover {
    background: #375b97;
}

body[data-theme="dark"] .MuiPaginationItem-ellipsis {
    color: #eaecf7;
    background: unset !important;
}

body[data-theme="dark"] .info-box {
    background: #2f415c1f;
    background: linear-gradient(270deg, #1a3052, transparent);
    color: #c1ccdd;
}

body[data-theme="dark"] .swal2-popup {
    background: #0B192F;
    color: #eaecf7;
    box-shadow: 0 0 0 4px #ffffff2e;
}

body[data-theme="dark"] .total-order {
    background: #182a46;
}

body[data-theme="dark"] .total-order li {
    color: #d8dae7;
}

body[data-theme="dark"] .total-order li.total {
    border-top: 1px dashed #ffffff2e;
}

body[data-theme="dark"] .total-order li .items-count {
    background: #2b4978;
}

body[data-theme="dark"] .image-upload {
    background: #182a46;
    border: 1px dashed #273d5e;
}

body[data-theme="dark"] .image-upload:hover {
    background: #192c4a;
}

body[data-theme="dark"] .card.card-custom .card-header {
    border: 0;
}

body[data-theme="dark"] .card .card-header .card-title {
    color: revert;
}

body[data-theme="dark"] .products-list li {
    background: #1b3254;
}

body[data-theme="dark"] .purchase-unit {
    background: #1f3454;
}

body[data-theme="dark"] .flex-select-input .select-table .bt-select .form-select {
    border-left: 1px solid #2f4261;
    background-color: #223553;
}

body[data-theme="dark"] .price-red {
    color: revert;
}

body[data-theme="dark"] .noresult {
    color: #c4cbd3;
}

body[data-theme="dark"] .reports li a {
    border: 1px solid #2b4978;
    color: #c4cbd3;
    text-shadow: 0 1px #0b192f;
    background: #1a3258;
    box-shadow: rgb(11 25 47) 0px 8px 24px;
}

body[data-theme="dark"] .reports li a i {
    background: #152846;
}

body[data-theme="dark"] .reports li a:hover {
    box-shadow: rgb(42 74 106 / 20%) 0px 8px 24px !important;
}

body[data-theme="dark"] .barcode-list svg {
    border: 1px dotted #30496e;
    background: #1d3150;
}

body[data-theme="dark"] .list-permissions li {
    box-shadow: 0px 16px 40px 0px #0b182c;
    background: #192e50;
}

body[data-theme="dark"] .list-permissions li h4 {
    border-bottom: 1px solid #2c4160;
    color: #efefef;
}

body[data-theme="dark"] .barcode-icon {
    color: #bbbecf !important;
}

body[data-theme="dark"] .barcode-icon:hover {
    color: #00BCD4 !important;
}

body[data-theme="dark"] .card .card-header {
    border-bottom: 1px solid rgb(35 52 78);
}

body[data-theme="dark"] .profile-box i {
    color: revert;
}

body[data-theme="dark"] .profile-box .profile-dts p,
body[data-theme="dark"] .profile-box .profile-dts ul li h4 {
    color: #8996a5;
}

body[data-theme="dark"] .profile-box .profile-dts ul li:not(:last-child) {
    border-left: 1px solid #ffffff26;
}

body[data-theme="dark"] .btn-permissions {
    color: #bbbecf;
}

body[data-theme="dark"] .setting-icon {
    background: #2f538b;
}

body[data-theme="dark"] .system-setting-sidebar {
    background-color: #182A46;
}

body[data-theme="dark"] .system-setting-sidebar .header-sidebar {
    color: #fff;
}

body[data-theme="dark"] .system-setting-sidebar li h4 {
    color: #e3e5e9;
}

body[data-theme="dark"] .system-setting-sidebar li .toggle-mode img {
    border: 2px solid #4d668f;
}

body[data-theme="dark"] .system-setting-sidebar li .p {
    color: revert;
}

body[data-theme="dark"] .system-setting-sidebar li .switch p {
    font-family: 'regular';
    color: #e3e5e9;
}

body[data-theme="dark"] .system-setting-sidebar .content-sidebar > li {
    border-top: 1px dashed #ffffff26;
}

body[data-theme="dark"] .system-setting-sidebar .content-sidebar > li:first-child {
    border-top: 0;
}

body[data-theme="dark"] .system-setting-sidebar li .switch input[type=checkbox] {
    background: #314564;
}

body[data-theme="dark"] .system-setting-sidebar li .switch input[type=checkbox]:checked {
    background: #50cd89;
}

body[data-theme="dark"] .system-setting-sidebar li .switch input[type=checkbox]::before {
    background-color: #566780;
}

body[data-theme="dark"] .system-setting-sidebar li .switch input[type=checkbox]:checked::before {
    background-color: #fff;
}

body[data-theme="dark"] .setting-sidebar-overlay {
    background-color: rgb(99 114 137 / 50%);
}

body[data-theme="dark"] .system-setting-sidebar .header-sidebar i:last-child {
    background: #182a466b;
}

body[data-theme="dark"] .total-box {
    border-top: 5px solid #0f1f38;
}

body[data-theme="dark"] .label-upload {
    background: #182a46;
    border: 1px dashed #273d5e;
}

body[data-theme="dark"] .label-upload:hover {
    background: #192c4a;
}

body[data-theme="dark"] .label-upload input {
    background: #162742 !important;
    border: 1px solid #273d5e !important;
}

body[data-theme="dark"] .file-status {
    border: 1px solid #263b5c;
    background: #182a46;
}

body[data-theme="dark"] .file-status .file-content span {
    color: #a5abb7;
}

body[data-theme="dark"] .file-status .progress-value {
    color: #c4cbd3;
}

body[data-theme="dark"] .file-status .progress {
    background: #273c5c;
}

body[data-theme="dark"] .holds-popup .MuiAccordion-root .MuiAccordionSummary-expandIconWrapper {
    color: #eaecf7;
}

body[data-theme="dark"] .holds-popup .MuiAccordion-root {
    border: 1px solid #4b4f60;
    border-right: 5px solid #5bbdc5;
    border-left: 5px solid #5bbdc5;
    box-shadow: rgb(7 18 36) 0px 8px 24px;
    background: #182A46;
}

body[data-theme="dark"] .holds-popup .MuiAccordionSummary-content .MuiTypography-root {
    border-right: 1px solid #363f64;
    color: #c4cbd3;
}

body[data-theme="dark"] .holds-popup .MuiAccordionSummary-content .MuiTypography-root:first-child {
    border-right: 0;
}

body[data-theme="dark"] .holds-popup .MuiAccordionSummary-content .MuiTypography-root:last-child {
    border-left: 1px solid #363f64;
}

body[data-theme="dark"] .text-red {
    color: #e76979 !important;
}

body[data-theme="dark"] .holds-popup .MuiAccordion-root .table-custom {
    background: #253958;
}

body[data-theme="dark"] .holds-popup .MuiAccordion-root .table-custom thead {
    background: unset
}

body[data-theme="dark"] .holds-popup .MuiAccordion-root .table-custom tbody tr td {
    background: #102038;
    color: #bbbecf;
}

body[data-theme="dark"] .holds-popup .bg-input .form-control {
    background-color: #102038;
    border: 1px solid #253958;
}

body[data-theme="dark"] .flex-total-order .switch-holder {
    background: unset;
}

body[data-theme="dark"] .switch-toggle input[type="checkbox"] + label {
    box-shadow: unset;
    background: #0b192f;
}

body[data-theme="dark"] .switch-toggle input[type="checkbox"] + label::before {
    box-shadow: unset;
    background-color: #358c93;
}

body[data-theme="dark"] .switch-toggle input[type="checkbox"]:checked + label::before {
    background-color: #406195;
}

body[data-theme="dark"] .system-setting-sidebar li .toggle-mode span::before {
    background: #8792a3;
    border: 5px solid #5a6679;
}

body[data-theme="dark"] .system-setting-sidebar li .toggle-mode span::after {
    background: #182a46;
}

body[data-theme="dark"] .type-sidebar {
    border: 2px solid #314058;
}

body[data-theme="dark"] .header-side {
    background-color: #f4f6fd0a;
}

body[data-theme="dark"] .body-side ul .sidebar-shape {
    background: #4a6899;
}

body[data-theme="dark"] .body-side ul .body-shape {
    background: #3b4b66;
}

body[data-theme="dark"] .point-of-sale {
    color: #7ac4cb !important;
}

body[data-theme="dark"] .point-of-sale:hover::after {
    background: #0f1f38;
}

body[data-theme="dark"] .title-h3 {
    border-top: 1px solid #ffffff1a;
}

body[data-theme="dark"] .card .toggle-password {
    color: #c4cbd3;
}

body[data-theme="dark"][data-side="horizontal"] .menu-overlay {
    background-color: rgb(2 15 34 / 81%);
}

body[data-theme="dark"] .page-loader {
    background: #111f37;
}

body[data-theme="dark"]::-webkit-scrollbar-thumb {
    background: #8facc3;
}

body[data-theme="dark"]::-webkit-scrollbar-track {
    background: #101e34;
}

body[data-theme="dark"] .custom-row {
    background: #0d192e;
}

body[data-theme="dark"] .sidebar-overlay {
    background-color: rgb(2 15 34 / 81%);
}

body[data-theme="dark"] .custom-two-col em {
    background: #192a45;
}

body[data-theme="dark"] .custom-two-col::after {
    border: 1px solid #ffffff1f;
    border-bottom: 0;
}

body[data-theme="dark"] .accordion-system .item-accordion .accordion-title {
    border: 1px solid #203354;
    color: #e2ebf3;
    background: #132340;
}

body[data-theme="dark"] .accordion-system .item-accordion.collapse-show .accordion-title {
    border: 1px solid #3b5d9b;
    background: #28426e;
}

body[data-theme="dark"] .accordion-system .item-accordion .accordion-contant {
    background: #223452;
}

body[data-theme="dark"] .purchases-bill {
    box-shadow: 0px 16px 40px 0px #0b182c;
    background: #0f1f38;
}

body[data-theme="dark"] .purchases-bill .bill-head p {
    color: #a7b0bb;
}

body[data-theme="dark"] .purchases-bill .bill-head p span {
    color: #c4cbd3;
}

body[data-theme="dark"] .purchases-bill .bill-head em {
    background: #293954;
}

body[data-theme="dark"] .purchases-bill .dts-bill {
    background: #15233c;
}

body[data-theme="dark"] .purchases-bill .dts-bill p {
    color: #a7b0bb;
}

body[data-theme="dark"] .purchases-bill .dts-bill span {
    color: #c4cbd3;
}

body[data-theme="dark"] .purchases-bill .table-prd {
    border-bottom: 1px solid #ffffff12;
}

body[data-theme="dark"] .purchases-bill .table-prd thead tr th {
    background: #172946;
}

body[data-theme="dark"] .purchases-bill .flex-boxs .right-box .invoice-notes i {
    color: #a7b0bb;
}

body[data-theme="dark"] .purchases-bill .total-bill {
    background: #192a45;
}

body[data-theme="dark"] .purchases-bill .total-bill li.total {
    border-top: 1px dashed #d7d7d726;
    color: #d2d6db;
}

body[data-theme="dark"] .purchases-bill .footer-bill {
    background-color: #192d4e;
    background-image: linear-gradient(to bottom, #192d4e, #111f37);
}

body[data-theme="dark"] .cash-receipt {
    box-shadow: 0 0 0 2px #0d192e, 0 0 0 3px #39475e, 0px 16px 40px 0px #0000000a;
    background: #0f1f38;
    border-color: #39475e;
}

body[data-theme="dark"] .cash-receipt h3 {
    border-color: #39475e;
}

body[data-theme="dark"] .cash-receipt h6::after {
    border-bottom: 1px dashed #ffffff30;
}

body[data-theme="dark"] .transfer-stock p {
    color: #c4cbd3;
}

body[data-theme="dark"] .transfer-stock p span {
    color: #ebebeb;
}

body[data-theme="dark"] .table-content-prd .table tr :nth-child(odd) {
    color: #c4cbd3;
    background: rgb(32 47 74);
}

body[data-theme="dark"] .table-content-prd .table tr td {
    border-bottom: 1px solid #ffffff1a;
    color: #c4cbd3;
}


/* abdo responsive */

@media (max-width: 1550px) {
    .header .header-left .logo img {
        max-width: 170px;
    }

    .header {
        gap: 10px;
    }

    .sidebar .sidebar-menu > ul > li > a span {
        font-size: 16px;
    }

    .sidebar .sidebar-menu > ul > li > a span img {
        width: 20px;
    }

    .sidebar-menu .menu_list.nav {
        gap: 8px;
    }

    .sidebar.new-header .sidebar-menu > ul > li {
        padding-left: 8px;
    }

}

@media (max-width: 1400px) {
    .header .header-left .logo img {
        max-width: 140px;
    }

    .header {
        gap: 12px;
    }

    .sidebar-menu .menu_list.nav {
        gap: 10px;
    }

    .sidebar .sidebar-menu > ul > li > a span img {
        width: 21px;
    }

    .list-pages-home {
        gap: 12px;
    }

    .list-pages-home li i {
        font-size: 25px;
        width: 42px;
        height: 42px;
        min-width: 42px;
    }

    .list-pages-home li a span {
        font-size: 25px;
    }

    .title-chart {
        font-size: 19px;
    }

    .sidebar .sidebar-menu > ul > li.submenu ul li a {
        font-size: 15px;
        padding: 6px 15px;
        padding-right: 30px;
    }

    .sidebar .sidebar-menu > ul > li.submenu ul li a::after {
        top: 13px;
    }

    .list-permissions {
        grid-template-columns: repeat(4, auto);
    }

    .noresult img {
        height: 155px;
    }

    .noresult h4 {
        font-size: 19px;
    }

    .point-of-sale span img {
        width: 20px;
        height: 20px;
    }

    .point-of-sale {
        font-size: 16px;
        padding: 8px 12px;
        padding-bottom: 5px;
        margin-left: 5px;
    }

}

@media (max-width: 1200px) {
    body[data-side="vertical"] .page-wrapper {
        width: 100%;
    }

    body[data-side="vertical"] .header-left {
        display: block;
    }

    .header .mobile_btn {
        color: #05bbc9;
        font-size: 24px;
        z-index: 10;
        display: block;
        margin-left: 12px;
    }

    .list-pages-home {
        gap: 20px;
        row-gap: 10px;
        justify-content: start;
    }

    .list-pages-home li i {
        font-size: 22px;
        width: 35px;
        height: 35px;
        min-width: 35px;
    }

    .list-pages-home li a span {
        font-size: 23px;
    }

    .list-pages-home li a p {
        font-size: 16px;
        line-height: 22px;
    }

    .title-chart {
        font-size: 18px;
    }

    .title-chart i {
        font-size: 20px;
    }

    .sidebar.new-header {
        background-color: white;
        margin-top: 0;
        z-index: 1001 !important;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        transition: all .2s ease;
        border-left: 1px solid #e8ebed;
        width: 275px !important;
        display: block;
        transform: translateX(100%);
        opacity: 0;
        visibility: hidden;
    }

    .sidebar.new-header.active {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }

    .sidebar.new-header .header-left {
        display: block;
    }

    .sidebar-menu .menu_list.nav {
        display: block;
    }

    .sidebar.new-header .sidebar-menu > ul > li {
        border-left: 0;
        padding-left: 0;
    }

    .sidebar-menu {
        padding: 0 15px;
        overflow: auto;
        max-height: calc(100vh - 70px);
    }

    .sidebar.new-header .sidebar-menu > ul > li a {
        padding: 13px 0;
    }

    .sidebar.new-header .sidebar-menu > ul > li .menu-arrow {
        margin-right: auto;
    }

    .sidebar .sidebar-menu > ul > li > a span img {
        width: 24px;
        animation: unset !important;
    }

    .sidebar.new-header .sidebar-menu > ul > li ul {
        display: none;
        position: relative;
        width: 100%;
        height: auto;
        top: 0;
        margin-top: 0;
        box-shadow: unset;
        padding: 0;
        transform: unset;
        border-radius: 0;
    }

    .sidebar.new-header .sidebar-menu > ul > li ul.active {
        display: block;
    }

    .header .header-left .logo img {
        max-width: 170px;
    }

    .sidebar .sidebar-menu > ul > li > a .menu-arrow {
        font-size: 12px;
    }

    body[data-theme="dark"] .sidebar.new-header {
        background: #152a4c;
        border-left: 1px solid #19335c;
    }

    body[data-theme="dark"] .sidebar .sidebar-menu > ul > li.submenu ul {
        box-shadow: unset;
        background: unset;
    }

    .products-list {
        grid-template-columns: repeat(2, 1fr);
    }

    .reports {
        gap: 15px;
        grid-template-columns: repeat(4, 1fr);
    }

    .reports li a {
        padding: 10px;
        border-radius: 8px;
        font-size: 18px;
    }

    .reports li a i {
        font-size: 24px;
        width: 40px;
        height: 40px;
    }

    .file-status .icon-excel {
        width: 45px;
        height: 45px;
        min-width: 45px;
        font-size: 26px;
    }

    .file-status {
        gap: 12px;
    }

    .file-status .file-content span {
        line-height: 25px;
        font-size: 15px;
    }

    .file-status .progress-value {
        font-size: 18px;
    }

    .file-status .bi-check-circle-fill {
        font-size: 22px;
    }

    .file-status .loader-btn {
        min-width: 22px;
        width: 22px;
        height: 22px;
    }

    .download-example {
        padding: 6px 20px;
        font-size: 15px;
    }

    body[data-side="horizontal"] .menu-overlay {
        display: none;
    }

}

@media (max-width: 992px) {
    .header .header-left .logo img {
        max-width: 160px;
        height: 45px;
    }

    body[data-side="vertical"] .sidebar.new-header .header-left .logo img {
        max-width: 180px;
    }

    .page-wrapper .content {
        min-height: calc(100vh - 89px);
    }

    .sidebar.new-header {
        width: 260px !important;
    }

    .sidebar-menu {
        max-height: calc(100vh - 65px);
    }

    .bar-icon {
        width: 30px;
    }

    .header .mobile_btn {
        margin-left: 15px;
    }

    .header .dropdown-menu.show {
        transform: translateY(12px);
        -webkit-transform: translateY(12px);
    }

    .page-btn .btn {
        padding: 3px 8px;
        font-size: 15px;
    }

    .btn-filter {
        width: 30px;
        height: 30px;
    }

    .select-some {
        font-size: 15px;
    }

    .wordset ul li a img {
        width: 24px;
        height: 24px;
    }

    .products-list {
        grid-template-columns: repeat(3, 1fr);
    }

    .flex-total-order {
        gap: 10px;
        flex-wrap: wrap;
    }

    .reports {
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }

    .reports li a i {
        font-size: 22px;
    }

    .reports li a {
        font-size: 17px;
    }

    .list-pages-home {
        gap: 20px;
        row-gap: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .list-permissions {
        grid-template-columns: repeat(3, auto);
        gap: 10px;
    }

    .system-setting-sidebar {
        width: 315px;
    }

    .system-setting-sidebar li .switch p {
        font-size: 15px;
    }

    .system-setting-sidebar .header-sidebar {
        font-size: 18px;
    }

    .system-setting-sidebar li .toggle-mode span::before,
    .system-setting-sidebar li .toggle-mode span::after {
        width: 18px;
        height: 18px;
        top: 3px;
    }

    .system-setting-sidebar li .toggle-mode span {
        padding-right: 24px;
    }

    .system-setting-sidebar li .switch input[type=checkbox] {
        width: 46px;
        height: 22px;
    }

    .system-setting-sidebar li .switch input[type=checkbox]::before {
        height: 16px;
        width: 16px;
    }

    .system-setting-sidebar > li {
        padding-top: 8px;
        margin-top: 8px;
    }

    .system-setting-sidebar li .flex-content {
        gap: 15px;
    }

}

@media (max-width: 768px) {
    .products-list {
        grid-template-columns: repeat(2, 1fr);
    }

    .products-list li button {
        width: 22px;
        height: 22px;
        min-width: 22px;
    }

    .products-list li button i {
        font-size: 18px;
    }

    .products-list li {
        gap: 5px;
        padding: 2px 5px;
        border-radius: 5px;
    }

    .card .card-header .card-title {
        font-size: 17px;
    }

    .productimgname p {
        font-size: 14px;
    }

    .price-red {
        font-size: 17px;
    }

    .flex-btn-table a,
    .flex-btn-table button {
        width: 25px;
        height: 25px;
        font-size: 16px;
    }

    .reports {
        grid-template-columns: repeat(2, 1fr);
    }

    .list-permissions {
        grid-template-columns: repeat(2, auto);
    }

    .download-example {
        margin-top: 15px;
    }

    .MuiDialog-paper {
        margin: 10px !important;
        max-height: calc(100% - 20px) !important;
        box-shadow: 0 0 0 4px #ffffff21 !important;
    }

    .MuiTypography-root.MuiDialogTitle-root {
        padding: 5px 15px;
        font-size: 18px;
    }

    .holds-popup .MuiAccordionSummary-content {
        gap: 10px;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .holds-popup .MuiAccordionSummary-content .MuiTypography-root:last-child {
        margin-right: 0;
        margin-left: 0;
        border-left: 0;
        font-size: 20px;
        margin-top: -8px;
    }

    .holds-popup .MuiAccordionSummary-content .MuiTypography-root {
        padding-right: 10px;
        font-size: 15px;
    }

    .holds-popup .MuiAccordion-root .table-custom tr th:first-child,
    .holds-popup .MuiAccordion-root .table-custom tr td:first-child {
        padding-right: 10px;
        min-width: 150px;
    }

    .holds-popup .MuiAccordion-root .table-custom thead tr th {
        font-size: 14px;
    }

}

@media (max-width: 576px) {
    .list-pages-home {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .list-pages-home li a {
        padding: 7px;
        border-radius: 8px;
    }

    .list-pages-home li a span {
        font-size: 20px;
    }

    .sidebar .sidebar-menu > ul > li > a span {
        font-size: 16px;
    }

    .sidebar.new-header .sidebar-menu > ul > li a {
        padding: 10px 0;
    }

    .form-control,
    .bt-select .form-select {
        padding: 3px 10px;
        font-size: 15px;
        background-position: left 8px center;
        background-size: 12px 12px;
    }

    .form-control-sm {
        font-size: 14px;
    }

    .btn-filters {
        width: 30px;
        height: 30px;
        padding: 3px 4px 4px;
    }

    .MuiPagination-ul .MuiButtonBase-root {
        margin: 0 !important;
    }

    .card-footer .btn {
        min-width: 100px;
        padding: 4px 10px;
    }

    .flex-total-order .form-group {
        margin-bottom: 10px;
    }

    .total-order li h4 {
        font-size: 16px;
    }

    .total-order li.total h4,
    .total-order li.total h5 span,
    .total-order li h5 span {
        font-size: 17px;
    }

    .total-order li.total {
        padding-top: 10px;
        margin-top: 10px;
    }

    .total-order {
        padding: 10px;
    }

    .card-body {
        padding: 12px;
    }

    .card-custom .card-body {
        padding: 12px;
        padding-top: 0;
    }

    .info-box p {
        margin: 0;
        font-size: 16px;
    }

    .info-box i {
        font-size: 26px;
    }

    .info-box {
        padding: 8px 10px;
        gap: 10px;
    }

    .productimgname {
        width: 175px;
        max-width: 200px;
    }

    .header .mobile_btn {
        margin-left: 3px;
    }

    .header .user-img i {
        font-size: 32px !important;
    }

    .page-header h4 {
        font-size: 18px;
    }

    .list-permissions {
        grid-template-columns: repeat(1, 1fr);
    }

    .list-permissions li h4 {
        font-size: 17px;
        padding-bottom: 6px;
        margin-bottom: 7px;
    }

    .btn-permissions {
        font-size: 24px;
    }

    .noresult img {
        height: 140px;
    }

    .noresult h4 {
        font-size: 17px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .switch-toggle {
        height: 33px;
    }

    .switch-toggle input[type="checkbox"] + label {
        width: 120px;
        height: 33px;
    }

    .switch-toggle input[type="checkbox"] + label::before {
        font-size: 15px;
        line-height: 25px;
        top: 4px;
        right: 6px;
        width: 65px;
        box-shadow: unset;
    }

    .switch-toggle input[type="checkbox"]:checked + label::before {
        right: 49px;
    }

    .holds-popup .MuiAccordionSummary-content {
        gap: 0;
        flex-direction: column;
        align-items: start;
        margin: 5px 0;
    }

    .holds-popup .MuiAccordionSummary-root {
        padding: 0 10px;
    }

    .holds-popup .MuiAccordionSummary-content .MuiTypography-root:last-child {
        margin-top: 0;
    }

    .holds-popup .MuiAccordionSummary-content .MuiTypography-root {
        padding-right: 0;
        border: 0;
    }

    .MuiDialogContent-root {
        padding: 10px;
    }

    .holds-popup .btns-acc button {
        width: 25px;
        height: 25px;
        font-size: 16px;
    }

    .holds-popup .MuiAccordion-root .table-custom tr td {
        padding: 3px 5px;
    }

}

@media (max-width: 450px) {

    .header .header-left .logo img {
        max-width: 155px;
    }

    .sidebar .sidebar-menu > ul > li > a span img {
        width: 20px;
    }

    .sidebar .sidebar-menu > ul > li > a span {
        font-size: 15px;
    }

    .sidebar.new-header {
        width: 230px !important;
    }

    .bar-icon {
        width: 25px;
    }

    .bar-icon span {
        height: 2px;
        margin-bottom: 5px;
    }

    .list-pages-home li a {
        gap: 7px;
    }

    .list-pages-home li i {
        font-size: 20px;
        width: 32px;
        height: 32px;
        min-width: 32px;
    }

    .list-pages-home li a p {
        font-size: 15px;
        line-height: 19px;
    }

    .title-chart {
        font-size: 16px;
        line-height: 22px;
    }

    .echarts-for-react {
        height: 180px !important;
    }

    .card-footer .btn {
        min-width: 85px;
        font-size: 15px;
    }

    .footer {
        padding: 8px;
    }

    .footer p {
        line-height: 18px
    }

    .form-group {
        margin-bottom: 10px;
    }

    .page-header h4 {
        font-size: 17px;
        line-height: 20px;
    }

    .page-header .page-title h6 {
        font-size: 15px;
    }

    .products-list {
        grid-template-columns: repeat(1, 1fr);
        gap: 7px;
    }

    .reports {
        grid-template-columns: repeat(1, 1fr);
    }

    .reports li a {
        padding: 6px;
        font-size: 16px;
    }

    .label-upload i {
        font-size: 38px;
    }

    .label-upload span {
        font-size: 16px;
    }

    .label-upload {
        padding: 12px;
    }

    .file-status {
        gap: 10px;
        padding: 7px;
        border-radius: 7px;
        flex-wrap: wrap;
        justify-content: end;
    }

    .file-status .icon-excel {
        width: 32px;
        height: 36px;
        min-width: 32px;
        font-size: 20px;
        border-radius: 4px;
    }

    .file-status .file-content p {
        line-height: 20px;
        font-size: 15px;
    }

    .file-status .file-content span {
        font-size: 14px;
    }

    .file-status .progress-value {
        font-size: 16px;
    }

    .file-status .bi-check-circle-fill {
        font-size: 18px;
    }

    .file-status .loader-btn {
        min-width: 18px;
        width: 18px;
        height: 18px;
    }

    .download-example {
        padding: 4px 17px;
    }

    .download-example i {
        font-size: 18px;
    }

    .file-status .file-content {
        width: calc(100% - 42px);
    }

    .total-order li {
        margin-bottom: 10px;
    }

    .MuiButtonBase-root.MuiIconButton-root {
        height: 34px;
    }

    .MuiTypography-root.MuiDialogTitle-root {
        font-size: 17px;
        gap: 3px;
    }

    .holds-popup .MuiAccordion-root {
        border-right: 4px solid #5bbdc5;
        border-left: 4px solid #5bbdc5;
    }

    .point-of-sale {
        display: none;
    }

    .pos-profile-link {
        display: flex !important;
    }

    .products-list li p {
        font-size: 13px;
    }

    .w-75px {
        width: 68px;
        min-width: 68px;
    }

}

@media (max-width: 375px) {
    .system-setting-sidebar {
        width: 100%;
    }

    .setting-sidebar-overlay {
        transition: ease all 0.3s;
    }
}


@media print {
    .main-wrapper {
        display: none;
    }

    .print-table {
        display: table;
    }

    .purchases-bill {
        border-radius: 0;
    }

    .prd-dts img {
        min-width: max-content;
        width: auto;
    }

    .table-content-prd {
        flex-wrap: wrap;
        row-gap: 0;
    }
}


.export-btn-excel {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 220px;
}
