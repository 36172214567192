.receipt {
  width: 80mm;
  margin: 0 auto;
  color: #000;
  font-size: 14px;
  font-family: sans-serif;
  display: none;
}

.receipt img {
  max-height: 50px;
  max-width: 180px;
  margin: 0 auto;
  display: block;
  filter: brightness(0);
}

.receipt h3 {
  font-size: 19px;
  text-align: center;
  margin-top: 6px;
}

.receipt .line-rcpt {
  border-top: 1px dashed #000000;
  padding-top: 2px;
  margin: 6px 0;
}

.receipt .line-rcpt span {
  border-bottom: 1px dashed #000000;
  display: block;
}

.receipt .flex-rcpt {
  display: grid;
  grid-template-columns: auto auto;
}

.receipt .flex-rcpt li {
  display: flex;
  gap: 12px;
}

.receipt .flex-rcpt li:nth-child(even) {
  justify-content: end;
}

.receipt .table-rcpt {
  width: 100%;
  margin: 5px 0;
}

.receipt .table-rcpt th {
  background: #000;
  color: #fff;
  border-left: 1px solid #ffffff !important;
}

.receipt .table-rcpt th,
.receipt .table-rcpt td {
  border: 1px solid #000;
  padding: 2px;
  padding-top: 0 !important;
  padding-bottom: 4px !important;
}

.receipt .table-rcpt th:not(:first-child),
.receipt .table-rcpt td:not(:first-child) {
  text-align: center;
}

.receipt .table-rcpt th:first-child,
.receipt .table-rcpt td:first-child {
  border-right: 0;
  padding-right: 5px;
}

.receipt .table-rcpt th:last-child,
.receipt .table-rcpt td:last-child {
  border-left: 0 !important;
}

.receipt .subtotal {
  margin-top: 25px;
}

.receipt .subtotal li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  font-size: 15px;
}

.receipt .subtotal li p {
  margin-bottom: 2px;
  min-width: max-content;
}


.receipt .subtotal li>em {
  width: 100%;
  border-bottom: 1px solid #a8a8a8;
}

.receipt .subtotal li span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.receipt .subtotal li.total span em {
  font-size: 17px;
}

.receipt .subtotal .total {
  margin: 0;
  font-size: 20px;
  margin-bottom: 50px;
}

.receipt .text-btm {
  text-align: center;
  margin: 0;
  font-size: 16px;
  background: #000;
  color: #fff;
  padding-bottom: 3px;
  box-shadow: 0px 3px 0 #7c7b7b;
  margin-bottom: 5px;
}

.receipt .addrs-phone {
  text-align: center;
  margin-bottom: 5px;
}

.receipt .addrs-phone p {
  margin: 0;
  line-height: 24px;
  font-size: 16px;
  direction: ltr;
}

.receipt .barcode-receipt {
  max-width: 95%;
  max-height: 70px;
  margin: 15px auto 10px;
}

.receipt .dev-by {
  font-size: 17px;
  text-align: center;
  margin-top: 5px;
  border-top: 1px solid #8d8d8d;
  padding-top: 3px;
}

@media print {
  .receipt {
    display: block;
  }
}