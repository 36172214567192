.rd3t-node>circle {
  fill: #1d8b95;
  stroke: #1d8b95;
  stroke-width: 2;
}

.rd3t-leaf-node>circle {
  fill: #fff;
  stroke: #2d3748;
  stroke: #1d8b95;
  stroke-width: 2;
}

circle {
  r: 17px;
  transition: .3s;
}

circle:hover {
  fill: #cadfe1;
  stroke: #328c94;
  r: 20
}

.rd3t-link {
  stroke-width: 2;
  stroke: #328c94 !important;
}

.rd3t-label__title {
  transform: translate(-15px, 4px);
  font-size: 15px;
  cursor: default;
}

.rd3t-label__attributes {
  display: none;
}

.rd3t-label__title,
.rd3t-label__attributes {
  font-family: 'bold';
}

/* .rd3t-node.active circle,
.rd3t-leaf-node.active circle {
  fill: #cadfe1;
  stroke: #328c94;
  stroke-width: 8px;
  r: 14;
}

.rd3t-node.active .rd3t-label__title,
.rd3t-leaf-node.active .rd3t-label__title {
  fill: #328c94;
} */

body[data-theme="dark"] .rd3t-label__title {
  fill: #caccdb;
}

body[data-theme="dark"] .rd3t-label__attributes {
  fill: #9fa0ab;
}

body[data-theme="dark"] .rd3t-leaf-node>circle {
  fill: #111f37;
}

body[data-theme="dark"] .rd3t-svg circle:hover {
  fill: #203a68;
}