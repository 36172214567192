  .custom-print {
    padding: 0;
    border-spacing: 0;
    border-radius: 0;
  }

  .custom-print thead {
    display: none;
  }

  .custom-print tbody tr {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #c1c1c1;
    margin-bottom: 15px;
    border-radius: 0 !important;
    border-bottom: 0;
    box-shadow: unset !important;
  }

  .custom-print tbody tr td:nth-child(2),
  .custom-print tbody tr td:nth-last-child(2) {
    width: 100%;
    min-width: 100%;
  }

  .custom-print tbody tr td {
    color: #2b2c2e;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c1c1c1 !important;
    position: relative;
    border-radius: 0 !important;
    gap: 15px;
    text-align: right;
    min-width: 50%;
  }

  .custom-print tbody tr td:before {
    content: attr(data-content);
    display: inline-block;
    position: relative;
    z-index: 1;
    width: max-content;
    min-width: 80px;
    font-family: 'bold';
    color: #212529;
  }